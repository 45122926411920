import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_KEYS } from '../../i18n/index.enum';
import { VehicleInfoContainer, ValueContainer } from './index.style';
import Typography from '../../atoms/Typography';
import { colorsNameKey } from '../../const/theme.const';

const VehicleInfo = ({
  value, unityType, label, margin,
}) => {
  const { t } = useTranslation();
  return (
    <VehicleInfoContainer margin={margin}>
      <ValueContainer>
        <Typography
          tag="span"
          type={Typography.TYPES.VEHICLE_INFO_VALUE}
          color={colorsNameKey.BLACK_100}
        >
          {value}
        </Typography>
        {unityType && (
          <Typography
            tag="span"
            type={Typography.TYPES.VEHICLE_INFO_UNITY}
            color={colorsNameKey.BLACK_100}
          >
            {unityType}
          </Typography>
        )}
      </ValueContainer>

      <Typography
        tag="span"
        type={Typography.TYPES.CAPTION}
        color={colorsNameKey.BLACK_90}
      >
        {t(TRANSLATION_KEYS.CAR_DETAIL[label])}
      </Typography>
    </VehicleInfoContainer>
  );
};

VehicleInfo.defaultProps = {
  margin: null,
  unityType: null,
};

VehicleInfo.propTypes = {
  value: PropTypes.string.isRequired,
  unityType: PropTypes.string,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,
};

export default VehicleInfo;
