import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { history } from './store';
import theme from './ui/theme';
import './App.scss';
import { RouterContainer } from './components/RouterContainer';
import { INITIALIZE_APP_STATE } from './redux/actions/types';
import { getQueryVariable } from './utils/query';
import { LOGIN_ON_FERRARI } from './redux/actions/login';
import ErrorManager from './components/ErrorManager';
import LoginHeader from './components/LoginHeader';
import LoaderFerrari from './atoms/LoaderFerrari';
import { windowSize } from './ui/theme/device';

function App({
  // eslint-disable-next-line react/prop-types
  initApp,
  // eslint-disable-next-line react/prop-types
  loginOnFerrari,
}) {
  const [renderLogin, setRenderLogin] = useState(false);
  const { size } = windowSize();
  const autoLoader = useRef(null);
  useEffect(() => {
    if (window.FerrariAutoloaderManager) {
      autoLoader.current = new window.FerrariAutoloaderManager({
        superheader: document.getElementById('fe-header'),
        superfooter: document.getElementById('fe-footer'),
      });
      autoLoader.current.options.superheader.onMount = () => {
        setRenderLogin(true);
      };
      if (autoLoader.current && autoLoader.current.options.superheader) {
        autoLoader.current.options.superheader.fixed = size === 'S';
      }
      autoLoader.current.mount();
      autoLoader.current.initContext();
    }
    initApp();
    const code = getQueryVariable('code');
    if (code) {
      loginOnFerrari(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
  }, [size]);

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <ErrorManager />
        <LoaderFerrari animationSpeed={4} />
        {renderLogin && <LoginHeader />}
        <RouterContainer />
      </ThemeProvider>
    </Router>
  );
}

export default connect(
  () => null,
  (dispatch) => ({
    initApp: () => dispatch({ type: INITIALIZE_APP_STATE }),
    loginOnFerrari: (code) => dispatch({ type: LOGIN_ON_FERRARI._REQUEST, code }),
  }),
)(App);
