import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DotsContainer,
  SlideIndicatorContainer,
  ArrowContainer,
} from './index.style';
import SliderIndicator from '../../../atoms/SliderIndicator';
import theme from '../../../ui/theme';
import Icon from '../../../atoms/Icon';
import Typography from '../../../atoms/Typography';
import { TRANSLATION_KEYS } from '../../../i18n/index.enum';

const Dots = ({
  dots,
  currentDot,
  autoplay,
  onChange,
  color,
  ringColor,
  timeoutProgressColor,
  arrows,
  arrowColors,
  arrowLeftText,
  arrowRightText,
  onFinishedAutoplay,
  howManyDotsToSee,
}) => {
  const [currentSelectedDot, setCurrentSelectedDot] = useState(0);
  const [dotsToSee, setDotsToSee] = useState(
    howManyDotsToSee % 2 === 0 ? howManyDotsToSee + 1 : howManyDotsToSee,
  );
  const { t } = useTranslation();

  useEffect(() => {
    setDotsToSee(
      howManyDotsToSee % 2 === 0 ? howManyDotsToSee + 1 : howManyDotsToSee,
    );
  }, [howManyDotsToSee]);

  useEffect(() => {
    setCurrentSelectedDot(currentDot);
  }, [currentDot]);

  return (
    <DotsContainer>
      {arrows && (
        <ArrowContainer
          onClick={() => {
            const newValue = currentSelectedDot - 1;
            if (currentSelectedDot > 0) {
              setCurrentSelectedDot(newValue);
              onChange(newValue);
            }
          }}
          position="left"
        >
          <Icon
            size={13}
            icon={Icon.ICONS_TYPE.ARROW_LEFT}
            color={arrowColors}
          />
          {arrowLeftText && (
            <Typography
              type={Typography.TYPES.CAPTION}
              style={Typography.STYLES.DEFAULT}
              tag="p"
              color={arrowColors}
            >
              {t(arrowLeftText)}
            </Typography>
          )}
        </ArrowContainer>
      )}
      {dots
        && Array.from(new Array(dots)).map((elem, i, array) => (dotsToSee > -1 ? (
          i < currentSelectedDot + dotsToSee / 2
            && i > currentSelectedDot - dotsToSee / 2 && (
              <SlideIndicatorContainer
                opacitizer={i !== currentSelectedDot}
                className="slider-indicator"
                key={i}
              >
                <SliderIndicator
                  color={color}
                  ringColor={ringColor}
                  timeoutProgressColor={timeoutProgressColor}
                  selected={currentSelectedDot === i && !autoplay}
                  onClick={() => {
                    setCurrentSelectedDot(i);
                    onChange(i);
                  }}
                  onFinished={() => currentSelectedDot === i
                    && (i < array.length - 1
                      ? (() => {
                        setCurrentSelectedDot(i + 1);
                        onChange(i + 1, 'timeout');
                      })()
                      : onFinishedAutoplay())}
                  timeout={
                    currentSelectedDot === i
                    && (typeof autoplay === 'number' ? autoplay : 0)
                  }
                />
              </SlideIndicatorContainer>
          )
        ) : (
          <SlideIndicatorContainer className="slider-indicator" key={i}>
            <SliderIndicator
              color={color}
              ringColor={ringColor}
              timeoutProgressColor={timeoutProgressColor}
              selected={currentSelectedDot === i && !autoplay}
              onClick={() => {
                setCurrentSelectedDot(i);
                onChange(i);
              }}
              onFinished={() => currentSelectedDot === i
                  && (i < array.length - 1
                    ? (() => {
                      setCurrentSelectedDot(i + 1);
                      onChange(i + 1, 'timeout');
                    })()
                    : onFinishedAutoplay())}
              timeout={
                  currentSelectedDot === i
                  && (typeof autoplay === 'number' ? autoplay : 0)
                }
            />
          </SlideIndicatorContainer>
        )))}
      {arrows && (
        <ArrowContainer
          onClick={() => {
            const newValue = currentSelectedDot + 1;
            if (currentSelectedDot < dots - 1) {
              setCurrentSelectedDot(newValue);
              onChange(newValue);
            }
          }}
          position="right"
        >
          {arrowRightText && (
            <Typography
              type={Typography.TYPES.CAPTION}
              style={Typography.STYLES.DEFAULT}
              tag="p"
              color={arrowColors}
            >
              {t(arrowRightText)}
            </Typography>
          )}
          <Icon
            size={13}
            icon={Icon.ICONS_TYPE.ARROW_RIGHT}
            color={arrowColors}
          />
        </ArrowContainer>
      )}
    </DotsContainer>
  );
};

Dots.defaultProps = {
  currentDot: 0,
  autoplay: 0,
  onChange: () => null,
  color: 'black_90',
  ringColor: 'black_05',
  timeoutProgressColor: 'rosso_corsa',
  arrows: false,
  arrowColors: 'white',
  arrowLeftText: TRANSLATION_KEYS.CAROUSEL.PREVIOUS,
  arrowRightText: TRANSLATION_KEYS.CAROUSEL.NEXT,
  onFinishedAutoplay: () => null,
  howManyDotsToSee: -1,
};

Dots.propTypes = {
  dots: PropTypes.number.isRequired,
  currentDot: PropTypes.number,
  autoplay: PropTypes.number,
  onChange: PropTypes.func,
  color: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  ringColor: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  timeoutProgressColor: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  arrows: PropTypes.bool,
  arrowColors: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  arrowLeftText: PropTypes.string,
  arrowRightText: PropTypes.string,
  onFinishedAutoplay: PropTypes.func,
  howManyDotsToSee: PropTypes.number,
};

export default Dots;
