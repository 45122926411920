import { css } from 'styled-components';

const display1 = {
  default: (color, props) => css`
    font-family: "Noe Display"; 
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 80px;
      line-height: 1.1;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      line-height: 1.05;
    }
  `,
  italic: (color, props) => css`
    font-family: "Noe Display";
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.13;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 80px;
      line-height: 1.1;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      line-height: 1.05;
    }
  `,
  alt: (color, props) => css`
    font-family: "Noe Display";
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.13;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 80px;
      line-height: 1.1;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      line-height: 1.05;
    }
  `,
};

const display2 = {
  default: (color, props) => css`
    font-family: "Noe Display";
    font-size: 50px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 48px;
      line-height: 1.17;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 56px;
      line-height: 1.04;
    }
  `,
  italic: (color, props) => css`
    font-family: "Noe Display";
    font-size: 50px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.16;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 48px;
      line-height: 1.17;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 56px;
      line-height: 1.04;
    }
  `,
  alt: (color, props) => css`
    font-family: "Noe Display";
    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 48px;
      line-height: 1.17;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 56px;
      line-height: 1.04;
    }
  `,
};

const headline = {
  default: (color, props) => css`
    font-family: "Noe Display";
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 32px;
      line-height: 1.19;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 44px;
      line-height: 1.27;
    }
  `,
  italic: (color, props) => css`
    font-family: "Noe Display";
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.38;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 32px;
      line-height: 1.19;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 44px;
      line-height: 1.27;
    }
  `,
  alt: (color, props) => css`
    font-family: "Noe Display";
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 1.5px;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 32px;
      line-height: 1.19;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 44px;
      line-height: 1.27;
    }
  `,
};

const title = {
  default: (color, props) => css`
    font-family: "Noe Display";
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 26px;
      line-height: 1.23;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 32px;
      line-height: 1.31;
    }
  `,
  italic: (color, props) => css`
    font-family: "Noe Display";
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.23;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 26px;
      line-height: 1.23;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 32px;
      line-height: 1.31;
    }
  `,
  alt: (color, props) => css`
    font-family: "Noe Display";
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 26px;
      line-height: 1.23;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 32px;
      line-height: 1.31;
    }
  `,
};

const subtitle = {
  default: (color, props) => css`
    font-family: "Noe Display";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 20px;
      line-height: 1.3;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 24px;
      line-height: 1.33;
    }
  `,
  italic: (color, props) => css`
    font-family: "Noe Display";
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.3;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 20px;
      line-height: 1.3;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 24px;
      line-height: 1.33;
    }
  `,
  alt: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 20px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 20px;
      line-height: 1.3;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 24px;
      line-height: 1.33;
    }
  `,
};


const subtitleLittler = {
  default: (color, props) => css`
    font-family: "Noe Display";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 18px;
      line-height: 1.3;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 20px;
      line-height: 1.33;
    }
  `,
  italic: (color, props) => css`
    font-family: "Noe Display";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.3;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 18px;
      line-height: 1.3;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 20px;
      line-height: 1.33;
    }
  `,
  alt: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 18px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 18px;
      line-height: 1.3;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 20px;
      line-height: 1.33;
    }
  `,
};

const body = {
  default: (color, props) => css`
    font-family: "GT America";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 14px;
      line-height: 1.57;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 16px;
      line-height: 1.5;
    }
  `,
  italic: (color, props) => css`
    font-family: "GT America";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.38;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 14px;
      line-height: 1.57;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 16px;
      line-height: 1.5;
    }
  `,
  alt: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 16px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 14px;
      line-height: 1.57;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 16px;
      line-height: 1.5;
    }
  `,
};

const small = {
  default: (color, props) => css`
    font-family: "GT America";
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 11px;
      line-height: 1.36;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 11px;
      line-height: 1.09;
    }
  `,
  italic: (color, props) => css`
    font-family: "GT America";
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.33;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 11px;
      line-height: 1.36;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 11px;
      line-height: 1.09;
    }
  `,
  alt: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 9px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 11px;
      line-height: 1.36;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 11px;
      line-height: 1.09;
    }
  `,
};

const caption = {
  default: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 11px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 1.5px;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 11px;
      line-height: 1.27;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 11px;
      line-height: 1.27;
    }
  `,
  italic: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 11px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 1.5px;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 11px;
      line-height: 1.27;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 11px;
      line-height: 1.27;
    }
  `,
  alt: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 11px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 1.5px;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 11px;
      line-height: 1.27;
    }
    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 11px;
      line-height: 1.27;
    }
  `,
};

const vehicleCardYear = {
  default: (color, props) => css`
    font-family: "GT America";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 1.8px;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
  `,
};

const vehicleCardTitle = {
  default: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 14px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
  `,
};

const vehicleCardPlate = {
  default: (color, props) => css`
    font-family: "GT America";
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
  `,
};

const vehicleCardPlateOver = {
  default: (color, props) => css`
    font-family: "GT America";
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
  `,
};

const vehicleCardLabel = {
  default: (color, props) => css`
    font-family: "GT America";
    font-size: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
  `,
};

const vehicleCardInfo = {
  default: (color, props) => css`
    font-family: "GT America Medium";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: uppercase;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
  `,
};

const custom = ({
  fontFamily,
  fontSize,
  fontWeight,
  fontStretch,
  fontStyle,
  lineHeight,
  letterSpacing,
  props,
  color,
}) => css`
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  font-stretch: ${fontStretch};
  font-style: ${fontStyle};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing};
  color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
`;

const vehicleCardYearDetail = {
  default: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 16px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};


    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 20px;;
      line-height: 1.2;
    }
  `,
};

const vehicleCardTitleDetail = {
  default: (color, props) => css`
    font-family: "Noe Display";
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};


    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 44px;
      line-height: 1.27;
      letter-spacing: 1.5px;
    }
  `,
};

const vehicleCardPlateLabelDetail = {
  default: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 8px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: center;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};


    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 10px;
      font-weight: 500;
      line-height: 2.4;
    }
  `,
};

const vehicleCardPlateDetail = {
  default: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 20px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};


    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 24px;
      line-height: 1;
    }
  `,
};

const vehicleInfoValue = {
  default: (color, props) => css`
    font-family: "Noe Display";
    font-size: 56px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_100};

    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 80px;
    }
  `,
};

const vehicleInfoUnity = {
  default: (color, props) => css`
    font-family: "Noe Display";
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};

    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 48px;
    }
  `,
};

const accordionTitle = {
  default: (color, props) => css`
    font-family: "GT America Expanded";
    font-size: 16px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};

    @media ${props.theme.device.mediaqueries.large_screen_min} {
      line-height: 2;
      letter-spacing: 0.44px;
    }
  `,
};

const accordionListItemValue = {
  default: (color, props) => css`
    font-family: "GT America";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
  `,
};

const modalTitleLoginRequest = {
  default: (color, props) => css`
    font-family: "Noe Display";
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};

    @media ${props.theme.device.mediaqueries.large_screen_min} {
      font-size: 32px;
      line-height: 1.38;
    }
  `,
};

const modalSubTitleLoginRequest = {
  default: (color, props) => css`
    font-family: "GT America";
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};

    @media ${props.theme.device.mediaqueries.desktop_screen_min} {
      font-size: 16px;
      line-height: 1.5;
    }
  `,
};

const vectorTabLabel = {
  default: (color, props) => css`
    font-family: "GT America";
    font-size: 10px;
    font-weight: 500;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
  `,
};

const questionnaireAnswer = {
  default: (color, props) => css`
    font-family: "GT America";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
      color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
  `,
};

const headerLogin = {
  default: (color, props) => css`
    font-family: "Noe Display";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: right;
    color: ${(color && props.theme.colors.primary[color]) || props.theme.colors.primary.black_90};
  `,
};


export default {
  display1,
  display2,
  headline,
  title,
  subtitle,
  body,
  small,
  caption,
  vehicleCardYear,
  vehicleCardTitle,
  vehicleCardPlate,
  vehicleCardLabel,
  vehicleCardInfo,
  vehicleCardYearDetail,
  vehicleCardTitleDetail,
  vehicleCardPlateLabelDetail,
  vehicleCardPlateDetail,
  vehicleInfoValue,
  vehicleInfoUnity,
  accordionTitle,
  accordionListItemValue,
  modalTitleLoginRequest,
  modalSubTitleLoginRequest,
  questionnaireAnswer,
  headerLogin,
  custom,
  subtitleLittler,
  vectorTabLabel,
  vehicleCardPlateOver,
};
