export const TYPES = {
  MAIN_RED: 'mainRed',
  MAIN_ON_BLACK: 'mainOnBlack',
  SECONDARY: 'secondary',
};

export const ICON_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};
