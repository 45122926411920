import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TRANSLATION_KEYS } from '../../../../i18n/index.enum';
import {
  ModalBodyLoginRequestContainer,
  TitleContainer,
  SubTitleContainer,
  ButtonContainer,
} from './index.style';
import Typography from '../../../../atoms/Typography';
import { colorsNameKey } from '../../../../const/theme.const';
import Button from '../../../../atoms/Button';

const ModalBodyLoginRequest = ({ onLoginClick, onRegisterClick }) => {
  const { t } = useTranslation();
  return (
    <ModalBodyLoginRequestContainer>
      <div>
        <TitleContainer>
          <Typography
            tag="p"
            type={Typography.TYPES.MODAL_TITLE_LOGIN_REQUEST}
            color={colorsNameKey.BLACK_90}
          >
            {t(TRANSLATION_KEYS.CAR_DETAIL.MODAL.LOGIN_REQUIRED_TITLE)}
          </Typography>
        </TitleContainer>
        <SubTitleContainer>
          <Typography
            tag="p"
            type={Typography.TYPES.MODAL_SUBTITLE_LOGIN_REQUEST}
            color={colorsNameKey.BLACK_90}
          >
            {t(TRANSLATION_KEYS.CAR_DETAIL.MODAL.LOGIN_REQUIRED_SUBTITLE)}
          </Typography>
        </SubTitleContainer>
      </div>
      <ButtonContainer>
        <Button type={Button.TYPES.MAIN_RED} onClick={() => onLoginClick()}>
          {t(TRANSLATION_KEYS.CAR_DETAIL.MODAL.BUTTON_GO_TO_LOGIN)}
        </Button>

        <Button type={Button.TYPES.SECONDARY} onClick={() => onRegisterClick()}>
          {t(TRANSLATION_KEYS.CAR_DETAIL.MODAL.BUTTON_REGISTER)}
        </Button>
      </ButtonContainer>
    </ModalBodyLoginRequestContainer>
  );
};

ModalBodyLoginRequest.defaultProps = {
  onLoginClick: () => null,
  onRegisterClick: () => null,
};

ModalBodyLoginRequest.propTypes = {
  onLoginClick: PropTypes.func,
  onRegisterClick: PropTypes.func,
};

export default ModalBodyLoginRequest;
