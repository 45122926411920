import { connect } from 'react-redux';

const ErrorManager = ({ errors }) => {
  console.log(errors);
  return null;
};

export default connect(
  (state) => {
    const errors = {};
    Object.keys(state).forEach((key) => {
      errors[key] = state[key].error;
    });
    return { errors };
  },
  () => null,
)(ErrorManager);
