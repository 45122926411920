import { LOCATION_CHANGE } from 'redux-first-history';
import { INITIALIZE_APP_STATE, SPINNER_OPEN, SPINNER_CLOSE } from '../actions/types';

const initialState = {
  token:
    'Basic RUZYVV9ERUFMRVJNT0JJTEU0REVWX01PQklMRV9BTk9OWU1PVVNfQVBQSUQ6ZWNzdllfcTYxcHV5Zno=',
  spinner: false,
};

export const app = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state;
    case SPINNER_OPEN:
      return { ...state, spinner: true };
    case SPINNER_CLOSE:
      return { ...state, spinner: false };
    case INITIALIZE_APP_STATE:
      return state;
    default:
      return state;
  }
};
