export const apiEnv = {
  LAST_PATH: {
    LIGHTHOUSE: 'lighthouse',
    LIGHTHOUSE_AUTH: 'lighthouseAuth',
  },
  APIS: {
    GET_ALL_MODEL_CAR: 'getAllModelCar',
    GET_ALL_CERTIFICATION_LIGHT_HOUSE: 'getAllCertificationLightHouse',
    GET_VEHICLE_CARD_INFORMATION: 'getVehicleCardInformation',
    NEGOTIABILITY: 'negotiability',
    GET_CERTIFICATION_DETAILS: 'getCertificationDetails',
    DO_LOGIN: 'getCertificationDetails',
  },
  env:
    window.location.hostname.match(/.*\.test\..*/)
    || window.location.hostname.match(/.*localhost.*/)
      ? 'dev'
      : 'prod',
  dev: {
    url: (lastPath, api) => `https://B1DB3B40CDF3476EBBE13A0A302A3D5E.mobile.ocp.oraclecloud.com:443/mobile/custom/${lastPath}/${api}?version=3.0`,
    urlAccessToken: () => 'https://login.test.ferrari.com/connect/token',
    urlConvertToken: () => 'https://B1DB3B40CDF3476EBBE13A0A302A3D5E.mobile.ocp.oraclecloud.com:443/mobile/platform/auth/token',
    loginUrl: () => 'https://login.test.ferrari.com/connect/authorize',
    client_id: '3a1e863a-1445-4a85-aa8c-19fde3c9bc6a',
    client_id_mcs: 'ceda7d5ef6bb4d938889cea5cfbec425',
    client_id_name: 'lighthouse',
    client_secret: '?GX8H46LGrjko2GVkEGKM-',
    grant_type_request_access_token: 'authorization_code',
    grant_type_refresh_access_token: 'refresh_token',
    grant_type_convert_access_token:
      'urn:ietf:params:oauth:grant-type:jwt-bearer',
    scope: 'openid offline_access',
    response_type: 'code',
    prompt: 'none',
    redirect_uri: (pathname) => `https://lighthouse.test.ferrari.com${pathname}`,
    state: 'ANgMpVS8kemaRXyOh2pn',
    nonce: 'nsPTrbNoJwo5m72Fh6VQ',
    basic_token:
      'QjFEQjNCNDBDREYzNDc2RUJCRTEzQTBBMzAyQTNENUVfTW9iaWxlQW5vbnltb3VzX0FQUElEOjcyOWM0MDEwLTczM2ItNGNiMi1hM2MxLTg1YWQ0NTJiMzQxZg==',
    mailto: 'FerrariClassiche@Ferrari.com',
    mailtoOwnership: 'CustomerService@owners.Ferrari.com',
  },
  prod: {
    url: (lastPath, api) => `https://64BB1BD6993C4F448D6048C0609B09D1.mobile.ocp.oraclecloud.com:443/mobile/custom/${lastPath}/${api}?version=4.0`,
    urlAccessToken: () => 'https://login.ferrari.com/connect/token',
    urlConvertToken: () => 'https://64BB1BD6993C4F448D6048C0609B09D1.mobile.ocp.oraclecloud.com:443/mobile/platform/auth/token',
    loginUrl: () => 'https://login.ferrari.com/connect/authorize',
    client_id: '1dfcb762-460d-4608-ba19-5cb8e355e37e',
    client_id_mcs: 'cdb2ac33b509422d9432f5dd399f3947',
    client_id_name: 'lighthouse',
    client_secret: '?Jnn9BZPZGpEzHbhXpSdl-',
    grant_type_request_access_token: 'authorization_code',
    grant_type_refresh_access_token: 'refresh_token',
    grant_type_convert_access_token:
      'urn:ietf:params:oauth:grant-type:jwt-bearer',
    scope: 'openid offline_access',
    response_type: 'code',
    prompt: 'none',
    redirect_uri: (pathname) => `https://registro.ferrari.com${pathname}`,
    state: 'ANgMpVS8kemaRXyOh2pn',
    nonce: 'nsPTrbNoJwo5m72Fh6VQ',
    basic_token:
      'NjRCQjFCRDY5OTNDNEY0NDhENjA0OEMwNjA5QjA5RDFfTW9iaWxlQW5vbnltb3VzX0FQUElEOmJjOWEzMTZiLWIyMGMtNDEyMS1iMTkwLTllY2MyMDM3MDU0OQ==',
    mailto: 'FerrariClassiche@Ferrari.com',
    mailtoOwnership: 'CustomerService@owners.Ferrari.com',
  },
};

const headersForJson = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'oracle-mobile-backend-id': apiEnv[apiEnv.env].client_id,
  Authorization: `Basic ${apiEnv[apiEnv.env].basic_token}`,
};

const headersForPlainText = {
  Accept: '*/*',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const headersForm = {
  Accept: '*/*',
  Authorization: `Basic ${apiEnv[apiEnv.env].basic_token}`,
};

const headersWithAuthentication = (token) => ({
  ...headersForJson,
  Authorization: `Bearer ${token}`,
});

const headersWithAuthenticationForm = (token) => ({
  ...headersForm,
  Authorization: `Bearer ${token}`,
});

function client(method, url, token, data, customHeaders, noParse) {
  const headers = customHeaders
    || (token ? headersWithAuthentication(token) : headersForJson);
  const params = { headers, method };

  if (typeof data !== 'undefined') {
    params.body = !noParse ? JSON.stringify(data) : data;
  }
  return fetch(url, params).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw Error(response.statusText);
  });
}

function clientFormData(method, url, token, data) {
  const headers = token ? headersWithAuthenticationForm(token) : headersForm;
  const params = { headers, method };
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  if (typeof data !== 'undefined') {
    params.body = formData;
  }

  return fetch(url, params).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw Error(response.statusText);
  });
}

function del(token) {
  return (endpoint, body) => {
    const url = new URL(endpoint);

    return client('DELETE', url, token, body);
  };
}

function get(token) {
  return (endpoint, searchParams) => {
    const url = new URL(endpoint);
    if (typeof searchParams !== 'undefined') {
      Object.keys(searchParams).forEach((key) => {
        url.searchParams.append(key, searchParams[key]);
      });
    }

    return client('GET', url.toString(), token);
  };
}

function patch(token) {
  return (endpoint, data) => {
    const url = new URL(endpoint);

    return client('PATCH', url.toString(), token, data);
  };
}

function post(token, textPlain) {
  return (endpoint, data) => {
    const url = new URL(endpoint);
    const headers = textPlain ? { ...headersForPlainText } : null;
    return client('POST', url.toString(), token, data, headers, textPlain);
  };
}

function postWithForm(token) {
  return (endpoint, data) => {
    const url = new URL(endpoint);

    return clientFormData('POST', url.toString(), token, data);
  };
}

function put(token) {
  return (endpoint, data) => {
    const url = new URL(endpoint);

    return client('PUT', url.toString(), token, data);
  };
}

export default {
  del,
  get,
  patch,
  post,
  put,
  postWithForm,
};
