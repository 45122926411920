/* eslint-disable camelcase */
import {
  takeLatest, select, call, put,
} from 'redux-saga/effects';
import {
  GET_VEHICLE_CARD_INFORMATION,
  NEGOTIABILITY,
} from '../actions/car-detail';
import {
  getVehicleCardInformation,
  negotiability,
} from '../../utils/apis/car-detail';
import { SPINNER_OPEN, SPINNER_CLOSE } from '../actions/types';

function* getVehicleCardInformationFn({ payload: body }) {
  try {
    yield put({
      type: SPINNER_OPEN,
    });
    const { access_token: token, gui_id } = yield select(({ login }) => (login.logged ? { access_token: login.access_token } : { token: null }));
    const { data } = yield call(
      getVehicleCardInformation,
      {
        ...body,
        ...(gui_id ? { gui_id } : {}),
      },
      token,
    );

    yield put({
      type: GET_VEHICLE_CARD_INFORMATION._SUCCESS,
      newState: { ...data },
    });
  } catch (e) {
    yield put({ type: GET_VEHICLE_CARD_INFORMATION._ERROR, error: e });
  } finally {
    yield put({ type: SPINNER_CLOSE });
  }
}

function* watchGetVehicleCardInformation() {
  yield takeLatest(
    GET_VEHICLE_CARD_INFORMATION._REQUEST,
    getVehicleCardInformationFn,
  );
}

function* negotiabilityFn({ payload: body }) {
  try {
    yield put({
      type: SPINNER_OPEN,
    });
    const { access_token: token, gui_id } = yield select(({ login }) => login);
    if (!token) {
      throw new Error('NOT_LOGGED');
    }
    yield call(negotiability, {
      ...body,
      ...(gui_id ? { gui_id } : {}),
    }, token);
    yield put({
      type: GET_VEHICLE_CARD_INFORMATION._REQUEST,
      payload: { gui_id: body.gui_id, vin: body.vin },
    });
  } catch (e) {
    yield put({ type: NEGOTIABILITY._ERROR, error: e });
  } finally {
    yield put({ type: SPINNER_CLOSE });
  }
}

function* watchNegotiability() {
  yield takeLatest(NEGOTIABILITY._REQUEST, negotiabilityFn);
}

export default [watchGetVehicleCardInformation, watchNegotiability];
