import styled, { css } from 'styled-components';

export const ImageCardContainer = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;

  ${({ hasFullView }) => hasFullView
    && css`
      height: 100vh;
      position: relative;
      top: 50%;
      margin-top: auto;
      transform: translateY(-50%);
  `}

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 34%;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 66%, #000000);
  }
  > img {
    transition: 0.2s;
    width: 100%;
  }
  &:hover {
    > img {
      transform: scale(1.05);
      transform-origin: center center;
    }
  }
`;

export const ImageCardFooter = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 16px;
  bottom: 0;
  left: 0%;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  > p,
  > i {
    color: ${({ theme }) => theme.colors.primary.white};
  }
  > i {
    cursor: pointer;
    margin-left: auto;
    margin-right: 0;
  }
`;

export const ImageContainer = styled.div`
  ${({ image }) => image
    && css`
      background-image: url(${image});
    `}
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: center;
  background-position: center;

  min-height: 240px;

  @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
    min-height: 340px;
  }

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    min-height: 400px;
  }

  @media ${(props) => props.theme.device.mediaqueries.desktop_large_screen_min} {
    min-height: 530px;
  }


  ${({ hasFullView }) => hasFullView
    && css`
      height: 100%
      background-size: contain;
      min-height: 100%;

      @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
        min-height: 100%;
      }
  `}
`;
