import React from 'react';
import PropTypes from 'prop-types';
import { QuestionnaireContainer } from './index.style';
import Typography from '../../atoms/Typography';
import { colorsNameKey } from '../../const/theme.const';

const Questionnaire = ({ question, answer, textAnswer }) => (
  <QuestionnaireContainer textAnswer={textAnswer}>
    <Typography
      tag="p"
      type={Typography.TYPES.ACCORDION_TITLE}
      color={colorsNameKey.BLACK_90}
    >
      {question}
    </Typography>
    <Typography
      tag="span"
      type={textAnswer
        ? Typography.TYPES.QUESTIONNARIE_ANSWER
        : Typography.TYPES.ACCORDION_TITLE}
      color={colorsNameKey.BLACK_50}
    >
      {answer}
    </Typography>
  </QuestionnaireContainer>
)

Questionnaire.defaultProps = {
  question: null,
  answer: null,
  textAnswer: false,
}

Questionnaire.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  textAnswer: PropTypes.bool,
}

export default Questionnaire;
