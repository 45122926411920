import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ROUTES } from './index.enum';

export const RouterContainer = () => (
  <Switch>
    {Object.values(ROUTES).map(({
      path, ToRender, redirect,
    }) => (redirect ? (
      <Route exact key={path}>
        <Redirect to={{ pathname: `/${redirect}` }} />
      </Route>
    ) : (
      <Route
        exact
        key={path}
        path={`/${path}`}
        render={(props) => <ToRender {...props} />}
      />
    )))}
  </Switch>
);
