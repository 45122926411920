const primaryColors = {
  rosso_corsa_light: '#f4583c',
  rosso_corsa: '#DA291C',
  rosso_corsa_dark: '#b01e0a',
  rosso_corsa_darker: '#9d2211',
  giallo_modena: '#F6E500',
  black_100: '#181818',
  black_90: '#303030',
  black_70: '#808080',
  black_50: '#8f8f8f',
  black_40: '#a4a4a4',
  black_20: '#D4D2D2',
  black_15: '#d0d0d0',
  black_10: '#efeded',
  black_05: '#eeeded',
  white: '#ffffff',
  transparent: 'transparent',
};

const colors = {
  primary: { ...primaryColors },
  buttons: {
    main_red: {
      text: {
        normal: primaryColors.white,
        hover: primaryColors.white,
        pressed: primaryColors.white,
        disabled: primaryColors.black_20,
      },
      normal: `radial-gradient(ellipse at center bottom, ${primaryColors.rosso_corsa_light} 0%, ${primaryColors.rosso_corsa} 62%, ${primaryColors.rosso_corsa} 100%);`,
      hover: primaryColors.rosso_corsa_dark,
      pressed: primaryColors.rosso_corsa_darker,
      disabled: primaryColors.black_10,
    },
    main_on_black: {
      text: {
        normal: primaryColors.black_90,
        hover: primaryColors.black_90,
        pressed: primaryColors.black_90,
        disabled: primaryColors.black_20,
      },
      normal: primaryColors.white,
      hover: primaryColors.black_10,
      pressed: primaryColors.black_20,
      disabled: primaryColors.black_10,
    },
    secondary: {
      text: {
        normal: primaryColors.black_90,
        hover: primaryColors.black_90,
        pressed: primaryColors.black_70,
        disabled: primaryColors.black_20,
      },
      border: {
        normal: primaryColors.black_20,
        hover: primaryColors.black_90,
        pressed: primaryColors.black_70,
        disabled: primaryColors.black_15,
      },
      normal: primaryColors.transparent,
      hover: primaryColors.transparent,
      pressed: primaryColors.transparent,
      disabled: primaryColors.transparent,
    },
    link: {
      text: {
        normal: primaryColors.black_90,
        hover: primaryColors.black_90,
        pressed: primaryColors.black_70,
        disabled: primaryColors.black_20,
      },
      border: {
        hover: primaryColors.black_90,
        pressed: primaryColors.black_70,
      },
      normal: primaryColors.transparent,
      hover: primaryColors.transparent,
      pressed: primaryColors.transparent,
      disabled: primaryColors.transparent,
    },
    text_only: {
      text: {
        normal: primaryColors.black_90,
        hover: primaryColors.black_90,
        pressed: primaryColors.black_90,
        disabled: primaryColors.black_20,
      },
      normal: primaryColors.transparent,
      hover: primaryColors.transparent,
      pressed: primaryColors.transparent,
      disabled: primaryColors.transparent,
    },
  },
};

export default {
  colors,
};
