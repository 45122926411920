import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_KEYS } from '../../i18n/index.enum';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import { colorsNameKey } from '../../const/theme.const';
import altImg from '../../logo-ferrari.png';

import {
  VehicleMask,
  StatusContainer,
  Status,
  CarMainInfo,
  HeroImagePreviewContainer,
} from './index.style';

const HeroImagePreview = ({
  productionYear,
  model,
  vin,
  isNegotiable,
  UserOwner,
  isUserLogged,
  notNegozianbleLabelButton,
  manageYourCarLabelButton,
  requestInfoLabelButton,
  requestOwnershipLabelButton,
  negotiableLabelButton,
  manageShowModal,
  manageShowModalInfo,
  imgCar,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <HeroImagePreviewContainer>
        <img src={imgCar || altImg} alt="" />
        <VehicleMask />
        <CarMainInfo>
          <Typography
            tag="p"
            type={Typography.TYPES.CARD_YEAR_DETAIL}
            color={colorsNameKey.WHITE}
          >
            {productionYear}
          </Typography>
          <Typography
            tag="p"
            type={Typography.TYPES.CARD_TITLE_DETAIL}
            color={colorsNameKey.WHITE}
          >
            {model}
          </Typography>
          <Typography
            tag="p"
            type={Typography.TYPES.CARD_PLATE_LABEL_DETAIL}
            color={colorsNameKey.WHITE}
          >
            {t(TRANSLATION_KEYS.CAR_DETAIL.VIN)}
          </Typography>
          <Typography
            tag="p"
            type={Typography.TYPES.CARD_PLATE_DETAIL}
            color={colorsNameKey.WHITE}
          >
            {vin}
          </Typography>
          {/* no logged */}
          {!isUserLogged ? (
            <>
              <StatusContainer>
                <Status>
                  <Button
                    type={Button.TYPES.MAIN_ON_BLACK}
                    onClick={manageShowModal}
                  >
                    {requestInfoLabelButton}
                  </Button>
                </Status>
                <Status>
                  <Button
                    type={Button.TYPES.MAIN_ON_BLACK}
                    onClick={manageShowModal}
                  >
                    {requestOwnershipLabelButton}
                  </Button>
                </Status>
              </StatusContainer>
            </>
          ) : (
            <>
              {UserOwner ? (
                <StatusContainer>
                  <Status>
                    <Button
                      type={Button.TYPES.MAIN_ON_BLACK}
                      onClick={manageShowModal}
                    >
                      {manageYourCarLabelButton}
                    </Button>
                  </Status>
                </StatusContainer>
              ) : (
                <>
                  <StatusContainer>
                    {isNegotiable !== null && (
                      <>
                        {!isNegotiable ? (
                          <Status>
                            <Typography
                              tag="p"
                              type={Typography.TYPES.CAPTION}
                              color={colorsNameKey.WHITE}
                            >
                              {notNegozianbleLabelButton}
                            </Typography>
                          </Status>
                        ) : (
                          <Status>
                            <Typography
                              tag="p"
                              type={Typography.TYPES.CAPTION}
                              color={colorsNameKey.WHITE}
                            >
                              {negotiableLabelButton}
                            </Typography>
                          </Status>
                        )}
                      </>
                    )}

                    <Status>
                      <Button
                        type={Button.TYPES.MAIN_ON_BLACK}
                        onClick={manageShowModalInfo}
                      >
                        {requestInfoLabelButton}
                      </Button>
                    </Status>
                    <Status>
                      <Button
                        type={Button.TYPES.MAIN_ON_BLACK}
                        onClick={manageShowModal}
                      >
                        {requestOwnershipLabelButton}
                      </Button>
                    </Status>
                  </StatusContainer>
                </>
              )}
            </>
          )}
        </CarMainInfo>
      </HeroImagePreviewContainer>
    </>
  );
};

HeroImagePreview.defaultProps = {
  productionYear: null,
  model: null,
  vin: null,
  isNegotiable: false,
  UserOwner: false,
  isUserLogged: false,
  negotiableLabelButton: null,
  notNegozianbleLabelButton: null,
  manageYourCarLabelButton: null,
  requestInfoLabelButton: null,
  requestOwnershipLabelButton: null,
  // requestInfoModalOpen: () => null,
  // requestOwnehipSModalOpen: () => null,
  manageShowModal: () => null,
  manageShowModalInfo: () => null,
  imgCar: null,
};

HeroImagePreview.propTypes = {
  productionYear: PropTypes.number,
  model: PropTypes.string,
  vin: PropTypes.string,
  isNegotiable: PropTypes.bool,
  UserOwner: PropTypes.bool,
  isUserLogged: PropTypes.bool,
  negotiableLabelButton: PropTypes.string,
  notNegozianbleLabelButton: PropTypes.string,
  manageYourCarLabelButton: PropTypes.string,
  requestInfoLabelButton: PropTypes.string,
  requestOwnershipLabelButton: PropTypes.string,
  // requestInfoModalOpen: PropTypes.func,
  // requestOwnehipSModalOpen: PropTypes.func,
  manageShowModal: PropTypes.func,
  manageShowModalInfo: PropTypes.func,
  imgCar: PropTypes.string,
};

export default HeroImagePreview;
