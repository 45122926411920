import Typography from '../Typography';
import { colorsNameKey } from '../../const/theme.const';

export const TYPES = {
  SEARCH: {
    name: 'SEARCH',
    type: Typography.TYPES.BODY,
    style: Typography.STYLES.ALT,
    showPlaceholderAlways: false,
    color: {
      placeholder: colorsNameKey.BLACK_50,
      value: colorsNameKey.BLACK_90,
    },
  },
  FORM: {
    name: 'FORM',
    type: Typography.TYPES.BODY,
    style: Typography.STYLES.DEFAULT,
    showPlaceholderAlways: true,
    color: {
      placeholder: colorsNameKey.BLACK_50,
      value: colorsNameKey.BLACK_90,
    },
  },
};
