import { GET_CERTIFICATION_DETAILS } from '../actions/certification';

const initialState = {
  currentCertification: {},
  error: null,
};

export const certification = (state = initialState, action) => {
  switch (action.type) {
    case GET_CERTIFICATION_DETAILS._SUCCESS:
      return { ...state, currentCertification: { ...action.newState }, error: null };
    case GET_CERTIFICATION_DETAILS._ERROR:
      return { ...state, currentCertification: {}, error: action.error };
    default:
      return state;
  }
};
