import React from 'react';
import '../../App.scss';
import Icon from '../../atoms/Icon';
import { colorsNameKey } from '../../const/theme.const';
import { ScrollToTopContainer } from './index.style';

const ScrollToTopButton = () => (
  <ScrollToTopContainer onClick={() => window.scrollTo(0, 0)}>
    <Icon
      icon={Icon.ICONS_TYPE.SCROLLUP}
      size={18}
      color={colorsNameKey.WHITE}
    />
  </ScrollToTopContainer>
);

ScrollToTopButton.defaultProps = {
};

ScrollToTopButton.propTypes = {
};

export default ScrollToTopButton;
