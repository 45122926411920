import styled from 'styled-components';

export const InputSearchModalHomeFilterContainer = styled.div`
    margin-top: 48px;
    margin-bottom: 24px;
`;

export const ModalHomeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  margin-bottom: 32px;

  button {
    &:first-child {
      margin-bottom: 24px;
    }
  }
`;
