import React from 'react';
import Proptypes from 'prop-types';
import { SectorTabContainer } from './index.style';
import Typography from '../../atoms/Typography';
import { TYPES } from './index.enum';

export const SectorTab = ({
  label, value, type, onClick,
}) => (
  <SectorTabContainer onClick={onClick}>
    <Typography
      tag="p"
      type={Typography.TYPES.VECTOR_TAB_LABEL}
      style={Typography.STYLES.DEFAULT}
    >
      {label}
    </Typography>
    <Typography
      tag="p"
      type={Typography.TYPES[type]}
      style={Typography.STYLES.ALT}
    >
      {value || '-'}
    </Typography>
  </SectorTabContainer>
);

SectorTab.TYPES = TYPES

SectorTab.defaultProps = {
  type: TYPES.NORMAL,
  onClick: () => null,
  value: '-',
};

SectorTab.propTypes = {
  type: Proptypes.oneOf(Object.values(TYPES)),
  label: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.node,
    Proptypes.element,
  ]).isRequired,
  value: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.node,
    Proptypes.element,
  ]),
  onClick: Proptypes.func,
};

export default SectorTab;
