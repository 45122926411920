import styled, { css } from 'styled-components';

export const IconContainer = styled.i`
${({ size }) => size
  && css`
    font-size: ${size}px;
  `}
  color: ${({ color, theme }) => (color ? theme.colors.primary[color] : theme.colors.primary.black_90)};
  &:before {
    color: inherit;
  }
  .path2 {
    color: ${({ color, theme }) => (color ? theme.colors.primary[color] : theme.colors.primary.black_90)};
    &:before {
      color: ${({ color, theme }) => (color ? theme.colors.primary[color] : theme.colors.primary.black_90)};
    }
  }
  .path1 {
    color: ${({ secondColor, theme }) => (secondColor
    ? theme.colors.primary[secondColor]
    : theme.colors.primary.black_10)};
    &:before {
       color: ${({ secondColor, theme }) => (secondColor
    ? theme.colors.primary[secondColor]
    : theme.colors.primary.black_10)};
    }
  }
`;

export const IconCircleContainer = styled.div`
  border: none;
  outline: none;
  background: none;
  height: 40px;
  width: 40px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${({ hoverRingColor, theme }) => (hoverRingColor
    ? theme.colors.primary[hoverRingColor]
    : theme.colors.primary.black_05)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  cursor: pointer;
  > i {
    cursor: pointer;
  }
  > svg {
    position: absolute;
    > circle {
      stroke-dashoffset: -180px;
      transition: stroke-dashoffset 1.2s;
      transform: rotate(-90deg);
      transform-origin: center center;
      stroke: ${({ hoverRingProgressColor, theme }) => (hoverRingProgressColor
    ? theme.colors.primary[hoverRingProgressColor]
    : theme.colors.primary.rosso_corsa)};
      stroke-width: 2px;
    }
  }
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    > svg {
      > circle {
        stroke-dashoffset: 0;
      }
    }
  }
`;
