// import { createAsyncActionType } from './async';

export function createAsyncActionType(section = '', type = '', ...extra) {
  const action = {
    _REQUEST: `@@${section.toLowerCase()}/${type.toUpperCase()}_REQUEST`,
    _SUCCESS: `@@${section.toLowerCase()}/${type.toUpperCase()}_SUCCESS`,
    _ERROR: `@@${section.toLowerCase()}/${type.toUpperCase()}_ERROR`,
  };
  if (extra && extra.length) {
    extra.forEach((key) => {
      action[key] = `@@${section.toLowerCase()}/${type.toUpperCase()}${key}`;
    });
  }
  return action;
}

/* GLOBAL */
export const SPINNER_CLOSE = 'SPINNER_CLOSE';
export const SPINNER_OPEN = 'SPINNER_OPEN';
export const INITIALIZE_APP_STATE = 'INIT_APP';
export const FEEDBACK_POST = createAsyncActionType('global', 'FEEDBACK_POST');
