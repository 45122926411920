import styled, { css } from 'styled-components';
import { windowSize } from '../../ui/theme/device';

export const OverDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.black_05};
  margin-bottom: 12px;
`;

export const StepperContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 6px;
  min-height: 35px;
  position: relative;
  &::before {
    ${() => windowSize().size === 'S'
      && css`
        content: "";
      `}
    width: 0;
    transition: 0.15s;
    background-color: ${({ theme }) => theme.colors.primary.black_05};
    position: absolute;
    bottom: 0;
    height: 4px;
    width: 100%;
  }
  &::after {
    content: "";
    width: 0;
    transition: 0.15s;
    background-color: ${({ theme }) => theme.colors.primary.rosso_corsa};
    position: absolute;
    bottom: 0;
    height: 4px;
    ${({ howManySteps, currentStepActive }) => css`
      width: ${(100 / howManySteps) * currentStepActive}%;
    `}
  }
`;

export const TypographyContainer = styled.div`
  ${({ howManySteps }) => css`
    width: ${100 / howManySteps}%;
  `}
  display: flex;
  align-items: center;
  ${({ step, howManySteps }) => (step <= howManySteps / 2
      && css`
        justify-content: flex-start;
      `)
    || (step > howManySteps / 2
      && css`
        justify-content: flex-end;
      `)}
  > p {
    cursor: pointer;
    max-width: 200px;
    text-align: center;
    ${({ theme, active }) => css`
      color: ${active
    ? theme.colors.primary.black_90
    : theme.colors.primary.black_20};
    `}
  }
  ${() => windowSize().size === 'S'
    && css`
      justify-content: flex-start;
      > p {
        max-width: 100%;
      }
    `}
`;
