import { initReactI18next } from 'react-i18next';
import i18n from '../../node_modules/i18next';

// language.json
import EN from './en.json';
import IT from './it.json';

const resources = {
  en: { translation: { ...EN } },
  it: { translation: { ...IT } },
};

i18n.use(initReactI18next).init({
  resources,
  lng: document.querySelector('html').lang || 'en',
  fallbackLng: 'en',
  keySeparator: '.',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
