import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalBodyManageYourCarContainer,
  TitleContainer,
  SubTitleContainer,
} from './index.style';
import Typography from '../../../../atoms/Typography'
import { colorsNameKey } from '../../../../const/theme.const';


const ModalBodyStatusConfirmed = ({ titleStatus, subTitleStatus }) => (
  <ModalBodyManageYourCarContainer>
    <div>
      <TitleContainer>
        <Typography
          tag="p"
          type={Typography.TYPES.MODAL_TITLE_LOGIN_REQUEST}
          color={colorsNameKey.BLACK_90}
        >
          {titleStatus}
        </Typography>
      </TitleContainer>
      <SubTitleContainer>
        <Typography
          tag="p"
          type={Typography.TYPES.MODAL_SUBTITLE_LOGIN_REQUEST}
          color={colorsNameKey.BLACK_90}
        >
          {subTitleStatus}
        </Typography>
      </SubTitleContainer>
    </div>

  </ModalBodyManageYourCarContainer>
)

ModalBodyStatusConfirmed.defaultProps = {
  titleStatus: null,
  subTitleStatus: null,
};

ModalBodyStatusConfirmed.propTypes = {
  titleStatus: PropTypes.string,
  subTitleStatus: PropTypes.string,

};


export default ModalBodyStatusConfirmed;
