/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoginHeaderContainer } from './index.style';
import Typography from '../../atoms/Typography';
import Icon from '../../atoms/Icon';

import { colorsNameKey } from '../../const/theme.const';
import { getQueryVariable } from '../../utils/query';
import { createLoginUrl } from '../../utils/login';

const LoginHeader = ({ logged: userLogged }) => {
  const firstRender = useRef(true)

  if (
    window.location.href.indexOf('localhost') < 0
    && !getQueryVariable('error')
    && !getQueryVariable('code')
    && !userLogged
    && firstRender
  ) {
    window.location.replace(createLoginUrl(false));
  }

  useEffect(() => {
    firstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginHeaderContainer userLogged={userLogged}>
      {userLogged ? (
        <Icon
          size={24}
          color={colorsNameKey.WHITE}
          icon={Icon.ICONS_TYPE.USER}
        />
      ) : (
        <Typography
          onClick={() => {
            window.location.replace(createLoginUrl());
          }}
          tag="a"
          type={Typography.TYPES.HEADERLOGIN}
          color={colorsNameKey.WHITE}
        >
          Login
        </Typography>
      )}
    </LoginHeaderContainer>
  );
};

LoginHeader.defaultProps = {
  logged: null,
};

LoginHeader.propTypes = {
  logged: PropTypes.bool,
};

export default connect(
  ({ login: { logged } }) => ({ logged }),
)(LoginHeader);
