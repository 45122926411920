import styled, { css } from 'styled-components';

export const BadgeContainer = styled.button`
  padding: 5px 10px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ background, theme }) => (background
    ? theme.colors.primary[background]
    : theme.colors.primary.rosso_corsa)};
  border: none;
  outline: none;
  &:focus,
  &:active {
    outline: none;
  }
  > p {
    cursor: pointer;
    color: ${({ color, theme }) => (color ? theme.colors.primary[color] : theme.colors.primary.white)};
    text-transform: uppercase;
  }

  ${({ hasNotCursorPointer }) => hasNotCursorPointer && css`
    &:hover, > p {
      cursor: default;
    }
  `}

  
`;
