import { TRANSLATION_KEYS } from '../../i18n/index.enum';

export const selectFilter = (translation) => [
  {
    id: 'model',
    option: [
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_MODELLO.LABEL_SELECTED),
        value: null,
        selected: true,
        disabled: true,
      },
    ],
  },
  {
    id: 'internal_color',
    disabled: true,
    option: [
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_COLORE_INTERNI.LABEL_SELECTED),
        value: null,
        selected: true,
        disabled: true,
      },
    ],
  },
  {
    id: 'external_color',
    disabled: true,
    option: [
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_COLORE_ESTERNI.LABEL_SELECTED),
        value: null,
        selected: true,
        disabled: true,
      },
    ],
  },
  {
    id: 'vehicleType',
    option: [
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_TIPO_VETTURE.LABEL_SELECTED),
        value: null,
        selected: true,
        disabled: true,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_TIPO_VETTURE.GRAN_TURISMO),
        value: 'Gran Turismo',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_TIPO_VETTURE.SPORT_PROTOTYPE),
        value: 'Sport Prototype',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_TIPO_VETTURE.F1),
        value: 'F1',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_TIPO_VETTURE.DINO),
        value: 'Dino',
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    id: 'status',
    option: [
      {
        label:
        translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_STATO_CERTIFICAZIONE.LABEL_SELECTED),
        value: null,
        selected: true,
        disabled: true,
      },
      // {
      //   label:
      //   translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_STATO_CERTIFICAZIONE.NOT_CERTIFIED),
      //   value: 'Vettura non certificata',
      //   selected: false,
      //   disabled: false,
      // },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_STATO_CERTIFICAZIONE.CURRENT),
        value: 'Current',
        selected: false,
        disabled: false,
      },
      {
        label:
        translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_STATO_CERTIFICAZIONE.NOT_CURRENT),
        value: 'Not Current',
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    id: 'car_nationality',
    option: [
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LABEL_SELECTED),
        value: null,
        selected: true,
        disabled: true,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.AFGHANISTAN),
        value: 'Afghanistan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ALBANIA),
        value: 'Albania',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ALGERIA),
        value: 'Algeria',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ANDORRA),
        value: 'Andorra',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ANGOLA),
        value: 'Angola',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ANTIGUA_AND_BARBUDA),
        value: 'Antigua and Barbuda',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAUDI_ARABIA),
        value: 'Saudi Arabia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ARGENTINA),
        value: 'Argentina',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ARMENIA),
        value: 'Armenia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.AUSTRALIA),
        value: 'Australia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.AUSTRIA),
        value: 'Austria',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.AZERBAIJAN),
        value: 'Azerbaijan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BAHAMAS),
        value: 'Bahamas',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BAHRAIN),
        value: 'Bahrain',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BANGLADESH),
        value: 'Bangladesh',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BARBADOS),
        value: 'Barbados',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BELGIUM),
        value: 'Belgium',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BELIZE),
        value: 'Belize',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BENIN),
        value: 'Benin',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BHUTAN),
        value: 'Bhutan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BELARUS),
        value: 'Belarus',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BURMA),
        value: 'Burma',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BOLIVIA),
        value: 'Bolivia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BOSNIA_AND_HERZEGOVINA),
        value: 'Bosnia and Herzegovina',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BOTSWANA),
        value: 'Botswana',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BRAZIL),
        value: 'Brazil',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BRUNEI),
        value: 'Brunei',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BULGARIA),
        value: 'Bulgaria',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BURKINA_FASO),
        value: 'Burkina Faso',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BURUNDI),
        value: 'Burundi',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CAMBODIA),
        value: 'Cambodia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CAMEROON),
        value: 'Cameroon',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CANADA),
        value: 'Canada',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CAPE_VERDE),
        value: 'Cape Verde',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CHAD),
        value: 'Chad',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CHILE),
        value: 'Chile',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CHINA),
        value: 'China',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CYPRUS),
        value: 'Cyprus',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.COLOMBIA),
        value: 'Colombia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.COMOROS),
        value: 'Comoros',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NORTH_KOREA),
        value: 'North Korea',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SOUTH_KOREA),
        value: 'South Korea',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.IVORY_COAST),
        value: 'Ivory Coast',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.COSTA_RICA),
        value: 'Costa Rica',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CROATIA),
        value: 'Croatia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CUBA),
        value: 'Cuba',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.DENMARK),
        value: 'Denmark',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.DOMINICA),
        value: 'Dominica',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ECUADOR),
        value: 'Ecuador',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.EGYPT),
        value: 'Egypt',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.EL_SALVADOR),
        value: 'El Salvador',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UNITED_ARAB_EMIRATES),
        value: 'United Arab Emirates',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ERITREA),
        value: 'Eritrea',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ESTONIA),
        value: 'Estonia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ETHIOPIA),
        value: 'Ethiopia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.FIJI),
        value: 'Fiji',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PHILIPPINES),
        value: 'Philippines',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.FINLAND),
        value: 'Finland',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.FRANCE),
        value: 'France',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GABON),
        value: 'Gabon',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GAMBIA),
        value: 'Gambia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GEORGIA),
        value: 'Georgia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GERMANY),
        value: 'Germany',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GHANA),
        value: 'Ghana',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.JAMAICA),
        value: 'Jamaica',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.JAPAN),
        value: 'Japan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.DJIBOUTI),
        value: 'Djibouti',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.JORDAN),
        value: 'Jordan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GREECE),
        value: 'Greece',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GRENADA),
        value: 'Grenada',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GUATEMALA),
        value: 'Guatemala',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GUINEA),
        value: 'Guinea',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.EQUATORIAL_GUINEA),
        value: 'Equatorial Guinea',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GUINEA_BISSAU),
        value: 'Guinea-Bissau',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GUYANA),
        value: 'Guyana',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.HAITI),
        value: 'Haiti',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.HONDURAS),
        value: 'Honduras',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.INDIA),
        value: 'India',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.INDONESIA),
        value: 'Indonesia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.IRAN),
        value: 'Iran',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.IRAQ),
        value: 'Iraq',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.IRELAND),
        value: 'Ireland',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ICELAND),
        value: 'Iceland',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MARSHALL_ISLANDS),
        value: 'Marshall Islands',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SOLOMON_ISLANDS),
        value: 'Solomon Islands',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ISRAEL),
        value: 'Israel',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ITALY),
        value: 'Italy',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.KAZAKHSTAN),
        value: 'Kazakhstan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.KENYA),
        value: 'Kenya',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.KYRGYZSTAN),
        value: 'Kyrgyzstan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.KIRIBATI),
        value: 'Kiribati',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.KUWAIT),
        value: 'Kuwait',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LAOS),
        value: 'Laos',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LESOTHO),
        value: 'Lesotho',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LATVIA),
        value: 'Latvia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LEBANON),
        value: 'Lebanon',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LIBERIA),
        value: 'Liberia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LIBYA),
        value: 'Libya',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LIECHTENSTEIN),
        value: 'Liechtenstein',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LITHUANIA),
        value: 'Lithuania',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LUXEMBOURG),
        value: 'Luxembourg',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NORTH_MACEDONIA),
        value: 'North Macedonia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MADAGASCAR),
        value: 'Madagascar',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MALAWI),
        value: 'Malawi',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MALAYSIA),
        value: 'Malaysia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MALDIVES),
        value: 'Maldives',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MALI),
        value: 'Mali',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MALTA),
        value: 'Malta',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MOROCCO),
        value: 'Morocco',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MAURITANIA),
        value: 'Mauritania',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MAURITIUS),
        value: 'Mauritius',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MEXICO),
        value: 'Mexico',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MOLDOVA),
        value: 'Moldova',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MONACO),
        value: 'Monaco',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MONGOLIA),
        value: 'Mongolia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MONTENEGRO),
        value: 'Montenegro',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MOZAMBIQUE),
        value: 'Mozambique',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NAMIBIA),
        value: 'Namibia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NAURU),
        value: 'Nauru',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NEPAL),
        value: 'Nepal',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NICARAGUA),
        value: 'Nicaragua',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NIGER),
        value: 'Niger',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NIGERIA),
        value: 'Nigeria',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NORWAY),
        value: 'Norway',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NEW_ZEALAND),
        value: 'New Zealand',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.OMAN),
        value: 'Oman',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NETHERLANDS),
        value: 'Netherlands',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PAKISTAN),
        value: 'Pakistan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PALAU),
        value: 'Palau',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PANAMA),
        value: 'Panama',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PAPUA_NEW_GUINEA),
        value: 'Papua New Guinea',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PARAGUAY),
        value: 'Paraguay',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PERU),
        value: 'Peru',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.POLAND),
        value: 'Poland',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PORTUGAL),
        value: 'Portugal',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.QATAR),
        value: 'Qatar',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CONGO_DR),
        value: 'Congo DR',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UNITED_KINGDOM),
        value: 'United Kingdom',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CZECH_REPUBLIC),
        value: 'Czech Republic',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CENTRAL_AFRICAN_REP),
        value: 'Central African Rep.',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CONGO_REP),
        value: 'Congo Rep.',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.DOMINICAN_REP),
        value: 'Dominican Rep.',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ROMANIA),
        value: 'Romania',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.RWANDA),
        value: 'Rwanda',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.RUSSIAN_FEDERATION),
        value: 'Russian Federation',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAINT_KITTS_AND_NEVIS),
        value: 'Saint Kitts and Nevis',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAINT_LUCIA),
        value: 'Saint Lucia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAINT_VINCENT_AND_THE_GRENADINES),
        value: 'Saint Vincent and the Grenadines',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAMOA),
        value: 'Samoa',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAN_MARINO),
        value: 'San Marino',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SÃO_TOMÉ_AND_PRÍNCIPE),
        value: 'São Tomé and Príncipe',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SENEGAL),
        value: 'Senegal',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SERBIA),
        value: 'Serbia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SEYCHELLES),
        value: 'Seychelles',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SIERRA_LEONE),
        value: 'Sierra Leone',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SINGAPORE),
        value: 'Singapore',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SYRIA),
        value: 'Syria',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SLOVAKIA),
        value: 'Slovakia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SLOVENIA),
        value: 'Slovenia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SOMALIA),
        value: 'Somalia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SPAIN),
        value: 'Spain',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SRI_LANKA),
        value: 'Sri Lanka',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.FEDERATED_STATES_OF_MICRONESIA),
        value: 'Federated States of Micronesia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UNITED_STATES),
        value: 'United States',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SOUTH_AFRICA),
        value: 'South Africa',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SUDAN),
        value: 'Sudan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SOUTH_SUDAN),
        value: 'South Sudan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SURINAME),
        value: 'Suriname',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SWEDEN),
        value: 'Sweden',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SWITZERLAND),
        value: 'Switzerland',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SWAZILAND),
        value: 'Swaziland',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TAJIKISTAN),
        value: 'Tajikistan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TANZANIA),
        value: 'Tanzania',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.THAILAND),
        value: 'Thailand',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.EAST_TIMOR),
        value: 'East Timor',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TOGO),
        value: 'Togo',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TONGA),
        value: 'Tonga',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TRINIDAD_AND_TOBAGO),
        value: 'Trinidad and Tobago',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TUNISIA),
        value: 'Tunisia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TURKEY),
        value: 'Turkey',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TURKMENISTAN),
        value: 'Turkmenistan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TUVALU),
        value: 'Tuvalu',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UKRAINE),
        value: 'Ukraine',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UGANDA),
        value: 'Uganda',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.HUNGARY),
        value: 'Hungary',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.URUGUAY),
        value: 'Uruguay',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UZBEKISTAN),
        value: 'Uzbekistan',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.VANUATU),
        value: 'Vanuatu',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.VENEZUELA),
        value: 'Venezuela',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.VIETNAM),
        value: 'Vietnam',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.YEMEN),
        value: 'Yemen',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ZAMBIA),
        value: 'Zambia',
        selected: false,
        disabled: false,
      },
      {
        label: translation(TRANSLATION_KEYS.HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ZIMBABWE),
        value: 'Zimbabwe',
        selected: false,
        disabled: false,
      },
    ],
  },
]
