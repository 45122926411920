import {
  GET_VEHICLE_CARD_INFORMATION,
  NEGOTIABILITY,
} from '../actions/car-detail';

const initialState = {
  currentVehicle: {},
  error: null,
};

export const carDetail = (state = initialState, action) => {
  switch (action.type) {
    case GET_VEHICLE_CARD_INFORMATION._SUCCESS:
      return { ...state, currentVehicle: { ...action.newState } };
    case GET_VEHICLE_CARD_INFORMATION._ERROR:
    case NEGOTIABILITY._ERROR:
      return { ...state, currentVehicle: {}, error: action.error };
    default:
      return state;
  }
};
