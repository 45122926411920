import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Splide from '@splidejs/splide';
import { UUIDGenerator } from '../../utils/id';
import { CarouselContainer, DotsContainer } from './index.style';
import Dots from './Dots';
import '../../../node_modules/@splidejs/splide/dist/css/splide.min.css';
import { TYPES } from './index.enum';
import theme from '../../ui/theme';

const CarouselComponent = ({
  // eslint-disable-next-line react/prop-types
  children,
  // eslint-disable-next-line react/prop-types
  autoplay,
  // eslint-disable-next-line react/prop-types
  sectionVisible,
  // eslint-disable-next-line react/prop-types
  currentSlide,
  // eslint-disable-next-line react/prop-types
  loop,
  // eslint-disable-next-line react/prop-types
  drag,
  // eslint-disable-next-line react/prop-types
  onSlideChange,
  // eslint-disable-next-line react/prop-types
  type,
  // eslint-disable-next-line react/prop-types
  dots,
  // eslint-disable-next-line react/prop-types
  visiblePerPage,
}) => {
  const carousel = useRef(null);
  const splider = useRef(null);
  const config = useRef({});

  const carouselId = UUIDGenerator('carousel');

  useEffect(() => {
    config.current = {
      arrows: false,
      pagination: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      perMove: 1,
      ...(type === TYPES.SEPARATED
        ? {
          perPage: visiblePerPage,
        }
        : {
          perPage: 1,
        }),
      ...(type === TYPES.SEQUENTIAL
        ? { fixedWidth: `${sectionVisible * 100}%` }
        : {}),
      ...(!dots && autoplay
        ? {
          autoplay: true,
          interval: autoplay * 1000,
          pauseOnHover: false,
          pauseOnFocus: false,
        }
        : {}),
      drag,
      ...(loop ? { type: 'loop' } : {}),
    };

    if (splider.current) {
      splider.current.destroy();
    }
    setTimeout(() => {
      if (carousel.current) {
        splider.current = new Splide(carousel.current, {
          ...config.current,
        }).mount();
        splider.current.on('move', (index) => {
          onSlideChange(index);
        });
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, visiblePerPage, sectionVisible, autoplay, loop, drag]);

  useEffect(() => {
    if (splider.current) {
      splider.current.go(currentSlide);
    }
  }, [currentSlide]);

  useEffect(
    () => () => {
      if (splider.current) {
        splider.current.destroy();
      }
    },
    [],
  );

  return (
    <div ref={carousel} className="splide" id={carouselId}>
      <div className="splide__track">
        <ul className="splide__list">
          {Array.from(children).map((child, i, array) => (
            <li
              key={i}
              onClick={() => (!drag
                ? (() => {
                  const newStep = i < array.length - 1
                    ? i === splider.current.index && i !== 0
                      ? i - 1
                      : i
                    : array.length - 1;
                  splider.current.go(newStep);
                })()
                : null)}
              className="splide__slide splide-container"
            >
              {child}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Carousel = ({
  children,
  autoplay,
  sectionVisible,
  currentSlide,
  loop,
  drag,
  onSlideChange,
  type,
  visiblePerPage,
  paddingPerSlide,
  dots,
  arrows,
  arrowColors,
  dotsAreInAbsolute,
  dotsColor,
  ringColor,
  isInFullView,
  howManyDotsToSee,
}) => {
  const [currentDot, setCurrentDot] = useState(currentSlide);
  const [currentSlideInner, setCurrentSlideInner] = useState(currentSlide);

  useEffect(() => {
    setCurrentSlideInner(currentSlide);
    setCurrentDot(currentSlide);
  }, [currentSlide]);

  return (
    <CarouselContainer
      paddingPerSlide={type === TYPES.SEPARATED ? paddingPerSlide : false}
      sectionVisible={sectionVisible}
      isInFullView={isInFullView}
    >
      <CarouselComponent
        autoplay={autoplay}
        sectionVisible={sectionVisible}
        currentSlide={currentSlideInner}
        loop={loop}
        drag={drag}
        dots={dots}
        onSlideChange={(index) => {
          setCurrentDot(index);
          onSlideChange(index);
        }}
        type={type}
        visiblePerPage={visiblePerPage}
      >
        {children}
      </CarouselComponent>
      {dots && (
        <DotsContainer dotsAreInAbsolute={dotsAreInAbsolute}>
          <Dots
            arrows={arrows}
            arrowColors={arrowColors}
            color={dotsColor}
            ringColor={ringColor}
            className="dots"
            currentDot={currentDot}
            howManyDotsToSee={howManyDotsToSee}
            autoplay={autoplay}
            onChange={(index) => {
              setCurrentSlideInner(index);
              setCurrentDot(index);
            }}
            dots={Math.floor(Array.from(children).length)}
          />
        </DotsContainer>
      )}
    </CarouselContainer>
  );
};

Carousel.TYPES = TYPES;

Carousel.defaultProps = {
  autoplay: null,
  sectionVisible: 0.7,
  currentSlide: 0,
  loop: false,
  drag: false,
  onSlideChange: () => null,
  type: TYPES.SEQUENTIAL,
  visiblePerPage: 3,
  paddingPerSlide: '12px',
  dots: false,
  arrows: false,
  arrowColors: 'white',
  dotsAreInAbsolute: false,
  dotsColor: 'white',
  ringColor: 'white',
  isInFullView: false,
  howManyDotsToSee: -1,
};

Carousel.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  ).isRequired,
  autoplay: PropTypes.number,
  sectionVisible: PropTypes.number,
  currentSlide: PropTypes.number,
  loop: PropTypes.bool,
  drag: PropTypes.bool,
  onSlideChange: PropTypes.func,
  type: PropTypes.oneOf(Object.values(TYPES)),
  visiblePerPage: PropTypes.number,
  paddingPerSlide: PropTypes.string,
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  arrowColors: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  dotsAreInAbsolute: PropTypes.bool,
  dotsColor: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  ringColor: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  isInFullView: PropTypes.bool,
  howManyDotsToSee: PropTypes.number,
};

export default Carousel;
