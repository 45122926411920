export const colorsNameKey = {
  ROSSO_CORSA_LIGHT: 'rosso_corsa_light',
  ROSSO_CORSA: 'rosso_corsa',
  ROSSO_CORSA_DARK: 'rosso_corsa_dark',
  ROSSO_CORSA_DARKER: 'rosso_corsa_darker',
  GIALLO_MODENA: 'giallo_modena',
  BLACK_100: 'black_100',
  BLACK_90: 'black_90',
  BLACK_70: 'black_70',
  BLACK_50: 'black_50',
  BLACK_40: 'black_40',
  BLACK_20: 'black_20',
  BLACK_15: 'black_15',
  BLACK_10: 'black_10',
  BLACK_05: 'black_05',
  WHITE: 'white',
  TRASPARENT: 'transparent',
};
