import React from 'react';
import PropTypes from 'prop-types';
import { BadgeContainer } from './index.style';
import theme from '../../ui/theme/colors';
import Typography from '../Typography';

const Badge = ({
  children, color, background, onClick, hasNotCursorPointer,
}) => (
  <BadgeContainer
    hasNotCursorPointer={hasNotCursorPointer}
    color={color}
    background={background}
    onClick={onClick}
  >
    <Typography
      tag="p"
      type={Typography.TYPES.CAPTION}
      style={Typography.STYLES.DEFAULT}
    >
      {children}
    </Typography>
  </BadgeContainer>
);

Badge.defaultProps = {
  color: null,
  background: null,
  onClick: () => null,
  hasNotCursorPointer: true,
};

Badge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  color: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  background: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  onClick: PropTypes.func,
  hasNotCursorPointer: PropTypes.bool,
};

export default Badge;
