import React from 'react'
import PropTypes from 'prop-types';
import { VehicleCardContainer, VehicleCardBody, VehicleCardHeader } from './index.style';
import Typography from '../../atoms/Typography';
import { colorsNameKey } from '../../const/theme.const';
import altImg from '../../logo-ferrari.png';

const VehicleCard = ({
  margin, imgSrc, imgAlt, carYear,
  carTitle, carVinLabel, plate, certificationLabel,
  certification, issueDateLabel, issueDate, onClick,
  to,
}) => (
  <VehicleCardContainer onClick={onClick} margin={margin} to={to}>
    <VehicleCardHeader>
      <img src={imgSrc || altImg} alt={imgAlt} />
      <div className="mask" />
      <div className="info">
        <Typography
          tag="span"
          type={Typography.TYPES.CARD_YEAR_VEHICLE}
          color={colorsNameKey.WHITE}
        >
          {carYear}
        </Typography>
        <Typography
          tag="span"
          type={Typography.TYPES.CARD_TITLE_VEHICLE}
          color={colorsNameKey.WHITE}
        >
          {carTitle}
        </Typography>
      </div>
    </VehicleCardHeader>
    <VehicleCardBody>
      <div className="plate">
        <Typography
          tag="span"
          type={Typography.TYPES.CARD_PLATE_VEHICLE_OVER}
          color={colorsNameKey.BLACK_40}
        >
          {carVinLabel}
        </Typography>
        <Typography
          tag="span"
          type={Typography.TYPES.CARD_PLATE_VEHICLE}
          color={colorsNameKey.BLACK_90}
        >
          {plate}
        </Typography>
      </div>
      <div className="info">
        <div className="certification">
          <Typography
            tag="p"
            type={Typography.TYPES.CARD_LABEL_VEHICLE}
            color={colorsNameKey.BLACK_50}
          >
            {certificationLabel}
          </Typography>
          <Typography
            tag="p"
            type={Typography.TYPES.CARD_INFO_VEHICLE}
            color={colorsNameKey.BLACK_90}
          >
            {certification}
          </Typography>
        </div>
        {(issueDate && issueDate !== null && issueDate !== undefined)
          ? (
            <div className="issue">
              <Typography
                tag="p"
                type={Typography.TYPES.CARD_LABEL_VEHICLE}
                color={colorsNameKey.BLACK_50}
              >
                {issueDateLabel}
              </Typography>
              <Typography
                tag="p"
                type={Typography.TYPES.CARD_INFO_VEHICLE}
                color={colorsNameKey.BLACK_90}
              >
                {issueDate}
              </Typography>
            </div>
          )
          : null}
      </div>
    </VehicleCardBody>
  </VehicleCardContainer>
);

VehicleCard.defaultProps = {
  margin: null,
  imgAlt: null,
  imgSrc: null,
  carYear: null,
  carTitle: null,
  carVinLabel: null,
  plate: null,
  certificationLabel: null,
  certification: null,
  issueDateLabel: null,
  issueDate: null,
  onClick: () => null,
  to: null,
};

VehicleCard.propTypes = {
  margin: PropTypes.string,
  imgAlt: PropTypes.string,
  imgSrc: PropTypes.string,
  carYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  carTitle: PropTypes.string,
  carVinLabel: PropTypes.string,
  plate: PropTypes.string,
  certificationLabel: PropTypes.string,
  certification: PropTypes.string,
  issueDateLabel: PropTypes.string,
  issueDate: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

export default VehicleCard;
