import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const VehicleCardHeader = styled.div`
    position: relative;
    width: 100%;
    /* height: 180px; */
    height:100%;

    img {
      height: 100%;
      width: 100%;
      /* max-height: 180px; */
      object-fit: cover;
    } 

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to top, #181818, rgba(24, 24, 24, 0) 62%);
    }

    .info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: 65%;
      left: 0;
      padding: 0 16px;

      span {
        text-align: left;
        max-width: 70%;

        @media ${(props) => props.theme.device.mediaqueries.large_screen_max} {
          max-width: 75%;
        }
      }
    }
`;

export const VehicleCardBody = styled.div`
    height: calc(100% - 170px);
    display: flex;
    padding: 5px 16px 10px 16px;

      .plate {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 65%;

        @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
          flex-basis: 55%;
        }

        @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
          flex-basis: 59%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 1 auto;

        &__issue {
          margin-top: 5px;
        }

        p {
          text-align: left;
        }
      }
`;

export const VehicleCardContainer = styled(Link)`
  /* max-width: 327px; */
  display: block;
  width: 100%;
  /* height: 238px; */
  text-decoration: none;
  border: solid 1px rgba(93, 93, 93, 0.08);
  background-color: #ffffff;
  padding: 0;
  margin: ${(props) => props.margin || 0};

  &:hover {
    cursor: pointer;
  }

  &:active, &:focus {
    outline: none;
  }
`;
