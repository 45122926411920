
import colors from './colors'
import device from './device'
import mixin from './mixin';
import icons from './icons';
import constants from './const';

export default {
  ...colors,
  ...device,
  ...mixin,
  ...icons,
  ...constants,
};
