import {
  GET_ALL_CERTIFICATION_LIGHT_HOUSE,
  GET_ALL_MODEL_CAR,
} from '../actions/homepage';

const initialState = {
  vehicleList: [],
  models: [],
};

export const homepage = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CERTIFICATION_LIGHT_HOUSE._SUCCESS:
      return { ...state, vehicleList: [...action.newState] };
    case GET_ALL_MODEL_CAR._SUCCESS:
      return { ...state, models: [...action.newState] };
    case GET_ALL_CERTIFICATION_LIGHT_HOUSE._ERROR:
    case GET_ALL_MODEL_CAR._ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
