import styled, { css } from 'styled-components';

export const SliderIndicatorContainer = styled.button`
  border: none;
  outline: none;
  background: none;
  height: 26px;
  width: 26px;
  border: 1px solid transparent;
  border-radius: 100%;
  position: relative;
  transition: 0.15s;
  ${({ timeout, ringColor, theme }) => timeout
    && css`
      border-color: ${ringColor
    ? theme.colors.primary[ringColor]
    : theme.colors.primary.black_05};
    `}
  cursor: pointer;
  > svg {
    position: absolute;
    top: calc(50% - 13px);
    left: calc(50% - 13px);
    > circle {
      &.outer-button {
        stroke-dashoffset: calc(565px - ${({ progress }) => progress / 100}px);
        transition: stroke-dashoffset 0s;
        transform: rotate(-90deg);
        transform-origin: center center;
        fill: transparent;
        stroke: ${({ timeoutProgressColor, theme }) => (timeoutProgressColor
    ? theme.colors.primary[timeoutProgressColor]
    : theme.colors.primary.rosso_corsa)};
        stroke-width: 1px;
      }
      &.inner-button {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 0s;
        transform: rotate(-90deg);
        transform-origin: center center;
        stroke: transparent;
        fill: ${({ color, theme }) => (color ? theme.colors.primary[color] : theme.colors.primary.black_90)};
        stroke-width: 1px;
      }
    }
  }
  &:focus,
  &:active {
    outline: none;
  }
  ${({ selected, theme, color }) => selected
    && css`
      border-color: ${color
    ? theme.colors.primary[color]
    : theme.colors.primary.black_05};

      > svg {
        position: absolute;
        > circle {
          &.outer-button {
            display: none;
          }
        }
      }
    `}

  &:hover {
    > svg {
      position: absolute;
      > circle {
        &.inner-button {
          fill: transparent;
          stroke: ${({ color, theme }) => (color
    ? theme.colors.primary[color]
    : theme.colors.primary.black_90)};
        }
      }
    }
  }
`;
