import React from 'react';
import PropTypes from 'prop-types';
import { CertificationBoxContainer } from './index.style';
import Typography from '../../atoms/Typography';
import { colorsNameKey } from '../../const/theme.const';
import Button from '../../atoms/Button';
import { windowSize } from '../../ui/theme/device';

const CertificationBox = ({ text, buttonText, onClick }) => {
  const { size } = windowSize();

  return (
    <CertificationBoxContainer>
      {size === 'S' ? (
        <Typography
          tag="p"
          type={Typography.TYPES.SUBTITLE}
          color={colorsNameKey.BLACK_90}
        >
          {text}
        </Typography>
      ) : (
        <Typography
          tag="p"
          type={Typography.TYPES.TITLE}
          color={colorsNameKey.BLACK_90}
        >
          {text}
        </Typography>
      )}

      <Button onClick={onClick} type={Button.TYPES.MAIN_RED}>
        {buttonText}
      </Button>
    </CertificationBoxContainer>
  );
};

CertificationBox.defaultProps = {
  text: null,
  buttonText: null,
  onClick: () => null,
};

CertificationBox.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

export default CertificationBox;
