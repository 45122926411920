import client, { apiEnv } from '../client';

export const requestAccessToken = (code) => {
  const objToSend = {
    grant_type: apiEnv[apiEnv.env].grant_type_request_access_token,
    client_id: apiEnv[apiEnv.env].client_id_name,
    client_secret: apiEnv[apiEnv.env].client_secret,
    code,
    scope: apiEnv[apiEnv.env].scope,
    redirect_uri: apiEnv[apiEnv.env].redirect_uri(
      window.location.pathname === '/car-detail'
        ? window.location.pathname
        : '/homepage',
    ),
  };
  return client.post(null, true)(
    apiEnv[apiEnv.env].urlAccessToken(),
    `${Object.keys(objToSend)
      .map((key) => `${key}=${objToSend[key]}`)
      .join('&')}`,
  );
};

export const convertAccessToken = (assertion) => {
  const objToSend = {
    grant_type: apiEnv[apiEnv.env].grant_type_convert_access_token,
    client_id: apiEnv[apiEnv.env].client_id_mcs,
    // client_secret: apiEnv[apiEnv.env].client_secret,
    assertion,
  };
  return client.post(null, true)(
    apiEnv[apiEnv.env].urlConvertToken(),
    `${Object.keys(objToSend)
      .map((key) => `${key}=${objToSend[key]}`)
      .join('&')}`,
  );
};

export const refreshAccessToken = (refreshToken) => {
  const objToSend = {
    grant_type: apiEnv[apiEnv.env].grant_type_refresh_access_token,
    client_id: apiEnv[apiEnv.env].client_id_name,
    client_secret: apiEnv[apiEnv.env].client_secret,
    refresh_token: refreshToken,
    scope: apiEnv[apiEnv.env].scope,
  };
  return client.post(null, true)(
    apiEnv[apiEnv.env].urlAccessToken(),
    `${Object.keys(objToSend)
      .map((key) => `${key}=${objToSend[key]}`)
      .join('&')}`,
  );
};

export const loginOnFerrari = (hasPrompt = true) => client.get()(apiEnv[apiEnv.env].loginUrl(), {
  scope: apiEnv[apiEnv.env].scope,
  response_type: apiEnv[apiEnv.env].response_type,
  redirect_uri: apiEnv[apiEnv.env].redirect_uri('/homepage'),
  client_id: apiEnv[apiEnv.env].client_id_name,
  ...(hasPrompt
    ? {}
    : {
      prompt: apiEnv[apiEnv.env].prompt,
    }),
});
