import styled from 'styled-components';

export const VehicleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: ${({ margin }) => margin || 0};

`;

export const ValueContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
`;
