import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ModalBodyManageYourCarContainer,
  ButtonContainer,
  TitleContainer,
  SubTitleContainer,
} from './index.style';
import Typography from '../../../../atoms/Typography';
import { colorsNameKey } from '../../../../const/theme.const';
import Button from '../../../../atoms/Button';

const ModalBodyManageYourCar = ({
  ToogleStatus,
  // eslint-disable-next-line react/prop-types
  title,
  // eslint-disable-next-line react/prop-types
  button,
  // eslint-disable-next-line react/prop-types
  description,
}) => {
  const { t } = useTranslation();
  return (
    <ModalBodyManageYourCarContainer>
      <div>
        <TitleContainer>
          <Typography
            tag="p"
            type={Typography.TYPES.MODAL_TITLE_LOGIN_REQUEST}
            color={colorsNameKey.BLACK_90}
          >
            {t(title)}
          </Typography>
        </TitleContainer>
        <SubTitleContainer>
          <Typography
            tag="p"
            type={Typography.TYPES.MODAL_SUBTITLE_LOGIN_REQUEST}
            color={colorsNameKey.BLACK_90}
          >
            {t(description)}
          </Typography>
        </SubTitleContainer>
      </div>
      <ButtonContainer>
        <Button type={Button.TYPES.MAIN_RED} onClick={ToogleStatus}>
          {t(button)}
        </Button>
      </ButtonContainer>
    </ModalBodyManageYourCarContainer>
  );
};

ModalBodyManageYourCar.defaultProps = {
  ToogleStatus: () => null,
};

ModalBodyManageYourCar.propTypes = {
  ToogleStatus: PropTypes.func,
};

export default ModalBodyManageYourCar;
