import watchApp from './app';
import homepage from './homepage';
import login from './login';
import carDetail from './car-detail';
import certification from './certification';

export default [
  ...watchApp,
  ...homepage,
  ...login,
  ...carDetail,
  ...certification,
];
