import { createAsyncActionType } from './types';

export const GET_ALL_CERTIFICATION_LIGHT_HOUSE = createAsyncActionType(
  'HOMEPAGE',
  'GET_ALL_CERTIFICATION_LIGHT_HOUSE',
);
export const GET_ALL_MODEL_CAR = createAsyncActionType(
  'HOMEPAGE',
  'GET_ALL_MODEL_CAR',
);
