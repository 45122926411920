import React from 'react';
import PropTypes from 'prop-types';
import {
  StepperContainer,
  TypographyContainer,
  OverDivider,
} from './index.style';
import Typography from '../../atoms/Typography';
import { TYPES } from './index.enum';

const Stepper = ({ onClick, steps, type }) => {
  const activeStep = steps?.map((step) => step.active)?.lastIndexOf(true);
  return (
    <>
      <OverDivider />
      <StepperContainer
        currentStepActive={activeStep + 1}
        howManySteps={steps?.length}
      >
        {(type === TYPES.SINGLE_LABEL ? [steps[activeStep]] : steps)?.map(
          (step, i, array) => step && (
          <TypographyContainer
            key={i}
            step={i + 1}
            active={activeStep === i || i < activeStep}
            howManySteps={array.length}
          >
            <Typography
              tag="p"
              type={Typography.TYPES.BODY}
              style={Typography.STYLES.ALT}
              onClick={() => {
                const toRun = step?.onClick
                  ? () => step?.onClick()
                  : () => onClick(step.id);
                toRun();
              }}
            >
              {step.label}
            </Typography>
          </TypographyContainer>
          )
        )}
      </StepperContainer>
    </>
  );
};

Stepper.TYPES = TYPES;

Stepper.defaultProps = {
  onClick: () => null,
  type: TYPES.DEFAULT,
};

Stepper.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(TYPES)),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      label: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      active: PropTypes.bool,
      disabled: PropTypes.disabled,
    }),
  ).isRequired,
};

export default Stepper;
