import { apiEnv } from './client';

export const createLoginUrl = (prompt = true) => {
  const queryParams = {
    scope: apiEnv[apiEnv.env].scope,
    response_type: apiEnv[apiEnv.env].response_type,
    redirect_uri: apiEnv[apiEnv.env].redirect_uri('/homepage'),
    client_id: apiEnv[apiEnv.env].client_id_name,
    state: apiEnv[apiEnv.env].state,
    nonce: apiEnv[apiEnv.env].nonce,
  };
  const url = `${apiEnv[apiEnv.env].loginUrl()}?${Object.keys({
    ...queryParams,
    ...(prompt ? {} : { prompt: apiEnv[apiEnv.env].prompt }),
  })
    .map(
      (key) => `${key}=${
        {
          ...queryParams,
          prompt: apiEnv[apiEnv.env].prompt,
        }[key]
      }`,
    )
    .join('&')}`;
  return url;
};

export const createMailToLink = (to, subject, body) => {
  const a = document.createElement('a');
  a.href = `mailto:${to}?Subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(body)}`;
  return a;
};
