import React from 'react'
import PropTypes from 'prop-types';
import { TextIcon } from './index.style';
import Icon from '../Icon';
import ICON_TYPE from '../Icon/index.enum'
import { THEME_TYPES } from './index.enum';
import Typography from '../Typography';
import theme from '../../ui/theme/colors';

const TextIconContainer = ({
  children, type, hasIconRight, icon, hoverBehaviour, size, color, secondColor, onClick,
}) => {
  const currentTheme = THEME_TYPES[type.themeName]
  return (
    <TextIcon onClick={onClick} hasIconRight={hasIconRight}>
      {hasIconRight
        ? (
          <Icon
            icon={icon}
            secondColor={secondColor}
            color={color}
            hoverBehaviour={hoverBehaviour}
            size={size}
          />
        )
        : (
          <Icon
            icon={icon}
            secondColor={secondColor}
            color={color}
            hoverBehaviour={hoverBehaviour}
            size={size}
          />
        )}
      <Typography
        tag="span"
        type={Typography.TYPES.CAPTION}
        color={currentTheme.textColor}
      >
        {children}
      </Typography>
    </TextIcon>
  )
}

TextIconContainer.TYPES = THEME_TYPES;
TextIconContainer.ICON_TYPE = ICON_TYPE;

TextIconContainer.defaultProps = {
  hasIconRight: false,
  hoverBehaviour: true,
  type: THEME_TYPES.WHITE,
  icon: ICON_TYPE.CHEVRON_RIGHT,
  size: 16,
  color: null,
  secondColor: null,
  onClick: () => null,
};

TextIconContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  hasIconRight: PropTypes.bool,
  hoverBehaviour: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(THEME_TYPES)),
  icon: PropTypes.oneOf(Object.values(ICON_TYPE)),
  size: PropTypes.number,
  color: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  secondColor: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  onClick: PropTypes.func,
};

export default TextIconContainer;
