import styled from 'styled-components';

export const CertificationBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 22px 24px 32px 24px;
  background-color: ${({ theme }) => (theme.colors.primary.black_10)};
  margin: 0 -24px;
  width: calc(100% + 48px);
  margin-bottom: 64px;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 39px 31.5px 46px 48px;
    margin: 0;
    width: 100%;
    margin-bottom: 64px;
  }

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    padding: 45px 47.5px 55px 64px;
  }

  > button {
    text-transform: uppercase;
    max-width: 260px;
    margin-top: 22px;
    padding: 0 29px;

    @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
      margin-top: 0;
    }
  }

  > p {
    max-width: 70%;

    @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
      max-width: 60%;
    }
  }

`;
