/* eslint-disable camelcase */
import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import {
  GET_ALL_CERTIFICATION_LIGHT_HOUSE,
  GET_ALL_MODEL_CAR,
} from '../actions/homepage';
import {
  getAllCertificationLightHouse,
  getAllModelCar,
} from '../../utils/apis/homepage';
import { SPINNER_OPEN, SPINNER_CLOSE } from '../actions/types';

function* getAllCertificationLightHouseFn({ payload, reset }) {
  try {
    yield put({
      type: SPINNER_OPEN,
    });
    const body = {
      car_nationality: '',
      external_color: '',
      internal_color: '',
      model: '',
      page: 0,
      status: '',
      vehicleType: '',
      vin: '',
      yearProduction: null,
    };
    if (payload) {
      Object.keys(payload).forEach((key) => {
        body[key] = payload[key] || body[key];
      });
    }
    const { access_token: token } = yield select(({ login }) => (login.logged
      ? { access_token: login.access_token }
      : { access_token: null }));
    const {
      data: { outputList },
    } = yield call(
      getAllCertificationLightHouse,
      { ...body },
      token,
    );
    const { vehicleList } = yield select(({ homepage }) => homepage);
    yield put({
      type: GET_ALL_CERTIFICATION_LIGHT_HOUSE._SUCCESS,
      newState: [...(!reset ? vehicleList : []), ...outputList],
    });
  } catch (e) {
    yield put({ type: GET_ALL_CERTIFICATION_LIGHT_HOUSE._ERROR, error: e });
  } finally {
    yield put({ type: SPINNER_CLOSE });
  }
}

function* watchGetAllCertificationLightHouse() {
  yield takeLatest(
    GET_ALL_CERTIFICATION_LIGHT_HOUSE._REQUEST,
    getAllCertificationLightHouseFn,
  );
}

function* getAllModelCarFn() {
  try {
    yield put({
      type: SPINNER_OPEN,
    });
    const { access_token: token } = yield select(({ login }) => (login.logged
      ? { access_token: login.access_token }
      : { access_token: null }));
    const {
      data: { outputList },
    } = yield call(getAllModelCar, token);
    yield put({ type: GET_ALL_MODEL_CAR._SUCCESS, newState: [...outputList] });
  } catch (e) {
    yield put({ type: GET_ALL_MODEL_CAR._ERROR, error: e });
  } finally {
    yield put({ type: SPINNER_CLOSE });
  }
}

function* watchGetAllModelCar() {
  yield takeLatest(GET_ALL_MODEL_CAR._REQUEST, getAllModelCarFn);
}

export default [watchGetAllCertificationLightHouse, watchGetAllModelCar];
