import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../atoms/Icon';

import {
  ModalContainer,
  ModalBody,
  ModalClose,
  ModalBodyContainer,
  ModalOverlay,
} from './index.style';
import { colorsNameKey } from '../../const/theme.const';

const Modal = ({
  active, close, onClose, onOpen, children, fullScreen, padding,
}) => {
  useEffect(() => {
    if (active) {
      onOpen();
    } else {
      onClose();
    }
  }, [active, onOpen, onClose]);

  return (
    <ModalContainer active={active} fullScreen={fullScreen}>
      <ModalOverlay active={active} onClick={close} />
      <ModalClose desktop>
        <button onClick={close} type="button">
          <Icon
            size={20}
            color={colorsNameKey.BLACK_90}
            icon={Icon.ICONS_TYPE.REMOVE}
          />
        </button>
      </ModalClose>
      <ModalBodyContainer active={active} padding={padding}>
        <ModalClose mobile>
          <button onClick={close} type="button">
            <Icon
              size={20}
              color={colorsNameKey.WHITE}
              icon={Icon.ICONS_TYPE.REMOVE}
            />
          </button>
        </ModalClose>
        <ModalBody>{children}</ModalBody>
      </ModalBodyContainer>
    </ModalContainer>
  );
};

Modal.defaultProps = {
  active: false,
  onClose: () => null,
  onOpen: () => null,
  fullScreen: false,
  padding: null,
};

Modal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  fullScreen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  padding: PropTypes.string,
};

export default Modal;
