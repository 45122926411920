import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SliderIndicatorContainer } from './index.style';
import theme from '../../ui/theme/colors';

const SliderIndicator = ({
  color,
  ringColor,
  selected,
  timeout,
  timeoutProgressColor,
  onClick,
  onFinished,
}) => {
  const [progress, setProgress] = useState(0);
  const [remainingTimer, setRemainingTimer] = useState(timeout * 1000);
  const progressRadius = 12;
  const interval = useRef(null);
  const timeoutRef = useRef(null);
  const intervalValue = 50;

  useEffect(() => {
    clearInterval(interval.current);
    setProgress(0);
    setRemainingTimer(0);
    clearTimeout(timeoutRef.current)
    if (timeout) {
      setProgress(0);
      setRemainingTimer(timeout * 1000);
      interval.current = setInterval(() => {
        setRemainingTimer((state) => {
          const newState = state - intervalValue;
          if (newState < intervalValue) {
            clearInterval(interval.current);
          }
          return newState;
        });
      }, intervalValue);
    }
  }, [timeout]);

  useEffect(() => {
    const currentTimeout = timeout * 1000;
    if (remainingTimer === currentTimeout) {
      setProgress(0)
    } else {
      setProgress(() => 100 - (remainingTimer / currentTimeout) * 100);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTimer]);

  useEffect(() => {
    if (progress >= 100) {
      timeoutRef.current = setTimeout(() => {
        onFinished()
      }, intervalValue);
    }
  }, [progress, onFinished]);

  return (
    <SliderIndicatorContainer
      color={color}
      ringColor={ringColor}
      onClick={onClick}
      timeout={timeout}
      timeoutProgressColor={timeoutProgressColor}
      selected={selected}
      progress={progress * Math.PI * (progressRadius * 2)}
    >
      <svg width="26" height="26" viewbox="0 0 1 1" preserveAspectRatio="xMinYMin meet">
        {timeout && (
          <circle
            className="outer-button"
            r={progressRadius}
            cx="13"
            cy="13"
            fill="transparent"
            strokeDasharray="565.48"
            strokeDashoffset="0"
          />
        )}
        <circle
          className="inner-button"
          r="2"
          cx="13"
          cy="13"
          fill="transparent"
          strokeDasharray="565.48"
          strokeDashoffset="0"
        />
      </svg>
    </SliderIndicatorContainer>
  );
};

SliderIndicator.defaultProps = {
  color: 'black_90',
  ringColor: 'black_05',
  timeoutProgressColor: 'rosso_corsa',
  timeout: null,
  onClick: () => null,
  onFinished: () => null,
  selected: false,
};

SliderIndicator.propTypes = {
  color: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  ringColor: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  timeoutProgressColor: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  timeout: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  onClick: PropTypes.func,
  onFinished: PropTypes.func,
  selected: PropTypes.bool,
};

export default SliderIndicator;
