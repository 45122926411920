import styled, { css } from 'styled-components';

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  > .slider-indicator {
    margin-left: 6px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const SlideIndicatorContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    flex-grow: 1;
    ${({ opacitizer }) => opacitizer && css`
      opacity: 0.4;
    `} 
  }
`;

export const ArrowContainer = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  > p,
  > i {
    cursor: pointer;
  }
  &:focus,
  &:active {
    outline: none;
  }
  ${({ position }) => (position === 'left'
    ? css`
          margin-right: auto;
          margin-left: 48px;
          > * {
            &:first-child {
              margin-right: 17.5px;
            }
          }
        `
    : css`
          margin-left: auto;
          margin-right: 48px;
          > * {
            &:last-child {
              margin-left: 17.5px;
            }
          }
        `)};
`;
