/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { TRANSLATION_KEYS } from '../../i18n/index.enum';
import '../../App.scss';
import Container from '../../atoms/Grid/Container';
import Icon from '../../atoms/Icon';
import MouseContainerWrapper from '../../components/MouseContainerWrapper';
import Carousel from '../../components/Carousel';
import PhotoCardElement from '../../components/Carousel/Elements/PhotoCardElement';
import PhotoCardElementFullView from '../../components/Carousel/Elements/PhotoCardElementFullView';
import { colorsNameKey } from '../../const/theme.const';
import {
  CarDetailContainer,
  CarCarouselContainer,
  // VehicleInfoLabelContainer,
  VehicleInfoContainer,
  DetailVehicleContainer,
} from './index.style';
// import VehicleInfoLabel from '../../atoms/VehicleInfoLabel';
import VehicleInfo from '../../components/VehicleInfo';
// import stub from './stub.json';
import Accordion from '../../components/Accordion';
import CertificationBox from '../../components/CertificationBox';
import HeroImagePreview from '../../components/HeroImagePreview';
import ShareHeader from '../../components/ShareHeader';
import Modal from '../../components/Modal';
import ModalCarousel from '../../components/ModalCarousel';
import ModalBodyLoginRequest from '../../components/Modal/modalBody/ModalBodyLoginRequest';
import ModalBodyManageYourCar from '../../components/Modal/modalBody/ModalBodyManageYourCar';
import ModalBodyStatusConfirmed from '../../components/Modal/modalBody/ModalBodyStatusConfirmed';
import ModalBodyRequestOwnership from '../../components/Modal/modalBody/ModalBodyRequestOwnership';
// import ModalBodyRequestInformation from '../../components/Modal/modalBody/ModalBodyRequestInformation';
import { ROUTES_URL } from '../../components/RouterContainer/index.routes';
import {
  GET_VEHICLE_CARD_INFORMATION,
  NEGOTIABILITY,
} from '../../redux/actions/car-detail';
import { windowSize } from '../../ui/theme/device';
import { createLoginUrl, createMailToLink } from '../../utils/login';
import { apiEnv } from '../../utils/client';
import { getQueryVariable } from '../../utils/query';

const CarDetail = ({
  pushUrl,
  currentVehicle,
  getVehicleCardInformation,
  negotiability,
  logged,
}) => {
  const { t } = useTranslation();
  const { size } = windowSize();
  const id = getQueryVariable('vin') || getQueryVariable('state');

  const [manageCarRequest, setManageCarRequest] = useState(false);
  const [infoCarRequest, setInfoCarRequest] = useState(false);
  const [ownerShipRequest, setOwnerShipRequest] = useState(false);

  const [modalShow, setShowModal] = useState(false);
  const [modalSecondShow, setShowSecondModal] = useState(false);

  const [fullViewCarousel, setfullViewCarousel] = useState(false);
  const [indexFullViewCarousel, setIndexFullViewCarousel] = useState(0);

  const manageShowModal = () => {
    setShowModal(true);
  };

  const manageShowModalInfo = () => {
    setShowSecondModal(true);
  };

  const DEFAULT_OFFSET = {
    right: 5,
    left: 5,
    top: 10,
    bottom: 10,
  };

  useEffect(() => {
    getVehicleCardInformation({ vin: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVehicleCardInformation, logged]);

  useEffect(() => {
    setManageCarRequest(currentVehicle.negotiable);
  }, [currentVehicle]);

  return (
    <>
      <ShareHeader
        isUserLogged={logged}
        isOwner={currentVehicle.owner}
        isNegotiable={currentVehicle.negotiable}
        linksShare={currentVehicle.linksShare}
        dataShareURL={window.location}
      />

      <CarDetailContainer>
        <HeroImagePreview
          imgCar={currentVehicle.urlPhoto}
          isUserLogged={logged}
          productionYear={currentVehicle.productionYear}
          model={currentVehicle.model}
          vin={currentVehicle.vin}
          isNegotiable={currentVehicle.negotiable}
          UserOwner={currentVehicle.owner}
          negotiableLabelButton={t(
            TRANSLATION_KEYS.CAR_DETAIL.STATUS.NEGOTIABLE,
          ).toString()}
          notNegozianbleLabelButton={t(
            TRANSLATION_KEYS.CAR_DETAIL.STATUS.NOT_NEGOTIABLE,
          ).toString()}
          manageYourCarLabelButton={t(
            TRANSLATION_KEYS.CAR_DETAIL.MANAGE_YOUR_CAR,
          ).toString()}
          requestInfoLabelButton={t(
            TRANSLATION_KEYS.CAR_DETAIL.REQUEST_INFO,
          ).toString()}
          requestOwnershipLabelButton={t(
            TRANSLATION_KEYS.CAR_DETAIL.REQUEST_OWNERSHIP_TEXT,
          ).toString()}
          manageShowModal={manageShowModal}
          manageShowModalInfo={manageShowModalInfo}
          requestOwnehipSModalOpen={manageShowModal}
        />
        <CarCarouselContainer>
          <MouseContainerWrapper
            disable={size === 'S'}
            type={MouseContainerWrapper.TYPES.SECTORIZED}
            iconOnLeft={Icon.ICONS_TYPE.CHEVRON_LEFT}
            iconOnRight={Icon.ICONS_TYPE.CHEVRON_RIGHT}
            atWhichSection={size === 'XL' ? 0.5 : 0.7}
            fullWidth
            offset={DEFAULT_OFFSET}
            backgroundIcon={colorsNameKey.WHITE}
          >
            {currentVehicle.gallery && currentVehicle.gallery.length > 0 && (
              <Carousel
                dots={size === 'S'}
                drag={size === 'S'}
                clickToGoNext
                dotsColor={colorsNameKey.BLACK_90}
                currentSlide={0}
                sectionVisible={size === 'XL' ? 1 / 3 : (size === 'L' || size === 'M') ? 1 / 2.5 : 0.7}
              >
                {(currentVehicle.gallery || []).map((elem, i) => (
                  <PhotoCardElement
                    key={i}
                    image={elem}
                    icon={Icon.ICONS_TYPE.SCREEN}
                    onIconClick={() => {
                      setIndexFullViewCarousel(i);
                      setfullViewCarousel(true);
                    }}
                  />
                ))}
              </Carousel>
            )}
          </MouseContainerWrapper>
        </CarCarouselContainer>
      </CarDetailContainer>

      <Container>
        {/* <VehicleInfoLabelContainer>
          <VehicleInfoLabel number={3} text="">
            {t(TRANSLATION_KEYS.CAR_DETAIL.INFO_VEHICLE)}
          </VehicleInfoLabel>
        </VehicleInfoLabelContainer> */}

        <VehicleInfoContainer>
          {(currentVehicle.top_info || [])
            .map((element, index) => ({
              ...element,
              unityType:
                index === 0
                  ? null
                  : t(
                    TRANSLATION_KEYS.CAR_DETAIL.MEASURE_UNITS[
                      element.label.toUpperCase()
                    ],
                  ),
            }))
            .map((element, index) => (
              <VehicleInfo
                key={index}
                margin="48px 0px 0px 0px"
                value={
                  element.value !== null && element.value !== 'null'
                    ? element.value
                    : '-'
                }
                unityType={
                  element.value !== null && element.value !== 'null'
                    ? element.unityType
                    : ''
                }
                label={element.label}
              />
            ))}
        </VehicleInfoContainer>

        <DetailVehicleContainer>
          {(currentVehicle.accordion || []).map((element, index) => (
            <Accordion
              key={index}
              text={
                t(
                  TRANSLATION_KEYS.CAR_DETAIL.ACCORDION.TITLE[element.label_acc],
                )
                  ? t(
                    TRANSLATION_KEYS.CAR_DETAIL.ACCORDION.TITLE[
                      element.label_acc
                    ],
                  )
                  : element.label_acc
              }
              listInfoBody={element.info.map((el) => ({
                ...el,
                value:
                  el.label
                  && el.label.indexOf('date') > -1
                  && el.value
                  && el.value.toString().length === 13
                  && el.value.toString().match(/^\d+$/)
                    ? dayjs(Number(el.value)).format(
                      t(TRANSLATION_KEYS.COMMON.DATE_FORMAT),
                    )
                    : el.value,
              }))}
            />
          ))}
        </DetailVehicleContainer>
        {currentVehicle.owner && (
          <CertificationBox
            text={t(TRANSLATION_KEYS.CAR_DETAIL.TEXT_CERTIFICATION_BOX)}
            buttonText={t(TRANSLATION_KEYS.CAR_DETAIL.GO_TO_CERTIFICATION)}
            onClick={() => {
              pushUrl(ROUTES_URL.certification_progress(id));
            }}
          />
        )}
      </Container>
      {/* no logged  */}
      {!logged ? (
        <>
          <Modal
            active={modalShow}
            fullScreen
            padding="0 25px"
            close={() => setShowModal(false)}
          >
            <ModalBodyLoginRequest
              onLoginClick={() => window.location.replace(createLoginUrl())}
              onRegisterClick={() => window.location.replace(
                t(TRANSLATION_KEYS.COMMON.LINK_REGISTER),
              )}
            />
          </Modal>
        </>
      ) : (
        <>
          {currentVehicle.owner && logged ? (
            <Modal
              active={modalShow}
              fullScreen
              padding="0 25px"
              close={() => setShowModal(false)}
            >
              <ModalBodyManageYourCar
                title={t(
                  !manageCarRequest
                    ? TRANSLATION_KEYS.CAR_DETAIL.MODAL.MANAGE_YOUR_CAR_TITLE
                    : TRANSLATION_KEYS.CAR_DETAIL.MODAL
                      .MANAGE_YOUR_CAR_TITLE_REMOVE,
                )}
                description={t(
                  !manageCarRequest
                    ? TRANSLATION_KEYS.CAR_DETAIL.MODAL.MANAGE_YOUR_CAR_SUBTITLE
                    : TRANSLATION_KEYS.CAR_DETAIL.MODAL
                      .MANAGE_YOUR_CAR_SUBTITLE_REMOVE,
                )}
                button={t(
                  !manageCarRequest
                    ? TRANSLATION_KEYS.CAR_DETAIL.MODAL.BUTTON_ACTIVATE
                    : TRANSLATION_KEYS.CAR_DETAIL.MODAL.BUTTON_ACTIVATE_REMOVE,
                )}
                ToogleStatus={() => {
                  setManageCarRequest((state) => {
                    negotiability({
                      negotiable: !state,
                      vin: id,
                    });
                    getVehicleCardInformation({ vin: id });
                    return !state;
                  });
                  setShowModal(false);
                }}
              />
            </Modal>
          ) : (
            <>
              {/* ownership */}
              <Modal
                active={modalShow}
                fullScreen
                padding="0 25px"
                close={() => setShowModal(false)}
              >
                {ownerShipRequest ? (
                  <ModalBodyStatusConfirmed
                    titleStatus={t(
                      TRANSLATION_KEYS.CAR_DETAIL.MODAL
                        .REQUEST_INFORMATION_TITLE,
                    )}
                    subTitleStatus={t(
                      TRANSLATION_KEYS.CAR_DETAIL.MODAL
                        .SUBTITLE_CONFIRMED_INFORMATION_REQUEST_INFO,
                    )}
                  />
                ) : (
                  <ModalBodyRequestOwnership
                    title={t(
                      TRANSLATION_KEYS.CAR_DETAIL.MODAL.REQUEST_OWNERSHIP_TITLE,
                    )}
                    description={t(
                      TRANSLATION_KEYS.CAR_DETAIL.MODAL
                        .REQUEST_OWNERSHIP_SUBTITLE,
                    )}
                    buttonText={t(
                      TRANSLATION_KEYS.CAR_DETAIL.MODAL.BUTTON_REQUEST,
                    )}
                    ToogleStatus={() => {
                      const a = createMailToLink(
                        apiEnv[apiEnv.env].mailtoOwnership,
                        `request ownership for chassis ${currentVehicle.vin}`,
                      );
                      window.open(a.href);
                      setOwnerShipRequest(true);
                    }}
                  />
                )}
              </Modal>
              {/* info */}
              <Modal
                active={modalSecondShow}
                fullScreen
                padding="0 25px"
                close={() => setShowSecondModal(false)}
              >
                {infoCarRequest ? (
                  <ModalBodyStatusConfirmed
                    titleStatus={t(
                      TRANSLATION_KEYS.CAR_DETAIL.MODAL
                        .REQUEST_INFORMATION_TITLE,
                    )}
                    subTitleStatus={t(
                      TRANSLATION_KEYS.CAR_DETAIL.MODAL
                        .SUBTITLE_CONFIRMED_INFORMATION_REQUEST_INFO,
                    )}
                  />
                ) : (
                  <ModalBodyRequestOwnership
                    title={t(
                      TRANSLATION_KEYS.CAR_DETAIL.MODAL.REQUEST_INFO_TITLE,
                    )}
                    description={t(
                      TRANSLATION_KEYS.CAR_DETAIL.MODAL.REQUEST_INFO_SUBTITLE,
                    )}
                    buttonText={t(
                      TRANSLATION_KEYS.CAR_DETAIL.MODAL.BUTTON_SEND,
                    )}
                    ToogleStatus={() => {
                      const a = createMailToLink(
                        apiEnv[apiEnv.env].mailto,
                        `request info for chassis ${currentVehicle.vin}`,
                      );
                      window.open(a.href);
                      setInfoCarRequest(true);
                    }}
                  />
                )}
              </Modal>
            </>
          )}
        </>
      )}

      <ModalCarousel
        active={fullViewCarousel}
        fullScreen
        padding="0 25px"
        close={() => {
          setfullViewCarousel(false);
        }}
        shareHeader
      >
        <MouseContainerWrapper
          type={MouseContainerWrapper.TYPES.FULL}
          icon={Icon.ICONS_TYPE.SIDE_CHEVRON}
          iconColor={colorsNameKey.BLACK_90}
          fullWidth
          fromParent
          offset={{
            right: 10,
            left: 10,
            top: 10,
            bottom: 10,
          }}
          backgroundIcon={colorsNameKey.WHITE}
          disable={size === 'S'}
        >
          {currentVehicle.gallery && currentVehicle.gallery.length > 0 && (
            <Carousel
              isInFullView
              dots
              drag
              dotsColor={colorsNameKey.WHITE}
              ringColor={colorsNameKey.WHITE}
              arrows={size === 'XL'}
              arrowColors={colorsNameKey.WHITE}
              dotsAreInAbsolute
              clickToGoNext
              currentSlide={indexFullViewCarousel}
              sectionVisible={1}
            >
              {(currentVehicle.gallery || []).map((elem, i) => (
                <PhotoCardElementFullView
                  key={i}
                  image={elem}
                  alt=""
                  hasFullView
                />
              ))}
            </Carousel>
          )}
        </MouseContainerWrapper>
      </ModalCarousel>
    </>
  );
};

export default connect(
  // eslint-disable-next-line no-unused-vars
  ({ carDetail: { currentVehicle }, login: { logged } }) => ({
    currentVehicle,
    logged,
  }),
  (dispatch) => ({
    pushUrl: (url) => dispatch(push(url)),
    getVehicleCardInformation: (payload) => dispatch({
      type: GET_VEHICLE_CARD_INFORMATION._REQUEST,
      payload,
    }),
    negotiability: (payload) => dispatch({ type: NEGOTIABILITY._REQUEST, payload }),
  }),
)(CarDetail);
