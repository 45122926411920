export const TRANSLATION_KEYS = {
  COMMON: {
    DATE_FORMAT: 'COMMON.DATE_FORMAT',
    LINK_REGISTER: 'COMMON.LINK_REGISTER',
  },
  HOMEPAGE: {
    TITLE: 'HOMEPAGE.TITLE',
    SUBTITLE: 'HOMEPAGE.SUBTITLE',
    INPUT_SEARCH_FILTER: 'HOMEPAGE.INPUT_SEARCH_FILTER',
    BUTTON_MORE_FILTERS: 'HOMEPAGE.BUTTON_MORE_FILTERS',
    BUTTON_VIEW_MORE: 'HOMEPAGE.BUTTON_VIEW_MORE',
    BUTTON_SEARCH: 'HOMEPAGE.BUTTON_SEARCH',
    BUTTON_APPLY_FILTERS: 'HOMEPAGE.BUTTON_APPLY_FILTERS',
    BUTTON_RESET_FILTERS: 'HOMEPAGE.BUTTON_RESET_FILTERS',
    anno_prod: 'HOMEPAGE.anno_prod',
    NO_RESULTS: 'HOMEPAGE.NO_RESULTS',
    MODAL: {
      TITLE: 'HOMEPAGE.MODAL.TITLE',
      SUBTITLE: 'HOMEPAGE.MODAL.SUBTITLE',
      APPLY_FILTERS: 'HOMEPAGE.MODAL.BUTTON_APPLY_FILTERS',
      RESET_FILTERS: 'HOMEPAGE.MODAL.BUTTON_RESET_FILTERS',
    },
    FILTERS: {
      FILTER_MODELLO: {
        LABEL_SELECTED: 'HOMEPAGE.FILTERS.FILTER_MODELLO.LABEL_SELECTED',
      },
      FILTER_COLORE_INTERNI: {
        LABEL_SELECTED: 'HOMEPAGE.FILTERS.FILTER_COLORE_INTERNI.LABEL_SELECTED',
      },
      FILTER_COLORE_ESTERNI: {
        LABEL_SELECTED: 'HOMEPAGE.FILTERS.FILTER_COLORE_ESTERNI.LABEL_SELECTED',
      },
      FILTER_TIPO_VETTURE: {
        LABEL_SELECTED: 'HOMEPAGE.FILTERS.FILTER_TIPO_VETTURE.LABEL_SELECTED',
        GRAN_TURISMO: 'HOMEPAGE.FILTERS.FILTER_TIPO_VETTURE.GRAN_TURISMO',
        SPORT_PROTOTYPE: 'HOMEPAGE.FILTERS.FILTER_TIPO_VETTURE.SPORT_PROTOTYPE',
        F1: 'HOMEPAGE.FILTERS.FILTER_TIPO_VETTURE.F1',
        DINO: 'HOMEPAGE.FILTERS.FILTER_TIPO_VETTURE.DINO',
      },
      FILTER_STATO_CERTIFICAZIONE: {
        LABEL_SELECTED:
          'HOMEPAGE.FILTERS.FILTER_STATO_CERTIFICAZIONE.LABEL_SELECTED',
        NOT_CERTIFIED:
          'HOMEPAGE.FILTERS.FILTER_STATO_CERTIFICAZIONE.NOT_CERTIFIED',
        CURRENT: 'HOMEPAGE.FILTERS.FILTER_STATO_CERTIFICAZIONE.CURRENT',
        NOT_CURRENT: 'HOMEPAGE.FILTERS.FILTER_STATO_CERTIFICAZIONE.NOT_CURRENT',
      },
      FILTER_NAZIONALITA: {
        LABEL_SELECTED: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LABEL_SELECTED',
        AFGHANISTAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.AFGHANISTAN',
        ALBANIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ALBANIA',
        ALGERIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ALGERIA',
        ANDORRA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ANDORRA',
        ANGOLA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ANGOLA',
        ANTIGUA_AND_BARBUDA:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ANTIGUA_AND_BARBUDA',
        SAUDI_ARABIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAUDI_ARABIA',
        ARGENTINA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ARGENTINA',
        ARMENIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ARMENIA',
        AUSTRALIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.AUSTRALIA',
        AUSTRIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.AUSTRIA',
        AZERBAIJAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.AZERBAIJAN',
        BAHAMAS: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BAHAMAS',
        BAHRAIN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BAHRAIN',
        BANGLADESH: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BANGLADESH',
        BARBADOS: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BARBADOS',
        BELGIUM: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BELGIUM',
        BELIZE: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BELIZE',
        BENIN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BENIN',
        BHUTAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BHUTAN',
        BELARUS: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BELARUS',
        BURMA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BURMA',
        BOLIVIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BOLIVIA',
        BOSNIA_AND_HERZEGOVINA:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BOSNIA_AND_HERZEGOVINA',
        BOTSWANA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BOTSWANA',
        BRAZIL: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BRAZIL',
        BRUNEI: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BRUNEI',
        BULGARIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BULGARIA',
        BURKINA_FASO: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BURKINA_FASO',
        BURUNDI: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.BURUNDI',
        CAMBODIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CAMBODIA',
        CAMEROON: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CAMEROON',
        CANADA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CANADA',
        CAPE_VERDE: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CAPE_VERDE',
        CHAD: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CHAD',
        CHILE: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CHILE',
        CHINA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CHINA',
        CYPRUS: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CYPRUS',
        COLOMBIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.COLOMBIA',
        COMOROS: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.COMOROS',
        NORTH_KOREA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NORTH_KOREA',
        SOUTH_KOREA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SOUTH_KOREA',
        IVORY_COAST: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.IVORY_COAST',
        COSTA_RICA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.COSTA_RICA',
        CROATIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CROATIA',
        CUBA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CUBA',
        DENMARK: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.DENMARK',
        DOMINICA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.DOMINICA',
        ECUADOR: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ECUADOR',
        EGYPT: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.EGYPT',
        EL_SALVADOR: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.EL_SALVADOR',
        UNITED_ARAB_EMIRATES:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UNITED_ARAB_EMIRATES',
        ERITREA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ERITREA',
        ESTONIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ESTONIA',
        ETHIOPIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ETHIOPIA',
        FIJI: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.FIJI',
        PHILIPPINES: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PHILIPPINES',
        FINLAND: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.FINLAND',
        FRANCE: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.FRANCE',
        GABON: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GABON',
        GAMBIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GAMBIA',
        GEORGIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GEORGIA',
        GERMANY: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GERMANY',
        GHANA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GHANA',
        JAMAICA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.JAMAICA',
        JAPAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.JAPAN',
        DJIBOUTI: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.DJIBOUTI',
        JORDAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.JORDAN',
        GREECE: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GREECE',
        GRENADA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GRENADA',
        GUATEMALA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GUATEMALA',
        GUINEA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GUINEA',
        EQUATORIAL_GUINEA:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.EQUATORIAL_GUINEA',
        GUINEA_BISSAU: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GUINEA_BISSAU',
        GUYANA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.GUYANA',
        HAITI: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.HAITI',
        HONDURAS: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.HONDURAS',
        INDIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.INDIA',
        INDONESIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.INDONESIA',
        IRAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.IRAN',
        IRAQ: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.IRAQ',
        IRELAND: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.IRELAND',
        ICELAND: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ICELAND',
        MARSHALL_ISLANDS:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MARSHALL_ISLANDS',
        SOLOMON_ISLANDS: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SOLOMON_ISLANDS',
        ISRAEL: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ISRAEL',
        ITALY: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ITALY',
        KAZAKHSTAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.KAZAKHSTAN',
        KENYA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.KENYA',
        KYRGYZSTAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.KYRGYZSTAN',
        KIRIBATI: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.KIRIBATI',
        KUWAIT: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.KUWAIT',
        LAOS: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LAOS',
        LESOTHO: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LESOTHO',
        LATVIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LATVIA',
        LEBANON: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LEBANON',
        LIBERIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LIBERIA',
        LIBYA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LIBYA',
        LIECHTENSTEIN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LIECHTENSTEIN',
        LITHUANIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LITHUANIA',
        LUXEMBOURG: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.LUXEMBOURG',
        NORTH_MACEDONIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NORTH_MACEDONIA',
        MADAGASCAR: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MADAGASCAR',
        MALAWI: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MALAWI',
        MALAYSIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MALAYSIA',
        MALDIVES: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MALDIVES',
        MALI: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MALI',
        MALTA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MALTA',
        MOROCCO: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MOROCCO',
        MAURITANIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MAURITANIA',
        MAURITIUS: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MAURITIUS',
        MEXICO: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MEXICO',
        MOLDOVA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MOLDOVA',
        MONACO: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MONACO',
        MONGOLIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MONGOLIA',
        MONTENEGRO: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MONTENEGRO',
        MOZAMBIQUE: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.MOZAMBIQUE',
        NAMIBIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NAMIBIA',
        NAURU: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NAURU',
        NEPAL: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NEPAL',
        NICARAGUA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NICARAGUA',
        NIGER: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NIGER',
        NIGERIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NIGERIA',
        NORWAY: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NORWAY',
        NEW_ZEALAND: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NEW_ZEALAND',
        OMAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.OMAN',
        NETHERLANDS: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.NETHERLANDS',
        PAKISTAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PAKISTAN',
        PALAU: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PALAU',
        PANAMA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PANAMA',
        PAPUA_NEW_GUINEA:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PAPUA_NEW_GUINEA',
        PARAGUAY: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PARAGUAY',
        PERU: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PERU',
        POLAND: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.POLAND',
        PORTUGAL: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.PORTUGAL',
        QATAR: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.QATAR',
        CONGO_DR: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CONGO_DR',
        UNITED_KINGDOM: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UNITED_KINGDOM',
        CZECH_REPUBLIC: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CZECH_REPUBLIC',
        CENTRAL_AFRICAN_REP:
        'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CENTRAL_AFRICAN_REP',
        CONGO_REP: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.CONGO_REP',
        DOMINICAN_REP: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.DOMINICAN_REP',
        ROMANIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ROMANIA',
        RWANDA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.RWANDA',
        RUSSIAN_FEDERATION:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.RUSSIAN_FEDERATION',
        SAINT_KITTS_AND_NEVIS:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAINT_KITTS_AND_NEVIS',
        SAINT_LUCIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAINT_LUCIA',
        SAINT_VINCENT_AND_THE_GRENADINES:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAINT_VINCENT_AND_THE_GRENADINES',
        SAMOA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAMOA',
        SAN_MARINO: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SAN_MARINO',
        SÃO_TOMÉ_AND_PRÍNCIPE:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SÃO_TOMÉ_AND_PRÍNCIPE',
        SENEGAL: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SENEGAL',
        SERBIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SERBIA',
        SEYCHELLES: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SEYCHELLES',
        SIERRA_LEONE: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SIERRA_LEONE',
        SINGAPORE: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SINGAPORE',
        SYRIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SYRIA',
        SLOVAKIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SLOVAKIA',
        SLOVENIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SLOVENIA',
        SOMALIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SOMALIA',
        SPAIN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SPAIN',
        SRI_LANKA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SRI_LANKA',
        FEDERATED_STATES_OF_MICRONESIA:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.FEDERATED_STATES_OF_MICRONESIA',
        UNITED_STATES: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UNITED_STATES',
        SOUTH_AFRICA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SOUTH_AFRICA',
        SUDAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SUDAN',
        SOUTH_SUDAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SOUTH_SUDAN',
        SURINAME: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SURINAME',
        SWEDEN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SWEDEN',
        SWITZERLAND: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SWITZERLAND',
        SWAZILAND: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.SWAZILAND',
        TAJIKISTAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TAJIKISTAN',
        TANZANIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TANZANIA',
        THAILAND: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.THAILAND',
        EAST_TIMOR: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.EAST_TIMOR',
        TOGO: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TOGO',
        TONGA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TONGA',
        TRINIDAD_AND_TOBAGO:
          'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TRINIDAD_AND_TOBAGO',
        TUNISIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TUNISIA',
        TURKEY: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TURKEY',
        TURKMENISTAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TURKMENISTAN',
        TUVALU: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.TUVALU',
        UKRAINE: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UKRAINE',
        UGANDA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UGANDA',
        HUNGARY: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.HUNGARY',
        URUGUAY: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.URUGUAY',
        UZBEKISTAN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.UZBEKISTAN',
        VANUATU: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.VANUATU',
        VENEZUELA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.VENEZUELA',
        VIETNAM: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.VIETNAM',
        YEMEN: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.YEMEN',
        ZAMBIA: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ZAMBIA',
        ZIMBABWE: 'HOMEPAGE.FILTERS.FILTER_NAZIONALITA.ZIMBABWE',
      },
    },
  },
  CAR_DETAIL: {
    STATUS: {
      NEGOTIABLE: 'CAR_DETAIL.STATUS.NEGOTIABLE',
      OWNED: 'CAR_DETAIL.STATUS.OWNED',
      NOT_NEGOTIABLE: 'CAR_DETAIL.STATUS.NOT_NEGOTIABLE',
    },
    engine: 'CAR_DETAIL.engine',
    displacement: 'CAR_DETAIL.displacement',
    bore_and_stroke: 'CAR_DETAIL.bore_and_stroke',
    VIN: 'CAR_DETAIL.VIN',
    CERTIFICATION: 'CAR_DETAIL.CERTIFICATION',
    ISSUE_DATA: 'CAR_DETAIL.ISSUE_DATA',
    CLOSE: 'CAR_DETAIL.CLOSE',
    SHARE: 'CAR_DETAIL.SHARE',
    MANAGE_YOUR_CAR: 'CAR_DETAIL.MANAGE_YOUR_CAR',
    REQUEST_INFO: 'CAR_DETAIL.REQUEST_INFO',
    REQUEST_OWNERSHIP_TEXT: 'CAR_DETAIL.REQUEST_OWNERSHIP_TEXT',
    INFO_VEHICLE: 'CAR_DETAIL.INFO_VEHICLE',
    GO_TO_CERTIFICATION: 'CAR_DETAIL.GO_TO_CERTIFICATION',
    TEXT_CERTIFICATION_BOX: 'CAR_DETAIL.TEXT_CERTIFICATION_BOX',
    MEASURE_UNITS: {
      DISPLACEMENT: 'CAR_DETAIL.MEASURE_UNITS.DISPLACEMENT',
      BORE_AND_STROKE: 'CAR_DETAIL.MEASURE_UNITS.BORE_AND_STROKE',
    },
    ACCORDION: {
      TITLE: {
        general_info: 'CAR_DETAIL.ACCORDION.TITLE.general_info',
        tech_info: 'CAR_DETAIL.ACCORDION.TITLE.tech_info',
      },
      LIST_INFO: {
        vehicle_type: 'CAR_DETAIL.ACCORDION.LIST_INFO.vehicle_type',
        production_date: 'CAR_DETAIL.ACCORDION.LIST_INFO.production_date',
        sales_dealer: 'CAR_DETAIL.ACCORDION.LIST_INFO.sales_dealer',
        sales_market: 'CAR_DETAIL.ACCORDION.LIST_INFO.sales_market',
        original_internal_color:
          'CAR_DETAIL.ACCORDION.LIST_INFO.original_internal_color',
        original_external_color:
          'CAR_DETAIL.ACCORDION.LIST_INFO.original_external_color',
        last_known_external_color:
          'CAR_DETAIL.ACCORDION.LIST_INFO.last_known_external_color',
        last_known_internal_color:
          'CAR_DETAIL.ACCORDION.LIST_INFO.last_known_internal_color',
        last_known_market: 'CAR_DETAIL.ACCORDION.LIST_INFO.last_known_market',
        last_certification_date:
          'CAR_DETAIL.ACCORDION.LIST_INFO.last_certification_date',
        chassis_type: 'CAR_DETAIL.ACCORDION.LIST_INFO.chassis_type',
        engine_type: 'CAR_DETAIL.ACCORDION.LIST_INFO.engine_type',
        bridge_type: 'CAR_DETAIL.ACCORDION.LIST_INFO.bridge_type',
        transmission_type: 'CAR_DETAIL.ACCORDION.LIST_INFO.transmission_type',
        numero_scocca: 'CAR_DETAIL.ACCORDION.LIST_INFO.numero_scocca',
      },
    },
    MODAL: {
      MANAGE_YOUR_CAR_TITLE: 'CAR_DETAIL.MODAL.MANAGE_YOUR_CAR_TITLE',
      MANAGE_YOUR_CAR_SUBTITLE: 'CAR_DETAIL.MODAL.MANAGE_YOUR_CAR_SUBTITLE',
      MANAGE_YOUR_CAR_TITLE_REMOVE: 'CAR_DETAIL.MODAL.MANAGE_YOUR_CAR_TITLE_REMOVE',
      MANAGE_YOUR_CAR_SUBTITLE_REMOVE: 'CAR_DETAIL.MODAL.MANAGE_YOUR_CAR_SUBTITLE_REMOVE',
      LOGIN_REQUIRED_TITLE: 'CAR_DETAIL.MODAL.LOGIN_REQUIRED_TITLE',
      LOGIN_REQUIRED_SUBTITLE: 'CAR_DETAIL.MODAL.LOGIN_REQUIRED_SUBTITLE',
      REQUEST_INFORMATION_TITLE: 'CAR_DETAIL.MODAL.REQUEST_INFORMATION_TITLE',
      REQUEST_OWNERSHIP_TITLE: 'CAR_DETAIL.MODAL.REQUEST_OWNERSHIP_TITLE',
      REQUEST_OWNERSHIP_SUBTITLE: 'CAR_DETAIL.MODAL.REQUEST_OWNERSHIP_SUBTITLE',
      REQUEST_INFO_TITLE: 'CAR_DETAIL.MODAL.REQUEST_INFO_TITLE',
      REQUEST_INFO_SUBTITLE: 'CAR_DETAIL.MODAL.REQUEST_INFO_SUBTITLE',
      TITLE_CONFIRM_REQUEST: 'CAR_DETAIL.MODAL.TITLE_CONFIRM_REQUEST',
      SUBTITLE_CONFIRMED_INFORMATION_REQUEST_INFO:
        'CAR_DETAIL.MODAL.SUBTITLE_CONFIRM_REQUEST_INFORMATION',
      SUBTITLE_CONFIRMED_INFORMATION_MANAGE_CAR:
        'CAR_DETAIL.MODAL.SUBTITLE_CONFIRMED_INFORMATION_MANAGE_CAR',
      BUTTON_SEND: 'CAR_DETAIL.MODAL.BUTTON_SEND',
      BUTTON_ACTIVATE: 'CAR_DETAIL.MODAL.BUTTON_ACTIVATE',
      BUTTON_ACTIVATE_REMOVE: 'CAR_DETAIL.MODAL.BUTTON_ACTIVATE_REMOVE',
      BUTTON_GO_TO_LOGIN: 'CAR_DETAIL.MODAL.BUTTON_GO_TO_LOGIN',
      BUTTON_REQUEST: 'CAR_DETAIL.MODAL.BUTTON_REQUEST',
      BUTTON_REGISTER: 'CAR_DETAIL.MODAL.BUTTON_REGISTER',
    },
  },
  CERTIFICATION_PROGRESS: {
    BACK_BUTTON: 'CERTIFICATION_PROGRESS.BACK_BUTTON',
    STEPS: {
      STEP_0: 'CERTIFICATION_PROGRESS.STEPS.STEP_0',
      STEP_1: 'CERTIFICATION_PROGRESS.STEPS.STEP_1',
      STEP_2: 'CERTIFICATION_PROGRESS.STEPS.STEP_2',
      STEP_3: 'CERTIFICATION_PROGRESS.STEPS.STEP_3',
    },
    SECTIONS: {
      SECTION_0: 'CERTIFICATION_PROGRESS.SECTIONS.SECTION_0',
      SECTION_1: 'CERTIFICATION_PROGRESS.SECTIONS.SECTION_1',
      SECTION_2: 'CERTIFICATION_PROGRESS.SECTIONS.SECTION_2',
      SECTION_3: 'CERTIFICATION_PROGRESS.SECTIONS.SECTION_3',
      SECTION_4: 'CERTIFICATION_PROGRESS.SECTIONS.SECTION_4',
    },
    TOP_INFO: {
      COMPLETED: 'CERTIFICATION_PROGRESS.TOP_INFO.COMPLETED',
    },
    DESCRIPTION: 'CERTIFICATION_PROGRESS.DESCRIPTION',
    ACCORDIONS: {
      TITLES: {
        CARROZZERIA: 'CERTIFICATION_PROGRESS.ACCORDIONS.TITLES.CARROZZERIA',
        TELAIO: 'CERTIFICATION_PROGRESS.ACCORDIONS.TITLES.TELAIO',
        MOTORE: 'CERTIFICATION_PROGRESS.ACCORDIONS.TITLES.MOTORE',
        'CAMBIO/DIFFERENZIALE': 'CERTIFICATION_PROGRESS.ACCORDIONS.TITLES.CAMBIO/DIFFERENZIALE',
        SOSPENSIONI: 'CERTIFICATION_PROGRESS.ACCORDIONS.TITLES.SOSPENSIONI',
        FRENI: 'CERTIFICATION_PROGRESS.ACCORDIONS.TITLES.FRENI',
        RUOTE: 'CERTIFICATION_PROGRESS.ACCORDIONS.TITLES.RUOTE',
        ACCESSORI: 'CERTIFICATION_PROGRESS.ACCORDIONS.TITLES.ACCESSORI',
      },
    },
  },
  CAROUSEL: {
    PREVIOUS: 'CAROUSEL.PREVIOUS',
    NEXT: 'CAROUSEL.NEXT',
  },
};
