import styled, { css } from 'styled-components';

export const CarStatusInfoHeaderContainer = styled.div`
  position: ${(props) => props.position};;
  top: ${(props) => props.top};
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  width: 100%;
  text-align: right;
  padding-top: 0;
  left: 0;

  ${({ openShare }) => openShare && css`
    height: 100vh;
    background-image: radial-gradient(circle at 88% 18%, #000000, black 14%, rgba(0, 0, 0, 0));
  `}

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    left: 50%;
    transform: translateX(-50%);

    ${({ openShare }) => openShare && css`
    height: 100vh;
    background-image: radial-gradient(circle at 88% 24%, #000000, black 20%, rgba(0, 0, 0, 0));
  `}
  }

`;

export const CarStatusInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 120px;
  padding: 0 24px;
  width: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 65%, rgba(0, 0, 0, 0.7));

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    padding: 0 47px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
  }
`;

export const TextShadownContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StatusContainerInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${({ openShare }) => openShare && css`
    display: none;
  `}
 
  > button {
    margin-right: 16px;

    @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
      margin-right: 24px;
    }

    &:last-child {
      margin-right: 16px;

      @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
        margin-right: 32px;
      }
    }
    
  }
`;

export const CarStatusShareLink = styled.div`
  padding: 0 24px;
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  position: relative;
  top: -30px;
  height: 0;

  ${({ openShare }) => openShare && css`
    height: 100%;
  `}

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    padding: 0 47px;
  }
`;

export const LinkContainer = styled.div`
  position: relative;
  margin-top: 7px;
  display: none;

  ${({ openShare }) => openShare && css`
    display: block;
  `}

  > button { 
    text-transform: uppercase;
    position: relative;
    top: 20px;
    opacity: 0;
    margin-left: auto;
    background-color: transparent;
    border: 0;
    outline: none;
    height: 24px;

    &:hover {
      cursor: pointer;
    }
    
    &:active {
      outline: none;
    }

    ${({ openShare }) => openShare && css`
      display: block;
      top: 0px;
      opacity: 1;
      transition: all 0.1s linear;
      transition-delay: ${(props) => ((props.index) + 0.25) * 0.1}s;
    `}
  }
`;

export const SvgContainer = styled.div`
  height: 22px;
  width: 22px;
  padding: 4px;
  border-radius: 50%;
  background-color: #303030;
  margin-left: auto;
  position: relative;
  display: none;

  svg {
    position: absolute;
    top: 2px;
    right: 3px;
    height: 16px;
    width: 16px;
  }

  ${({ openShare }) => openShare && css`
    display: block;
  `}

`;
