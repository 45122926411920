import styled, { css } from 'styled-components';

export const QuestionnaireContainer = styled.div`
  border-bottom: 1px solid #eeeded;
  min-height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;

 ${({ textAnswer }) => textAnswer
    && css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      min-height: 136px;

      > span {
        margin-top: 16px
      }
  `}

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    min-height: 81px;
    ${({ textAnswer }) => textAnswer
      && css`
        flex-direction: column;
        min-height: 127px;

        > span {
          margin-top: 24px
        }
    `}
  }
  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    ${({ textAnswer }) => textAnswer
      && css`
        min-height: 129px;
    `}
    }

`;
