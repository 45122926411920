const icons = {
  'ferrari-icon-black-1': 'e900',
  'ferrari-icon-black-2': 'e901',
  'ferrari-icon-black-3': 'e902',
  'ferrari-icon-black-4': 'e903',
  'ferrari-icon-black-5': 'e904',
  'ferrari-icon-black-6': 'e905',
  'ferrari-icon-black': 'e906',
  'ferrari-icon-close-path1': 'e907',
  'ferrari-icon-close-path2': 'e908',
  'ferrari-icon-combined-shape-1': 'e909',
  'ferrari-icon-combined-shape-copy': 'e90a',
  'ferrari-icon-combined-shape': 'e90b',
  'ferrari-icon-group-3': 'e90c',
  'ferrari-icon-group-7': 'e90d',
  'ferrari-icon-group-24-path1': 'e90e',
  'ferrari-icon-group-24-path2': 'e90f',
  'ferrari-icon-group-25-path1': 'e910',
  'ferrari-icon-group-25-path2': 'e911',
  'ferrari-icon-path': 'e912',
};

export default { icons };
