/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import '../../App.scss';
import { connect } from 'react-redux';
import { ThemeContext } from 'styled-components';
import Container from '../../atoms/Grid/Container';
import {
  HomePageContainer,
  MaxWidthDiv,
  HomePageTitleContainer,
  HomePageSubtitleContainer,
  ContainerFilters,
  FilterSelectSection,
  FilterButtonSection,
  ButtonSecondaryContainer,
  ButtonMainRedContainer,
  LineDivider,
  IconFilterMobile,
  VievMoreSection,
  NoResultContainer,
  FilterButtonSectionLeft,
} from './index.style';
import Button from '../../atoms/Button';
import Typography from '../../atoms/Typography';
import TextInput from '../../atoms/TextInput';
import VehicleCard from '../../components/VehicleCard';
import { colorsNameKey } from '../../const/theme.const';
import ScrollToTopButton from '../../components/ScrollToTopButton';
// import stub from './stub.json';
import ICONS_TYPE from '../../atoms/Icon/index.enum';
import { TRANSLATION_KEYS } from '../../i18n/index.enum';
import Row from '../../atoms/Grid/Row';
import Col from '../../atoms/Grid/Col';
import InputSelect from '../../atoms/InputSelect';
import Modal from '../../components/Modal';
import Icon from '../../atoms/Icon';
import ModalBodyHomepage from '../../components/Modal/modalBody/ModalBodyHomepage';
import { ROUTES_URL } from '../../components/RouterContainer/index.routes';
import {
  GET_ALL_MODEL_CAR,
  GET_ALL_CERTIFICATION_LIGHT_HOUSE,
} from '../../redux/actions/homepage';
import { selectFilter } from './index.const';
import { windowSize } from '../../ui/theme/device';

const Homepage = ({
  models,
  vehicleList,
  getAllModelCar,
  getAllCertificationLightHouse,
}) => {
  const { t } = useTranslation();
  const [displayFilter, setDisplayFilter] = useState(true);
  const [modalShow, setShoWModal] = useState(false);

  const [yearProduction, setYearProduction] = useState();

  const [search, setSearch] = useState();

  const [initialfilters, setInitialfilters] = useState(selectFilter(t));
  const [filters, setFilters] = useState(selectFilter(t));
  const [activeFilters, setActiveFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  // TODO setVehicleInLastPage when add fetch
  const [vehicleInLastPage, setVehicleInLastPage] = useState(
    vehicleList.length % 12 === 0,
  );

  const callAllCertificationWithFilters = (hasToReset) => {
    if (hasToReset && currentPage !== 0) {
      setCurrentPage(0);
      return;
    }
    const currentFilters = {
      page: currentPage,
      vin: search,
      ...activeFilters,
    };
    getAllCertificationLightHouse(currentFilters, hasToReset);
  };

  useEffect(() => {
    getAllModelCar();
    callAllCertificationWithFilters(currentPage === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllModelCar, getAllCertificationLightHouse, currentPage]);

  useEffect(() => {
    setVehicleInLastPage(vehicleList.length % 12 === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleList]);

  useEffect(() => {
    setFilters((state) => [
      ...state.map((filter) => (filter.id === 'model'
        ? {
          ...filter,
          option: [
            ...filter.option,
            ...models.map((model) => ({
              label: model,
              value: model,
              selected: false,
              disabled: false,
            })),
          ],
        }
        : { ...filter })),
    ]);
  }, [models]);

  useEffect(() => {
    setInitialfilters((state) => [
      ...state.map((filter) => (filter.id === 'modello'
        ? {
          ...filter,
          option: [
            ...filter.option,
            ...models.map((model) => ({
              label: model,
              value: model,
              selected: false,
              disabled: false,
            })),
          ],
        }
        : filter)),
    ]);
  }, [models]);

  useEffect(() => {
    if (Object.keys(activeFilters).length > 0) {
      // activeFilters = nazionalita: italiana"
      setFilters((state) => [
        ...state.map((filterElement) => ({
          ...filterElement,
          // activeFilters: {colore_interni : "rosso", stato_certificazione: "b"}
          // option  activeFilters[colore_interni]
          // controlla se stai iterando l'elemento presente
          // in activeFilters, nel caso cambi il valore di selected
          option: activeFilters[filterElement.id] // activeFilters.colore_interni
            ? [
              ...filterElement.option.map((option) => ({
                ...option,
                selected: activeFilters[filterElement.id] === option.value,
              })),
            ]
            : [...filterElement.option],
        })),
      ]);
    } else {
      setFilters(initialfilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  const { size } = windowSize();

  const themeContext = useContext(ThemeContext);

  return (
    <>
      {themeContext.device.windowScroolToTop().scroll > (window.innerHeight / 2) ? (
        <ScrollToTopButton />
      ) : null}
      <Container>
        <HomePageContainer>
          {size === 'S' ? (
            <IconFilterMobile alert={Object.keys(activeFilters).length > 0}>
              <Icon
                icon={Icon.ICONS_TYPE.SETTTINGS}
                size={18}
                onClick={() => setShoWModal((state) => !state)}
              />
            </IconFilterMobile>
          ) : null}
          <HomePageTitleContainer>
            <Typography
              tag="h1"
              type={Typography.TYPES.HEADLINE}
              color={colorsNameKey.BLACK_90}
            >
              {t(TRANSLATION_KEYS.HOMEPAGE.TITLE)}
            </Typography>
          </HomePageTitleContainer>
          <HomePageSubtitleContainer>
            <MaxWidthDiv maxWidth="80%">
              <Typography
                tag="p"
                type={Typography.TYPES.BODY}
                color={colorsNameKey.BLACK_90}
              >
                {t(TRANSLATION_KEYS.HOMEPAGE.SUBTITLE)}
              </Typography>
            </MaxWidthDiv>
          </HomePageSubtitleContainer>
          {size !== 'S' ? (
            <>
              <Row>
                <Col xs="12" sm="12" md="6" xl="3" lg="4">
                  <TextInput
                    margin="64px 0px 0px 0px"
                    id="searcHome"
                    value={search}
                    onChange={({ target: { value } }) => {
                      setSearch(value);
                    }}
                    placeholder={t(
                      TRANSLATION_KEYS.HOMEPAGE.INPUT_SEARCH_FILTER,
                    )}
                    icon={ICONS_TYPE.SEARCH}
                    type={TextInput.TYPES.SEARCH.name}
                  />
                </Col>
              </Row>
              <ContainerFilters>
                <FilterSelectSection displayNone={displayFilter}>
                  <Row>
                    {filters.map((element, index) => {
                      if (index === 2) {
                        return (
                          <Col
                            sm="12"
                            md="6"
                            lg="3"
                            xl="3"
                            className="offset-right-lg-3 offset-right-xl-3"
                            key={element.id}
                          >
                            <InputSelect
                              id={element.id}
                              disabled={element.disabled}
                              margin="32px 0px 0px 0px"
                              icon={ICONS_TYPE.CHEVRON_DOWN}
                              selectOption={element.option}
                              onChange={({ target: { value } }) => {
                                setActiveFilters((state) => ({
                                  ...state,
                                  [element.id]: value,
                                }));
                              }}
                            />
                          </Col>
                        );
                      }
                      return (
                        <Col
                          xs="12"
                          sm="12"
                          md="6"
                          xl="3"
                          lg="3"
                          key={element.id}
                        >
                          <InputSelect
                            id={element.id}
                            disabled={element.disabled}
                            margin="32px 0px 0px 0px"
                            icon={ICONS_TYPE.CHEVRON_DOWN}
                            selectOption={element.option}
                            onChange={({ target: { value } }) => {
                              setActiveFilters((state) => ({
                                ...state,
                                [element.id]: value,
                              }));
                            }}
                          />
                        </Col>
                      );
                    })}
                    <Col xs="12" sm="12" md="6" xl="3" lg="3">
                      <TextInput
                        margin="32px 0px 0px 0px"
                        inputType="number"
                        max={9999}
                        value={yearProduction}
                        placeholder={t(TRANSLATION_KEYS.HOMEPAGE.anno_prod)}
                        type={TextInput.TYPES.SEARCH.name}
                        onChange={({ target: { value } }) => {
                          setYearProduction(value);
                          setActiveFilters((state) => ({
                            ...state,
                            yearProduction: Number(value),
                          }));
                        }}
                      />
                    </Col>
                  </Row>
                </FilterSelectSection>
                <FilterButtonSection>
                  <FilterButtonSectionLeft>
                    <ButtonSecondaryContainer>
                      <Button
                        type={Button.TYPES.SECONDARY}
                        disabled={
                        (search === undefined || search === null)
                        && Object.keys(activeFilters).length === 0
                      }
                        onClick={() => {
                          setSearch();
                          setActiveFilters(() => ({}));
                          setYearProduction();
                          getAllModelCar();
                        }}
                      >
                        {t(TRANSLATION_KEYS.HOMEPAGE.MODAL.RESET_FILTERS)}
                      </Button>
                    </ButtonSecondaryContainer>
                    <ButtonSecondaryContainer>
                      <Button
                        type={Button.TYPES.SECONDARY}
                        iconSize={displayFilter ? 16 : 25}
                        icon={
                        displayFilter
                          ? Button.ICONS_TYPE.PLUS
                          : Button.ICONS_TYPE.REMOVE
                      }
                        onClick={() => setDisplayFilter((state) => !state)}
                      >
                        {t(TRANSLATION_KEYS.HOMEPAGE.BUTTON_MORE_FILTERS)}
                        {Object.keys(activeFilters).length > 0
                          ? ` (${Object.keys(activeFilters).length})`
                          : null}
                      </Button>
                    </ButtonSecondaryContainer>
                  </FilterButtonSectionLeft>

                  <ButtonMainRedContainer>
                    <Button
                      type={Button.TYPES.MAIN_RED}
                      onClick={() => callAllCertificationWithFilters(true)}
                    >
                      {t(TRANSLATION_KEYS.HOMEPAGE.BUTTON_SEARCH)}
                    </Button>
                  </ButtonMainRedContainer>
                </FilterButtonSection>
                <LineDivider />
              </ContainerFilters>
            </>
          ) : null}

          {vehicleList.length > 0 ? (
            <>
              <Row>
                {vehicleList.map((ele, i) => (
                  <Col xs="12" sm="12" md="6" xl="3" lg="4" key={i}>
                    <VehicleCard
                      margin="24px 0px 0px 0px"
                      imgSrc={ele.urlPhoto}
                      imgAlt=""
                      carYear={ele.productionYear}
                      carTitle={ele.model}
                      carVinLabel={t(TRANSLATION_KEYS.CAR_DETAIL.VIN)}
                      plate={ele.vin}
                      certificationLabel={t(
                        TRANSLATION_KEYS.CAR_DETAIL.CERTIFICATION,
                      )}
                      certification={ele.certificationStatus}
                      issueDateLabel={t(TRANSLATION_KEYS.CAR_DETAIL.ISSUE_DATA)}
                      issueDate={
                        ele.lastCertificationReleaseDate
                          ? (typeof ele.lastCertificationReleaseDate
                              === 'string'
                              || typeof ele.lastCertificationReleaseDate
                                === 'number')
                            && ele.lastCertificationReleaseDate.toString()
                              .length === 13
                            && ele.lastCertificationReleaseDate.toString().match(/^\d+$/)
                            ? dayjs(
                              Number(ele.lastCertificationReleaseDate),
                            ).format(t(TRANSLATION_KEYS.COMMON.DATE_FORMAT))
                            : ele.lastCertificationReleaseDate
                          : null
                      }
                      to={ROUTES_URL.car_detail(ele.vin)}
                    />
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <NoResultContainer>
              <Typography
                tag="p"
                type={Typography.TYPES.CAPTION}
                color={colorsNameKey.BLACK_90}
              >
                {t(TRANSLATION_KEYS.HOMEPAGE.NO_RESULTS)}
              </Typography>
            </NoResultContainer>
          )}

          {vehicleInLastPage && vehicleList.length / 12 === currentPage + 1 ? (
            <VievMoreSection>
              <Button
                type={Button.TYPES.MAIN_ON_BLACK}
                onClick={() => setCurrentPage((state) => state + 1)}
              >
                {t(TRANSLATION_KEYS.HOMEPAGE.BUTTON_VIEW_MORE)}
              </Button>
            </VievMoreSection>
          ) : null}

          {
            // TODO Add hook for cardFilter and chech if cardFilter = 0
          }
        </HomePageContainer>
        {size === 'S' ? (
          <Modal
            active={modalShow}
            fullScreen
            padding="0 25px"
            close={() => setShoWModal(false)}
          >
            <ModalBodyHomepage
              filter={filters}
              activeFilters={activeFilters}
              onFiltersChange={(newActiveFilters) => {
                setActiveFilters(newActiveFilters);
              }}
              onSubmit={() => {
                callAllCertificationWithFilters(true);
                setShoWModal(false);
              }}
              yearProduction={yearProduction}
              onYearFilterChange={setYearProduction}
              searchText={search}
              onSearchChange={setSearch}
            />
          </Modal>
        ) : null}
      </Container>
    </>
  );
};

export default connect(
  // eslint-disable-next-line camelcase
  ({ homepage: { models, vehicleList } }) => ({
    models,
    vehicleList,
  }),
  (dispatch) => ({
    getAllModelCar: () => dispatch({ type: GET_ALL_MODEL_CAR._REQUEST }),
    getAllCertificationLightHouse: (payload, reset) => dispatch({
      type: GET_ALL_CERTIFICATION_LIGHT_HOUSE._REQUEST,
      payload,
      reset,
    }),
  }),
)(Homepage);
