import client, { apiEnv } from '../client';

export const getAllCertificationLightHouse = (body, token) => client.post(token)(
  apiEnv[apiEnv.env].url(
    apiEnv.LAST_PATH.LIGHTHOUSE,
    apiEnv.APIS.GET_ALL_CERTIFICATION_LIGHT_HOUSE,
  ),
  body,
);
export const getAllModelCar = (token) => client.post(token)(
  apiEnv[apiEnv.env].url(
    apiEnv.LAST_PATH.LIGHTHOUSE,
    apiEnv.APIS.GET_ALL_MODEL_CAR,
  ),
  {},
);
