import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_KEYS } from '../../i18n/index.enum';
import {
  AccordionContainer,
  AccordionHeader,
  AccordionBody,
  AccordionBodyListItemContainer,
} from './index.style';
import Typography from '../../atoms/Typography';
import Icon from '../../atoms/Icon';
import { colorsNameKey } from '../../const/theme.const';

const Accordion = ({
  text, open, listInfoBody, onToggle, children,
}) => {
  const [openState, setOpenState] = useState(open);

  const toogleCollapse = () => {
    setOpenState(!openState);
  };

  // when open change
  useEffect(() => {
    setOpenState(open);
  }, [open]);

  useEffect(() => {
    onToggle(openState);
  }, [openState, onToggle]);

  const { t } = useTranslation();

  return (
    <AccordionContainer>
      <AccordionHeader onClick={toogleCollapse} open={openState}>
        <Typography
          tag="p"
          type={Typography.TYPES.ACCORDION_TITLE}
          color={colorsNameKey.BLACK_90}
        >
          {text}
        </Typography>

        <Icon icon={Icon.ICONS_TYPE.PLUS} size={16} />
      </AccordionHeader>

      <AccordionBody open={openState}>
        {!children
          ? listInfoBody.map((element, index) => (
            <AccordionBodyListItemContainer key={index}>
              <Typography
                tag="p"
                type={Typography.TYPES.CAPTION}
                color={colorsNameKey.BLACK_50}
              >
                {t(TRANSLATION_KEYS.CAR_DETAIL.ACCORDION.LIST_INFO[element.label])
                  ? t(TRANSLATION_KEYS.CAR_DETAIL.ACCORDION.LIST_INFO[element.label])
                  : element.label }
              </Typography>
              <Typography
                tag="span"
                type={Typography.TYPES.ACCORDION_LIST_ITEM_VALUE}
                color={colorsNameKey.BLACK_90}
              >
                {(element.value !== null && element.value !== 'null') ? element.value : '-'}
              </Typography>
            </AccordionBodyListItemContainer>
          ))
          : children}
      </AccordionBody>
    </AccordionContainer>
  );
};

Accordion.defaultProps = {
  text: null,
  open: false,
  listInfoBody: null,
  onToggle: () => null,
  children: null,
};

Accordion.propTypes = {
  text: PropTypes.string,
  open: PropTypes.bool,
  listInfoBody: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  onToggle: PropTypes.func,
};

export default Accordion;
