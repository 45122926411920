/* eslint-disable camelcase */
import {
  takeLatest, select, call, put,
} from 'redux-saga/effects';
import { GET_CERTIFICATION_DETAILS } from '../actions/certification';
import { getCertificationDetails } from '../../utils/apis/certification';
import { SPINNER_OPEN, SPINNER_CLOSE } from '../actions/types';

function* getCertificationDetailsFn({ payload: body }) {
  try {
    yield put({
      type: SPINNER_OPEN,
    });
    const { access_token: token } = yield select(({ login }) => login);
    if (!token) {
      throw new Error('NOT_LOGGED');
    }
    const { data } = yield call(
      getCertificationDetails,
      {
        ...body,
      },
      token,
    );
    yield put({
      type: GET_CERTIFICATION_DETAILS._SUCCESS,
      newState: { ...data },
    });
  } catch (e) {
    yield put({ type: GET_CERTIFICATION_DETAILS._ERROR, error: e });
  } finally {
    yield put({ type: SPINNER_CLOSE });
  }
}

function* watchGetCertificationDetails() {
  yield takeLatest(
    GET_CERTIFICATION_DETAILS._REQUEST,
    getCertificationDetailsFn,
  );
}

export default [watchGetCertificationDetails];
