import styled, { css } from 'styled-components';

export const CertificationProgressContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: ${({ theme }) => theme.constants.header_margin}px;
  margin-bottom: ${({ theme }) => theme.constants.header_margin}px;
`;

export const HeadingContainer = styled.section`
  width: 100%;
  position: relative;
  > button {
    margin: 30px 0;
  }
  > h3,
  > p {
    margin-bottom: 24px;
  }
`;

export const DropdownListContainer = styled.ul`
  width: 100%;
  position: relative;
  padding: 0;
  > li {
    list-style-type: none;
    width: 100%;
    position: relative;
    > div {
      > button {
        padding: 34px 0;
      }
    }
  }
`;

export const CarouselContainer = styled.div`
  .splide-container {
    ${({ size }) => (size === 'S' || size === 'M')
      && css`
        > div {
          > div {
            &:first-child {
              margin-right: 16px;
            }
          }
        }
        &:last-child {
          > div {
            > div {
              &:first-child {
                margin-right: 0;

                & + div {
                  > i {
                    right: -11px;

                    @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
                      right: 6px;
                    }
                  }
                }
              }
            }
          }
        }
      `}
  }
`;

export const SectorTabContainer = styled.div`
  margin-bottom: 24px;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    margin-bottom: 30px;
  }

`;
