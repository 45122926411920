import styled, { css } from 'styled-components';
import { TYPES, ICON_POSITION } from './index.enum';

export const ButtonContainer = styled.button`
  border: none;
  outline: none;
  background: none;
  height: 56px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > p,
  > i {
    cursor: pointer;
  }
  &:focus,
  &:active {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    > p {
      cursor: not-allowed;
    }
  }
  ${({ fullWidth }) => fullWidth && css`
    width: 100%;
  `}
  ${({ theme, type }) => {
    switch (type) {
      case TYPES.MAIN_ON_BLACK:
        return css`
          background: ${theme.colors.buttons.main_on_black.normal};
          > p {
            color: ${theme.colors.buttons.main_on_black.text.normal};
          }
          > i {
            color: ${theme.colors.buttons.main_on_black.text.normal};
          }
          &:hover {
            background: ${theme.colors.buttons.main_on_black.hover};
            > p {
              color: ${theme.colors.buttons.main_on_black.text.hover};
            }
            > i {
              color: ${theme.colors.buttons.main_on_black.text.hover};
            }
          }
          &:active {
            background: ${theme.colors.buttons.main_on_black.pressed};
            > p {
              color: ${theme.colors.buttons.main_on_black.text.pressed};
            }
            > i {
              color: ${theme.colors.buttons.main_on_black.text.pressed};
            }
          }
          &:disabled {
            background: ${theme.colors.buttons.main_on_black.disabled};
            > p {
              color: ${theme.colors.buttons.main_on_black.text.disabled};
            }
            > i {
              color: ${theme.colors.buttons.main_on_black.text.disabled};
            }
          }
        `;
      case TYPES.MAIN_RED:
        return css`
          background: ${theme.colors.buttons.main_red.normal};
          > p {
            color: ${theme.colors.buttons.main_red.text.normal};
          }
          > i {
            color: ${theme.colors.buttons.main_red.text.normal};
          }
          &:hover {
            background: ${theme.colors.buttons.main_red.hover};
            > p {
              color: ${theme.colors.buttons.main_red.text.hover};
            }
            > i {
              color: ${theme.colors.buttons.main_red.text.hover};
            }
          }
          &:active {
            background: ${theme.colors.buttons.main_red.pressed};
            > p {
              color: ${theme.colors.buttons.main_red.text.pressed};
            }
            > i {
              color: ${theme.colors.buttons.main_red.text.pressed};
            }
          }
          &:disabled {
            background: ${theme.colors.buttons.main_red.disabled};
            > p {
              color: ${theme.colors.buttons.main_red.text.disabled};
            }
            > i {
              color: ${theme.colors.buttons.main_red.text.disabled};
            }
          }
        `;
      case TYPES.SECONDARY:
        return css`
          background: ${theme.colors.buttons.secondary.normal};
          box-shadow: inset 0 0 0 1px
            ${theme.colors.buttons.secondary.border.normal};
          > p {
            color: ${theme.colors.buttons.secondary.text.normal};
          }
          > i {
            color: ${theme.colors.buttons.secondary.text.normal};
          }
          &:hover {
            background: ${theme.colors.buttons.secondary.hover};
            box-shadow: inset 0 0 0 1px
              ${theme.colors.buttons.secondary.border.hover};
            > p {
              color: ${theme.colors.buttons.secondary.text.hover};
            }
            > i {
              color: ${theme.colors.buttons.secondary.text.hover};
            }
          }
          &:active {
            background: ${theme.colors.buttons.secondary.pressed};
            box-shadow: inset 0 0 0 1px
              ${theme.colors.buttons.secondary.border.pressed};
            > p {
              color: ${theme.colors.buttons.secondary.text.pressed};
            }
            > i {
              color: ${theme.colors.buttons.secondary.text.pressed};
            }
          }
          &:disabled {
            background: ${theme.colors.buttons.secondary.disabled};
            box-shadow: inset 0 0 0 1px
              ${theme.colors.buttons.secondary.border.disabled};
            > p {
              color: ${theme.colors.buttons.secondary.text.disabled};
            }
            > i {
              color: ${theme.colors.buttons.secondary.text.disabled};
            }
          }
        `;
      default:
        return null;
    }
  }}
  ${({ iconPosition }) => {
    switch (iconPosition) {
      case ICON_POSITION.RIGHT:
        return css`
          > i {
            order: 2;
            margin-left: 16px;
          }
        `;
      case ICON_POSITION.LEFT:
      default:
        return css`
          > i {
            margin-right: 16px;
          }
        `;
    }
  }}
`;
