import { takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'redux-first-history';
// eslint-disable-next-line require-yield
function* appFn() {
  window.scrollTo(0, 0);
  // yield put({ type: LOCATION_CHANGE, sortBy });
}

function* watchApp() {
  yield takeLatest(LOCATION_CHANGE, appFn);
}
export default [watchApp];
