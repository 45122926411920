import React from 'react';
import Proptypes from 'prop-types';
import { ImageCardContainer, ImageCardFooter, ImageContainer } from './index.style';
import Typography from '../../../../atoms/Typography';
import Icon from '../../../../atoms/Icon';


export const PhotoCardElementWithTitle = ({
  image,
  onClick,
  onIconClick,
  icon,
  label,
  hasFullView,
}) => (
  <ImageCardContainer onClick={onClick} hasFullView={hasFullView}>
    <ImageContainer image={image} hasFullView={hasFullView} />
    <ImageCardFooter>
      <Typography
        tag="p"
        type={Typography.TYPES.BODY}
        style={Typography.STYLES.ALT}
      >
        {label}
      </Typography>
      {icon && (
        <Icon
          onClick={(ev) => {
            onIconClick(ev);
            ev.stopPropagation();
          }}
          icon={icon}
        />
      )}
    </ImageCardFooter>
  </ImageCardContainer>
);

PhotoCardElementWithTitle.defaultProps = {
  onClick: () => null,
  hasFullView: false,
  icon: null,
  onIconClick: () => null,
};

PhotoCardElementWithTitle.propTypes = {
  image: Proptypes.oneOfType([Proptypes.string, Proptypes.node]).isRequired,
  onClick: Proptypes.func,
  onIconClick: Proptypes.func,
  icon: Proptypes.oneOf(Object.values(Icon.ICONS_TYPE)),
  label: Proptypes.string.isRequired,
  hasFullView: Proptypes.bool,
};

export default PhotoCardElementWithTitle;
