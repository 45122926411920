import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalBodyRequestOwnershipContainer,
  ButtonContainer,
  TitleContainer,
  SubTitleContainer,
} from './index.style';
import Typography from '../../../../atoms/Typography';
import { colorsNameKey } from '../../../../const/theme.const';
import Button from '../../../../atoms/Button';

const ModalBodyRequestOwnership = ({
  title,
  description,
  buttonText,
  ToogleStatus,
}) => (
  <ModalBodyRequestOwnershipContainer>
    <div>
      <TitleContainer>
        <Typography
          tag="p"
          type={Typography.TYPES.MODAL_TITLE_LOGIN_REQUEST}
          color={colorsNameKey.BLACK_90}
        >
          {title}
        </Typography>
      </TitleContainer>
      <SubTitleContainer>
        <Typography
          tag="p"
          type={Typography.TYPES.MODAL_SUBTITLE_LOGIN_REQUEST}
          color={colorsNameKey.BLACK_90}
        >
          {description}
        </Typography>
      </SubTitleContainer>
    </div>
    <ButtonContainer>
      <Button type={Button.TYPES.MAIN_RED} onClick={ToogleStatus}>
        {buttonText}
      </Button>
    </ButtonContainer>
  </ModalBodyRequestOwnershipContainer>
);

ModalBodyRequestOwnership.defaultProps = {
  ToogleStatus: () => null,
};

ModalBodyRequestOwnership.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  ToogleStatus: PropTypes.func,
};

export default ModalBodyRequestOwnership;
