import styled, { css } from 'styled-components';

export const WrapperContainer = styled.a`
  position: relative;
  display: block;
  ${({ fullWidth }) => fullWidth
    && css`
      width: 100%;
  `}

  ${({ hasBoxGrayOverflow }) => hasBoxGrayOverflow
    && css`
      position: relative;
      min-height: 348px;

      &:before {
        content: '';
          position: absolute;
          top: -25px;
          left: 50%;
          transform: translateX(-50%);
          width: 100vw;
          background: #eeeded;
          height: 110%;
      }

      @media ${(props) => (props.theme.device.mediaqueries.large_screen_min)} {
        min-height: 420px;
      }
      @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
        &::before {
          height: 100%;
        }
      }


  `}

  &:active {
    > .fake-cursor {
      transform: scale(0.7);
    }
  }
`;

export const FakeCursor = styled.div.attrs(({
  x, y, background, theme,
}) => ({
  style: {
    top: `${y - 28}px`,
    left: `${x - 28}px`,
    background: `${
      background
        ? theme.colors.primary[background]
        : theme.colors.primary.rosso_corsa
    }`,
  },
}))`
  pointer-events: none;
  touch-action: none;
  position: fixed;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: transform 0.1s;
  > p {
    position: absolute;
    left: 70px;
    margin-top: 2px;
    color: ${({ labelColor, theme }) => (labelColor
    ? theme.colors.primary[labelColor]
    : theme.colors.primary.white)};
  }
`;
