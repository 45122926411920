import styled, { css } from 'styled-components';

export const ImageCardContainer = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;

  height: 100vh;
  top: 50%;
  margin-top: auto;
  transform: translateY(-50%);

  /* &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
  } */

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
  }

  > img {
    transition: 0.2s;
    width: 100%;
  }
  &:hover {
    > img {
      transform: scale(1.05);
      transform-origin: center center;
    }
  }
`;

export const ImageCardFooter = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 16px;
  bottom: 80px;
  left: 0%;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  > p, > i {
    color: ${({ theme }) => theme.colors.primary.white};
  }
  > i {
    cursor: pointer;
    margin-left: auto;
    margin-right: 0;
  }

  @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
    bottom: 110px;
    > p {
      width: 100%;
      text-align: center;
    }
  }

  @media ${({ theme }) => theme.device.mediaqueries.desktop_screen_min} {
    > p {
      display: none;
    }
  }
`;

export const ImageContainer = styled.div`
  ${({ image }) => image
    && css`
      background-image: url(${image});
  `}
  background-repeat: no-repeat;
  background-origin: center;
  background-position: center;
  height: 100%;
  background-size: contain;
  min-height: 100%;

  @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
    min-height: 100%;
  }

  @media ${({ theme }) => theme.device.mediaqueries.desktop_screen_min} {
    background-size: contain;
  }


`;
