import { createAsyncActionType } from './types';

export const GET_VEHICLE_CARD_INFORMATION = createAsyncActionType(
  'CAR_DETAIL',
  'GET_VEHICLE_CARD_INFORMATION',
);

export const NEGOTIABILITY = createAsyncActionType(
  'CAR_DETAIL',
  'NEGOTIABILITY',
);
