import styled, { css } from 'styled-components';

export const TextIcon = styled.button`
  display: flex;
  align-items: center;
  /* width: 100%; */
  height: 100%;
  background-color: transparent;
  border: 0;
  padding: 0;
  
  &:hover {
    cursor: pointer;
    
    > div {
      > svg {
        > circle {
          stroke-dashoffset: 0;
        }
      }
    }
  }

  &:active, &:focus {
    outline: 0;
  }

  ${({ hasIconRight }) => {
    switch (hasIconRight) {
      case true:
        return css`
          > div  {
            order: 2;
            margin-left: 8px;
          }
          > i  {
            order: 2;
            margin-left: 8px;
          }
        `;
      case false:
      default:
        return css`
          > div {
            margin-right: 8px;
          }
          > i {
            margin-right: 8px;
          }
        `;
    }
  }}
`;
