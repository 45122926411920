import styled, { css } from 'styled-components';

export const CarouselContainer = styled.div`
  width: 100%;
  
  .splide__slide {
    ${({ paddingPerSlide }) => paddingPerSlide
      && css`
        box-sizing: border-box;
        padding: 0 ${paddingPerSlide};
      `}
    > * {
      width: 100%;
      height: 100%;
    }

    ${({ isInFullView }) => isInFullView
      && css`
        max-height: 100vh;
        background-color: black;
    `}
  }
  .splide__track {
    ${({ isInFullView }) => isInFullView
      && css`
        max-height: 100vh;
    `}
  }

  ${({ isInFullView }) => isInFullView
      && css`
      .splide__slide {
        > * {
          height: 100vh;
        }
      }
      `}
`;

export const DotsContainer = styled.div`
  margin-top: 22px;

  ${({ dotsAreInAbsolute }) => dotsAreInAbsolute
    && css`
      position: absolute;
      width: 100%;
      bottom: 32px;
      left: 0;
      background-color: transparent;

      @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
        bottom: 40px;
      }

      @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
        bottom: 56px;
      }
  `}
`;
