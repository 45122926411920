export const TYPES = {
  DSP_1: 'display1',
  DSP_2: 'display2',
  HEADLINE: 'headline',
  TITLE: 'title',
  SUBTITLE: 'subtitle',
  SUBTITLE_LITTLER: 'subtitleLittler',
  BODY: 'body',
  SMALL: 'small',
  CAPTION: 'caption',
  CUSTOM: 'custom',
  CARD_YEAR_VEHICLE: 'vehicleCardYear',
  CARD_TITLE_VEHICLE: 'vehicleCardTitle',
  CARD_PLATE_VEHICLE: 'vehicleCardPlate',
  CARD_LABEL_VEHICLE: 'vehicleCardLabel',
  CARD_INFO_VEHICLE: 'vehicleCardInfo',
  CARD_PLATE_VEHICLE_OVER: 'vehicleCardPlateOver',

  CARD_YEAR_DETAIL: 'vehicleCardYearDetail',
  CARD_TITLE_DETAIL: 'vehicleCardTitleDetail',
  CARD_PLATE_LABEL_DETAIL: 'vehicleCardPlateLabelDetail',
  CARD_PLATE_DETAIL: 'vehicleCardPlateDetail',

  VEHICLE_INFO_VALUE: 'vehicleInfoValue',
  VEHICLE_INFO_UNITY: 'vehicleInfoUnity',

  ACCORDION_TITLE: 'accordionTitle',
  ACCORDION_LIST_ITEM_VALUE: 'accordionListItemValue',

  MODAL_TITLE_LOGIN_REQUEST: 'modalTitleLoginRequest',
  MODAL_SUBTITLE_LOGIN_REQUEST: 'modalSubTitleLoginRequest',

  VECTOR_TAB_LABEL: 'vectorTabLabel',

  QUESTIONNARIE_ANSWER: 'questionnaireAnswer',

  HEADERLOGIN: 'headerLogin',


};

export const STYLES = {
  DEFAULT: 'default',
  ALT: 'alt',
  ITALIC: 'italic',
};

export const DEFAULT_CUSTOM = {
  fontFamily: 'GT America',
  fontSize: '16px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.38',
  letterSpacing: 'normal',
};
