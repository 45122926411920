import client, { apiEnv } from '../client';

export const getVehicleCardInformation = (body, token) => client.post(token)(
  apiEnv[apiEnv.env].url(
    apiEnv.LAST_PATH.LIGHTHOUSE,
    apiEnv.APIS.GET_VEHICLE_CARD_INFORMATION,
  ),
  body,
);

export const negotiability = (body, token) => client.post(token)(
  apiEnv[apiEnv.env].url(
    apiEnv.LAST_PATH.LIGHTHOUSE_AUTH,
    apiEnv.APIS.NEGOTIABILITY,
  ),
  body,
);
