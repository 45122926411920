import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';
import {
  LOGIN_ON_FERRARI,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CONVERT_TOKEN,
} from '../actions/login';
import { requestAccessToken, convertAccessToken } from '../../utils/apis/login';
import { SPINNER_OPEN, SPINNER_CLOSE } from '../actions/types';

function* loginOnFerrari({ code }) {
  try {
    yield put({ type: LOGIN_ON_FERRARI._SUCCESS, code });
    yield put({ type: ACCESS_TOKEN._REQUEST, code });
  } catch (e) {
    yield put({ type: LOGIN_ON_FERRARI._ERROR, error: e });
  }
}

function* watchLoginOnFerrari() {
  yield takeLatest(LOGIN_ON_FERRARI._REQUEST, loginOnFerrari);
}

function* accessToken({ code }) {
  try {
    yield put({
      type: SPINNER_OPEN,
    })
    // eslint-disable-next-line camelcase
    const { access_token: access_token_idp, refresh_token: refresh_token_idp } = yield call(
      requestAccessToken,
      code,
    );
    yield put({ type: ACCESS_TOKEN._SUCCESS, access_token_idp, refresh_token_idp });
    yield put({ type: CONVERT_TOKEN._REQUEST, access_token_idp });
  } catch (e) {
    yield put({ type: ACCESS_TOKEN._ERROR, error: e });
  } finally {
    yield put({ type: SPINNER_CLOSE })
  }
}

function* watchAccessToken() {
  yield takeLatest(ACCESS_TOKEN._REQUEST, accessToken);
}

// eslint-disable-next-line camelcase
function* convertToken({ access_token_idp: assertion }) {
  try {
    yield put({
      type: SPINNER_OPEN,
    })
    // eslint-disable-next-line camelcase
    const { access_token } = yield call(
      convertAccessToken,
      assertion,
    );

    // eslint-disable-next-line camelcase
    // const gui_id = access_token ? JSON.parse(atob(access_token.split('.')[1])).sub : null

    yield put({ type: CONVERT_TOKEN._SUCCESS, access_token });
  } catch (e) {
    yield put({ type: CONVERT_TOKEN._ERROR, error: e });
  } finally {
    yield put({ type: SPINNER_CLOSE })
  }
}

function* watchConvertToken() {
  yield takeLatest(CONVERT_TOKEN._REQUEST, convertToken);
}

function* refreshAccessToken() {
  try {
    yield put({
      type: SPINNER_OPEN,
    })
    // eslint-disable-next-line camelcase
    const { refresh_token: current_refresh_token } = yield select(
      ({ login }) => login,
    );
    // eslint-disable-next-line camelcase
    const { access_token_idp, refresh_token_idp } = yield call(
      refreshAccessToken,
      current_refresh_token,
    );
    yield put({ type: REFRESH_TOKEN._SUCCESS, access_token_idp, refresh_token_idp });
  } catch (e) {
    yield put({ type: REFRESH_TOKEN._ERROR, error: e });
  } finally {
    yield put({ type: SPINNER_CLOSE })
  }
}

function* watchRefreshAccessToken() {
  yield takeLatest(REFRESH_TOKEN._REQUEST, refreshAccessToken);
}

export default [watchLoginOnFerrari, watchAccessToken, watchRefreshAccessToken, watchConvertToken];
