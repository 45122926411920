/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';

const breakpoints = {
  mobile_min: 320,
  mobile_max: 767,
  tablet_screen_min: 768,
  tablet_screen_max: 1023,
  large_screen_min: 1024,
  large_screen_max: 1439,
  desktop_screen_min: 1440,
  desktop_large_screen_min: 1920,
};

const sizes = {};

Object.keys(breakpoints).forEach((key) => {
  sizes[key] = `${breakpoints[key]}px`;
});

const mediaqueries = {};

Object.keys(sizes).forEach((key) => {
  mediaqueries[key] = key.indexOf('min') > -1
    ? `screen and (min-width: ${sizes[key]})`
    : `screen and (max-width: ${sizes[key]})`;
});

export const windowSize = () => {
  const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
  const sizesObj = {
    S: {
      min: 320,
      max: 767,
    },
    M: {
      min: 768,
      max: 1279,
    },
    L: {
      min: 1280,
      max: 1439,
    },
    XL: {
      min: 1440,
      max: 9999,
    },
  };
  const sizeEnum = {
    S: 'S',
    M: 'M',
    L: 'L',
    XL: 'XL',
  };
  const getSize = () => {
    let newSize = sizeEnum.XL;
    Object.keys(sizesObj).forEach((key) => {
      const elem = sizesObj[key];
      if (getWidth() < elem.max && getWidth() > elem.min) {
        newSize = key;
      }
    });
    return newSize
  }
  const [size, setSize] = useState(getSize());
  useEffect(() => {
    let timeoutId = null;
    const resizeFunction = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setSize(getSize())
      }, 150);
    };
    window.addEventListener('resize', resizeFunction);
    return () => {
      window.removeEventListener('resize', resizeFunction);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { size, sizeEnum, sizesObj };
};

export const windowScroolToTop = () => {
  const getScrool = () => {
    const scroll = window.scrollY;
    return scroll
  }

  const [scroll, setScroll] = useState(getScrool());


  useEffect(() => {
    const scrollFunction = () => {
      setScroll(getScrool());
    };
    // window.addEventListener('scroll', scrollFunction);

    window.addEventListener('scroll', scrollFunction);

    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);
  return { scroll };
}

const device = {
  breakpoints,
  sizes,
  mediaqueries,
  windowSize,
  windowScroolToTop,
};

export default { device };
