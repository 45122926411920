import styled from 'styled-components';

export const ScrollToTopContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #303030;
  opacity: 0.6;
  position: fixed;
  bottom: 64px;
  z-index: 100;
  right: 25px;
  z-index: 20;

  &:hover {
    cursor: pointer;
  }

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    right: 40px;
  }

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    right: 112px;
  }

 
`;
