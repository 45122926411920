import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
${({ fullScreen, theme }) => (fullScreen
    ? css`
        height: 100%;
      `
    : css`
        height: calc(100% - ${theme.constants.header_height}px);
      `)}
  z-index: 9999;
  width: 100%;
  bottom: -100vh;
  left: 0;
  transition: 0.2s;
  ${({ active }) => active
    && css`
      bottom: 0;
    `}
  @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
      height: 100%;
      transition: bottom 0s 0.2s;
  ${({ active }) => active
    && css`
      transition: bottom 0s;
    `}
  }
`;

export const ModalOverlay = styled.div`
  position: absolute;
  opacity: 0;
  transition: 0.2s;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.mixin.rgba(theme.colors.primary.black_100, 0.8)};
  ${({ active }) => active
    && css`
      opacity: 1;
    `}
`;

export const ModalBodyContainer = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.primary.white};
  height: 100%;
  width: 100%;
  overflow-y: auto;

  @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
    transition: all 0.5s linear ;
    transform: scale(0);
    opacity: 0;
    height: 100%;
    ${({ active }) => active
      && css`
        transform: scale(1);
        opacity: 1;
        transition: all 0.5s linear ;
        transition-delay: 0.2s;
      `}
  }
`;

export const ModalBody = styled.div`
  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    height: 100%; 
  }
`;
