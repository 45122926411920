import Homepage from '../../pages/HomeCarListPage';
import CarDetail from '../../pages/CarDetailPage';
import CertificationProgress from '../../pages/CertificationProgressPage';

export const ROUTES = {
  HOMEPAGE: {
    path: 'homepage',
    ToRender: Homepage,
  },
  CAR_DETAIL: {
    path: 'car-detail',
    ToRender: CarDetail,
  },
  CERTIFICATION_PROGRESS: {
    path: 'certification-progress',
    ToRender: CertificationProgress,
  },
  START: {
    path: '',
    redirect: 'homepage',
  },
  NOMATCH: {
    path: '*',
    ToRender: Homepage,
  },
};
