import styled, { css } from 'styled-components';

export const InputSelectContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #d4d2d2;
  margin: ${({ margin }) => margin || 0};


  > select {
    width: 100%;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";

    &:focus,
    &:active {
      outline: none;
    }
  }

  > i {
    position: absolute;
    top: calc(50% - 8px);
    right: 0px;
  }

  ${({ disabled }) => disabled
    && css`
      opacity: 0.5;
      > select {
        cursor: no-drop;
      }
    `}
`;

export const SelectContainer = styled.select`
  width: 100%;
  font-family: "GT America Expanded";
  font-size: 16px;
  font-weight: 500;
  font-stretch: expanded;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #303030;

  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.label`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  touch-action: none;
`;
