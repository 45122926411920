import {
  LOGIN_ON_FERRARI,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CONVERT_TOKEN,
} from '../actions/login';

const initialState = {
  code: null,
  access_token: null,
  refresh_token: null,
  logged: false,
  gui_id: null,
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ON_FERRARI._REQUEST:
      return { ...state, code: action.code };
    case ACCESS_TOKEN._SUCCESS:
      return {
        ...state,
        access_token_idp: action.access_token_idp,
        refresh_token_idp: action.refresh_token_idp,
      };
    case CONVERT_TOKEN._SUCCESS:
      return {
        ...state,
        access_token: action.access_token,
        // gui_id: action.gui_id,
        logged: true,
      };
    case REFRESH_TOKEN._SUCCESS:
      return {
        ...state,
        access_token_idp: action.access_token_idp,
        refresh_token_idp: action.refresh_token_idp,
      };
    case ACCESS_TOKEN._ERROR:
    case LOGIN_ON_FERRARI._ERROR:
    case REFRESH_TOKEN._ERROR:
    case CONVERT_TOKEN._ERROR:
      return {
        ...state,
        error: action.error,
        logged: false,
        code: null,
        access_token: null,
        refresh_token: null,
        gui_id: null,
      };
    default:
      return state;
  }
};
