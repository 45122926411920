import { enableES5 } from 'immer';

import { app } from './app';
import { homepage } from './homepage';
import { carDetail } from './car-detail';
import { login } from './login';
import { certification } from './certification';

enableES5();

export default {
  app,
  homepage,
  carDetail,
  login,
  certification,
};
