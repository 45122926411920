import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TYPES } from './index.enum';
import Icon from '../../atoms/Icon';
import Typography from '../../atoms/Typography';
import { colorsNameKey } from '../../const/theme.const';
import { WrapperContainer, FakeCursor } from './index.style';

const MouseContainerWrapper = ({
  type,
  children,
  label,
  labelColor,
  icon,
  iconColor,
  iconOnLeft,
  iconOnRight,
  iconOnLeftColor,
  iconOnRightColor,
  backgroundIcon,
  backgroundIconOnRight,
  backgroundIconOnLeft,
  fullWidth,
  atWhichSection,
  offset,
  offsetRight,
  offsetLeft,
  disable,
  hasBoxGrayOverflow,
}) => {
  const wrapper = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [currentCoords, setCurrentCoords] = useState({ x: -1, y: -1 });
  const [cursorVisible, setCursorVisible] = useState(false);
  const [currentIcon, setCurrentIcon] = useState(icon);
  const [currentBackground, setCurrentBackground] = useState(backgroundIcon);
  const [currentIconColor, setCurrentIconColor] = useState(iconColor);

  useEffect(() => {
    switch (type) {
      case TYPES.SECTORIZED:
        setCurrentIcon(
          wrapperWidth * atWhichSection > currentCoords.offsetX
            ? iconOnLeft || icon
            : iconOnRight || icon,
        );
        setCurrentBackground(
          wrapperWidth * atWhichSection > currentCoords.offsetX
            ? backgroundIconOnLeft || backgroundIcon
            : backgroundIconOnRight || backgroundIcon,
        );
        setCurrentIconColor(
          wrapperWidth * atWhichSection > currentCoords.offsetX
            ? iconOnLeftColor || iconColor
            : iconOnRightColor || iconColor,
        );
        break;
      case TYPES.FULL:
      default:
        setCurrentIcon(icon);
        setCurrentBackground(backgroundIcon);
        break;
    }
  }, [
    backgroundIcon,
    backgroundIconOnLeft,
    backgroundIconOnRight,
    currentCoords,
    icon,
    iconOnLeft,
    iconOnRight,
    type,
    wrapperWidth,
    atWhichSection,
    currentIconColor,
    iconOnLeftColor,
    iconColor,
    iconOnRightColor,
  ]);

  const getPercentualOffset = (
    offsetObject,
    direction,
    measure = wrapperWidth,
    hasToDivide = true,
  ) => (measure
      * (offsetObject[direction]
        / (type === TYPES.SECTORIZED && hasToDivide ? 2 : 1)))
    / 100;

  useEffect(() => {
    setWrapperWidth(wrapper.current.offsetWidth);
  }, [wrapper, type, fullWidth]);

  const checkOffsetBox = (x, y) => {
    const sectorWrapperWidth = wrapperWidth * atWhichSection;
    let toReturn = false;
    switch (type) {
      case TYPES.SECTORIZED:
        toReturn = ((x
            > (getPercentualOffset(offsetLeft, 'left')
              || getPercentualOffset(offset, 'left'))
            && x
              < sectorWrapperWidth
                - (getPercentualOffset(offsetLeft, 'right')
                  || getPercentualOffset(offset, 'right')))
            || (x
              > (getPercentualOffset(offsetRight, 'left')
                || getPercentualOffset(offset, 'left'))
                + sectorWrapperWidth
              && x
                < wrapperWidth
                  - (getPercentualOffset(offsetRight, 'right')
                    || getPercentualOffset(offset, 'right'))))
          && y
            > getPercentualOffset(offset, 'top', wrapper.current.offsetHeight)
          && y
            < wrapper.current.offsetHeight
              - getPercentualOffset(
                offset,
                'bottom',
                wrapper.current.offsetHeight,
                false,
              );
        break;
      case TYPES.FULL:
      default:
        toReturn = x > getPercentualOffset(offset, 'left')
          && x < wrapperWidth - getPercentualOffset(offset, 'right')
          && y
            > getPercentualOffset(offset, 'top', wrapper.current.offsetHeight)
          && y
            < wrapper.current.offsetHeight
              - getPercentualOffset(
                offset,
                'bottom',
                wrapper.current.offsetHeight,
                false,
              );
    }
    return toReturn;
  };

  return (
    <WrapperContainer
      hasBoxGrayOverflow={hasBoxGrayOverflow ? window.innerWidth : null}
      ref={wrapper}
      fullWidth={fullWidth}
      onMouseOver={() => !disable && setCursorVisible(true)}
      onMouseLeave={() => {
        if (!disable) {
          setCursorVisible(false);
          setCurrentCoords({ x: -100, y: -100 });
        }
      }}
      onMouseMove={(event) => {
        const x = event.clientX;
        const y = event.clientY;
        if (
          cursorVisible
          && checkOffsetBox(
            x - wrapper.current.getBoundingClientRect().left,
            y - wrapper.current.getBoundingClientRect().top,
          )
        ) {
          setCurrentCoords({
            x,
            y,
            offsetX: x - wrapper.current.offsetLeft,
          });
        } else {
          setCurrentCoords({ x: -100, y: -100 });
        }
      }}
    >
      {!disable && cursorVisible && (
        <FakeCursor
          background={currentBackground}
          x={currentCoords.x}
          y={currentCoords.y}
          labelColor={labelColor}
          className="fake-cursor"
        >
          <Icon icon={currentIcon} color={currentIconColor} size={20} />
          {label && (
            <Typography
              tag="p"
              type={Typography.TYPES.SUBTITLE_LITTLER}
              style={Typography.STYLES.DEFAULT}
            >
              {label}
            </Typography>
          )}
        </FakeCursor>
      )}
      {children}
    </WrapperContainer>
  );
};

MouseContainerWrapper.TYPES = TYPES;

MouseContainerWrapper.defaultProps = {
  label: null,
  icon: null,
  iconOnLeft: null,
  iconOnRight: null,
  labelColor: colorsNameKey.WHITE,
  backgroundIcon: colorsNameKey.WHITE,
  iconColor: colorsNameKey.BLACK_90,
  iconOnLeftColor: colorsNameKey.WHITE,
  iconOnRightColor: colorsNameKey.WHITE,
  backgroundIconOnRight: colorsNameKey.ROSSO_CORSA,
  backgroundIconOnLeft: colorsNameKey.ROSSO_CORSA,
  fullWidth: false,
  hasBoxGrayOverflow: false,
  atWhichSection: 0.5,
  offset: {
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
  },
  offsetRight: {
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
  },
  offsetLeft: {
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
  },
  disable: false,
};

MouseContainerWrapper.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  atWhichSection: PropTypes.number,
  fullWidth: PropTypes.bool,
  hasBoxGrayOverflow: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(Icon.ICONS_TYPE)),
  iconOnLeft: PropTypes.oneOf(Object.values(Icon.ICONS_TYPE)),
  iconOnRight: PropTypes.oneOf(Object.values(Icon.ICONS_TYPE)),
  iconColor: PropTypes.oneOf(Object.values(colorsNameKey)),
  iconOnLeftColor: PropTypes.oneOf(Object.values(colorsNameKey)),
  iconOnRightColor: PropTypes.oneOf(Object.values(colorsNameKey)),
  labelColor: PropTypes.oneOf(Object.values(colorsNameKey)),
  backgroundIcon: PropTypes.oneOf(Object.values(colorsNameKey)),
  backgroundIconOnRight: PropTypes.oneOf(Object.values(colorsNameKey)),
  backgroundIconOnLeft: PropTypes.oneOf(Object.values(colorsNameKey)),
  offset: PropTypes.shape({
    right: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
  }),
  offsetRight: PropTypes.shape({
    right: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }),
  offsetLeft: PropTypes.shape({
    right: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }),
  disable: PropTypes.bool,
};

export default MouseContainerWrapper;
