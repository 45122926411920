import styled, { css } from 'styled-components';

export const LoaderContainer = styled.div`
    position: fixed;
    z-index: 950;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;

    ${({ stopAnimation }) => stopAnimation
    && css`
      opacity: 0;
      transition: all 0.5s linear;
      z-index: -1;
    `}
`;

export const SvgContainer = styled.div`
    opacity: 0;
    animation: LoaderFerrari ${({ animationSpeed }) => animationSpeed}s ease-out infinite;

    svg {
      fill:#fff;
      width:68px;
      height:94px
    }
`;
