import styled, { css } from 'styled-components';

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid ${({ theme }) => (theme.colors.primary.blacK_100)};
`;

export const AccordionHeader = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0;
  background-color: transparent;
  padding: 23px 11px 24px 0;
  
  &:focus, &:active {
    outline: none;
  }
 
  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    padding: 13px 16px 38px 0;
  }

  > p {
    text-align: left;
  }

  > i {
    transform: rotate(0deg);
    transition: all 0.2s linear;

    ${({ open }) => open
    && css`
      transform: rotate(-45deg);
    `}
  }
`;

export const AccordionBody = styled.div`
  width: 100%;
  max-height: 0;
  overflow: hidden;
  padding-bottom: 0;
  position: relative;
  top: -20px;
  transition: all 0.2s ease-out;


  ${({ open }) => open
    && css`
      max-height: 100%;
      padding-bottom: 26px;
      top: 0px;
      transition: all 0.1s ease-in;
      overflow-x: visible;
      overflow-y: visible;
  `}

  
`;

export const AccordionBodyListItemContainer = styled.li`
  width: 100%;
  padding: 30px 16px;
  border-bottom: 1px solid #8f8f8f;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;

  span {
    margin-top: 10px
  }

  @media ${(props) => (props.theme.device.mediaqueries.large_screen_min)} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      margin-top: 0px
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }

   p {
    text-transform: uppercase;
  }
`;
