/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import { connect } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { push } from 'redux-first-history';
import Container from '../../atoms/Grid/Container';
import {
  CertificationProgressContainer,
  HeadingContainer,
  DropdownListContainer,
  SectorTabContainer,
  CarouselContainer,
} from './index.style';
// import STUB from './stub.json';
import TextIconContainer from '../../atoms/TextIconContainer';
import Stepper from '../../components/Stepper';
import Accordion from '../../components/Accordion';
import { TRANSLATION_KEYS } from '../../i18n/index.enum';
import Icon from '../../atoms/Icon';
import Typography from '../../atoms/Typography';
import { TYPES } from '../../atoms/Typography/index.enum';
import SectorTab from '../../components/SectorTab';
import { ROUTES_URL } from '../../components/RouterContainer/index.routes';
import Col from '../../atoms/Grid/Col';
import Row from '../../atoms/Grid/Row';
import MouseContainerWrapper from '../../components/MouseContainerWrapper';
import Carousel from '../../components/Carousel';
import ScrollToTopButton from '../../components/ScrollToTopButton';
import Questionnaire from '../../components/Questionnaire';
import { colorsNameKey } from '../../const/theme.const';
import PhotoCardElementWithTitle from '../../components/Carousel/Elements/PhotoCardElementWithTitle';
import { GET_CERTIFICATION_DETAILS } from '../../redux/actions/certification';
import { windowSize } from '../../ui/theme/device';
import { getQueryVariable } from '../../utils/query';
import ModalCarousel from '../../components/ModalCarousel';
import PhotoCardElementFullView from '../../components/Carousel/Elements/PhotoCardElementFullView';

const RenderAccordion = ({
  gallery,
  groupName,
  questionnaire,
  index: accordionIndex,
  size,
}) => {
  const [accordionState, setAccordionState] = useState(accordionIndex === 0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t } = useTranslation();

  const [fullViewCarousel, setfullViewCarousel] = useState(false);
  const [indexFullViewCarousel, setIndexFullViewCarousel] = useState(0);

  useEffect(() => {
    setCurrentSlide(0);
  }, [accordionState]);

  const getCarouselProps = (currentSize) => {
    let carouselProps = {};
    switch (currentSize) {
      case 'S':
        carouselProps = {
          howManyDotsToSee: 3,
          sectionVisible: 0.9,
          type: Carousel.TYPES.SEQUENTIAL,
        };
        break;
      case 'M':
        carouselProps = {
          howManyDotsToSee: 5,
          sectionVisible: 0.6,
          type: Carousel.TYPES.SEQUENTIAL,
        };
        break;
      default:
        carouselProps = {
          howManyDotsToSee: -1,
          type: Carousel.TYPES.SEPARATED,
        };
        break;
    }
    return carouselProps;
  };

  const themeContext = useContext(ThemeContext);

  const CarouselInner = (
    <>
      {themeContext.device.windowScroolToTop().scroll > (window.innerHeight / 2) ? (
        <ScrollToTopButton />
      ) : null}

      <CarouselContainer size={size}>
        <Carousel
          dots
          autoplay={accordionState ? 3 : 0}
          visiblePerPage={3}
          currentSlide={currentSlide}
          onSlideChange={(index) => {
            setCurrentSlide(index);
          }}
          dotsColor={colorsNameKey.BLACK_90}
          {...getCarouselProps(size)}
        >
          {gallery.map(({ view_name: viewName, url_photo: urlPhoto }, index) => (
            <PhotoCardElementWithTitle
              key={index}
              image={urlPhoto}
              label={viewName}
              icon={Icon.ICONS_TYPE.SCREEN}
              onIconClick={() => {
                setIndexFullViewCarousel(index);
                setfullViewCarousel(true);
              }}
            />
          ))}
        </Carousel>
      </CarouselContainer>
      <ModalCarousel
        active={fullViewCarousel}
        fullScreen
        padding="0 25px"
        close={() => {
          setfullViewCarousel(false);
        }}
      >
        <MouseContainerWrapper
          type={MouseContainerWrapper.TYPES.FULL}
          icon={Icon.ICONS_TYPE.SIDE_CHEVRON}
          iconColor={colorsNameKey.BLACK_90}
          fullWidth
          fromParent
          offset={{
            right: 10,
            left: 10,
            top: 10,
            bottom: 10,
          }}
          backgroundIcon={colorsNameKey.WHITE}
          disable={size === 'S'}
        >
          <Carousel
            isInFullView
            dots
            drag
            dotsColor={colorsNameKey.WHITE}
            ringColor={colorsNameKey.WHITE}
            arrows={size === 'XL'}
            arrowColors={colorsNameKey.WHITE}
            dotsAreInAbsolute
            clickToGoNext
            currentSlide={indexFullViewCarousel}
            sectionVisible={1}
          >
            {gallery.map(({ url_photo: urlPhoto }, index) => (
              <PhotoCardElementFullView
                key={index}
                image={urlPhoto}
                alt=""
                hasFullView
              />
            ))}
          </Carousel>
        </MouseContainerWrapper>
      </ModalCarousel>
    </>
  );

  return (
    <Accordion
      text={t(
        TRANSLATION_KEYS.CERTIFICATION_PROGRESS.ACCORDIONS.TITLES[
          groupName.toUpperCase()
        ],
      )}
      onToggle={(state) => {
        setAccordionState(state);
      }}
      open={accordionIndex === 0}
    >
      {gallery && gallery.length > 0 && (
        <MouseContainerWrapper
          disable={size === 'S'}
          type={MouseContainerWrapper.TYPES.SECTORIZED}
          iconOnLeft={Icon.ICONS_TYPE.CHEVRON_LEFT}
          iconOnRight={Icon.ICONS_TYPE.CHEVRON_RIGHT}
          atWhichSection={0.5}
          fullWidth
          hasBoxGrayOverflow
          offsetRight={{ left: 50, right: 0 }}
          offsetLeft={{ left: 0, right: 50 }}
        >
          {CarouselInner}
        </MouseContainerWrapper>
      )}
      {questionnaire.map((element, i) => (
        <Questionnaire
          key={i}
          question={element.quest_text}
          answer={element.answer}
          textAnswer={
            element.answer_type !== null
            && element.answer_type !== 'null'
            && element.answer_type.toString() === 'Libera'
          }
        />
      ))}
    </Accordion>
  );
};

const CertificationProgress = ({
  pushUrl,
  currentCertification,
  getCertificationDetails,
}) => {
  const { t } = useTranslation();
  const id = getQueryVariable('vin');
  const { size } = windowSize();

  useEffect(() => {
    getCertificationDetails({ vin: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [steps] = useState([
    {
      onClick: () => null,
      label: t(TRANSLATION_KEYS.CERTIFICATION_PROGRESS.STEPS.STEP_0),
      id: 'opened_case',
      active:
        !currentCertification.is_cert_completed
        && (Number(currentCertification.cert_step) === 0
          || Number(currentCertification.cert_step) === '0'),
      disabled: false,
    },
    {
      onClick: () => null,
      label: t(TRANSLATION_KEYS.CERTIFICATION_PROGRESS.STEPS.STEP_1),
      id: 'completing_inspect',
      active:
        !currentCertification.is_cert_completed
        && (Number(currentCertification.cert_step) === 1
          || Number(currentCertification.cert_step) === '1'),
      disabled: false,
    },
    {
      onClick: () => null,
      label: t(TRANSLATION_KEYS.CERTIFICATION_PROGRESS.STEPS.STEP_2),
      id: 'completed_analysis',
      active:
        !currentCertification.is_cert_completed
        && (Number(currentCertification.cert_step) === 2
          || Number(currentCertification.cert_step) === '2'),
      disabled: false,
    },
    {
      onClick: () => null,
      label: t(TRANSLATION_KEYS.CERTIFICATION_PROGRESS.STEPS.STEP_3),
      id: 'certified_vehicle',
      active: currentCertification.is_cert_completed,
      disabled: false,
    },
  ]);

  return (
    (currentCertification
      && currentCertification[Object.keys(currentCertification)[0]] && (
        <Container>
          <CertificationProgressContainer>
            <HeadingContainer>
              <TextIconContainer
                onClick={() => {
                  pushUrl(ROUTES_URL.car_detail(id));
                }}
                icon={Icon.ICONS_TYPE.CHEVRON_LEFT}
              >
                {t(TRANSLATION_KEYS.CERTIFICATION_PROGRESS.BACK_BUTTON)}
              </TextIconContainer>
              <Typography
                tag="h3"
                type={TYPES.HEADLINE}
                style={Typography.STYLES.DEFAULT}
              >
                {currentCertification.model_name}
              </Typography>
              <Typography
                tag="p"
                type={TYPES.BODY}
                style={Typography.STYLES.DEFAULT}
              >
                {t(TRANSLATION_KEYS.CERTIFICATION_PROGRESS.DESCRIPTION)}
              </Typography>
              <Row>
                {currentCertification.top_info.map(({ value }, i) => (
                  <Col xs="6" sm="4" md="2" key={i}>
                    <SectorTabContainer>
                      <SectorTab
                        label={t(
                          TRANSLATION_KEYS.CERTIFICATION_PROGRESS.SECTIONS[
                            `SECTION_${i}`
                          ],
                        )}
                        value={
                          value
                            ? (typeof value === 'string'
                                || typeof value === 'number')
                              && value.toString().length === 13
                              && value.toString().match(/^\d+$/)
                              ? dayjs(Number(value)).format(
                                t(TRANSLATION_KEYS.COMMON.DATE_FORMAT),
                              )
                              : TRANSLATION_KEYS.CERTIFICATION_PROGRESS
                                .TOP_INFO[value.toUpperCase()]
                                ? t(
                                  TRANSLATION_KEYS.CERTIFICATION_PROGRESS
                                    .TOP_INFO[value.toUpperCase()],
                                )
                                : value
                            : null
                        }
                        type={
                          i === 0
                            ? SectorTab.TYPES.BOLDED
                            : SectorTab.TYPES.NORMAL
                        }
                      />
                    </SectorTabContainer>
                  </Col>
                ))}
              </Row>
              {!currentCertification.is_cert_completed && (
                <Stepper
                  steps={steps}
                  type={
                    size === 'S'
                      ? Stepper.TYPES.SINGLE_LABEL
                      : Stepper.TYPES.DEFAULT
                  }
                />
              )}
            </HeadingContainer>
            <DropdownListContainer>
              {currentCertification.is_cert_completed
                && currentCertification.certification_info.map(
                  ({ group_name: groupName, gallery, questionnaire }, i) => (
                    <li key={i}>
                      <RenderAccordion
                        index={i}
                        size={size}
                        questionnaire={questionnaire}
                        groupName={groupName}
                        gallery={gallery}
                      />
                    </li>
                  ),
                )}
            </DropdownListContainer>
          </CertificationProgressContainer>
        </Container>
    ))
    || null
  );
};

export default connect(
  ({ certification: { currentCertification } }) => ({ currentCertification }),
  (dispatch) => ({
    pushUrl: (url) => dispatch(push(url)),
    getCertificationDetails: (payload) => dispatch({
      type: GET_CERTIFICATION_DETAILS._REQUEST,
      payload,
    }),
  }),
)(CertificationProgress);
