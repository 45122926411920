import styled, { css } from 'styled-components';

export const LoginHeaderContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 19px;
  z-index: 25;

  > a {
    text-decoration: none;
    color: white;

    &:hover {
      cursor: pointer
    }
  }


  ${({ userLogged }) => userLogged
    && css`
      top: 10px;
      right: 24px;

      @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
        right: 39px;
      }

      @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
        right: 46px;
      }
  `}
`;
