import styled from 'styled-components';

export const HeroImagePreviewContainer = styled.div`
  position: relative;
  min-height: 600px;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    min-height: 623px;
  }

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    min-height: 100%;
  }

  img {
    width: 100%;
    object-fit: cover;
    min-height: 300px;
  }
`;

export const VehicleMask = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to top,
    ${({ theme }) => theme.colors.primary.black_100} 50%,
    ${({ theme }) => theme.colors.primary.transparent} 76%
  );

@media ${({ theme }) => theme.device.mediaqueries.tablet_screen_min} {
  background-image: linear-gradient(to top, ${({ theme }) => theme.colors.primary.black_100} 35%, ${({ theme }) => theme.colors.primary.transparent} 65%);
}

@media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
  background-image: linear-gradient(to top, ${({ theme }) => theme.colors.primary.black_100}, ${({ theme }) => theme.colors.primary.transparent} 50%);
}
`;

export const CarMainInfo = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 52%;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    top: 50%;
  }

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    top: 57%;
  }
`;

export const Status = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    margin-top: 20px;
  }

  > p {
    text-align: center;
  }

  > button {
    margin: auto;
  }
`;

export const StatusContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 26px;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    &:first-child {
      margin-top: 56px;
    }
  }

  > * {
    max-width: 258px;
    > * {
      width: 100%;
    }
  }
`;
