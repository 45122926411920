import styled from 'styled-components';

export const CarDetailContainer = styled.div`
  width: 100%;
`;

export const CarCarouselContainer = styled.div`
  width: 100%;
`;

export const VehicleInfoLabelContainer = styled.div`
  margin-top: 54px;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    margin-top: 64px;
  }
`;

export const VehicleInfoContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  margin-top: 6px;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    margin-top: 16px;
  }
`;

export const DetailVehicleContainer = styled.div`
  width: 100%;
  margin-top: 64px;
  margin-bottom: 57px; 

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    margin-top: 96px;
    margin-bottom: 26px; 
  }
`;
