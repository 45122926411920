import React from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer } from './index.style';
import { TYPES, ICON_POSITION } from './index.enum';
import Typography from '../Typography';
import Icon from '../Icon';
import ICONS_TYPE from '../Icon/index.enum';

const Button = ({
  children, type, icon, iconPosition, iconSize, onClick, disabled, fullWidth,
}) => (
  <ButtonContainer
    fullWidth={fullWidth}
    iconPosition={iconPosition}
    disabled={disabled}
    onClick={onClick}
    type={type}
  >
    {icon ? <Icon icon={icon} size={iconSize} /> : null}
    <Typography
      tag="p"
      type={Typography.TYPES.CAPTION}
      style={Typography.STYLES.DEFAULT}
    >
      {children}
    </Typography>
  </ButtonContainer>
);

Button.TYPES = TYPES;
Button.ICON_POSITION = ICON_POSITION;
Button.ICONS_TYPE = ICONS_TYPE;

Button.defaultProps = {
  iconPosition: null,
  iconSize: 16,
  onClick: () => null,
  disabled: false,
  icon: null,
  fullWidth: false,
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.oneOf(Object.values(ICON_POSITION)),
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.oneOf(Object.values(ICONS_TYPE)),
  fullWidth: PropTypes.bool,
  iconSize: PropTypes.number,
};

export default Button;
