import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
${({ fullScreen, theme }) => (fullScreen
    ? css`
        height: 100%;
      `
    : css`
        height: calc(100% - ${theme.constants.header_height}px);
      `)}
  z-index: 9999;
  width: 100%;
  bottom: -100vh;
  left: 0;
  transition: 0.2s;
  ${({ active }) => active
    && css`
      bottom: 0;
    `}
  @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
      height: 100%;
      transition: bottom 0s 0.2s;
  ${({ active }) => active
    && css`
      transition: bottom 0s;
    `}
  }
`;

export const ModalOverlay = styled.div`
  position: absolute;
  opacity: 0;
  transition: 0.2s;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.mixin.rgba(theme.colors.primary.black_100, 0.8)};
  ${({ active }) => active
    && css`
      opacity: 1;
    `}
`;

export const ModalBodyContainer = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.primary.white};
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: ${({ padding }) => padding};

  @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
    transition: 0.2s;
    transform: translateX(70%);
    height: 100%;
    ${({ active }) => active
      && css`
        transform: translateX(0);
      `}
  }

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    width: 70%;
    max-width: 810px;
    margin-left: auto;
  }
`;

export const ModalClose = styled.div`
  ${({ mobile }) => mobile
    && css`
      display: flex;
    `}
  ${({ desktop }) => desktop
    && css`
      display: none;
    `}
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 48px;
  
  > button {
    height: 40px;
    width: 40px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.mixin.rgba(theme.colors.primary.black_90, 0.6)};
    border: none;
    outline: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
  
    ${({ desktop }) => desktop
      && css`
        display: flex;
        z-index: 2;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 20%;
        > button {
          background-color: ${({ theme }) => theme.mixin.rgba(theme.colors.primary.black_05, 0.8)};
        }
      `}
  }

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    ${({ mobile }) => mobile
      && css`
        display: none;
      `}
  }
`;

export const ModalBody = styled.div`
  height: calc(100% - 112px);

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    height: 100%; 
  }
`;
