import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_KEYS } from '../../../../i18n/index.enum';
import {
  InputSearchModalHomeFilterContainer,
  ModalHomeButtonContainer,
} from './index.style';
import Typography from '../../../../atoms/Typography'
import { colorsNameKey } from '../../../../const/theme.const';
import TextInput from '../../../../atoms/TextInput'
import ICONS_TYPE from '../../../../atoms/Icon/index.enum';
import Col from '../../../../atoms/Grid/Col';
import Row from '../../../../atoms/Grid/Row';
import InputSelect from '../../../../atoms/InputSelect';
import Button from '../../../../atoms/Button';


const ModalBodyHomepage = ({
  filter, activeFilters,
  onFiltersChange,
  yearProduction,
  onYearFilterChange,
  searchText,
  onSearchChange,
  onSubmit,
}) => {
  const [currentActiveFilters, setCurrentActiveFilters] = useState(activeFilters)
  const [currentYearFilter, setCurrentYearFilter] = useState(yearProduction)
  const [search, setSearch] = useState(searchText)

  useEffect(() => {
    onSearchChange(search);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    onFiltersChange(currentActiveFilters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveFilters])

  useEffect(() => {
    onYearFilterChange(currentYearFilter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveFilters])

  const { t } = useTranslation();
  return (
    <>
      <Typography
        tag="p"
        type={Typography.TYPES.TITLE}
        color={colorsNameKey.BLACK_90}
      >
        {t(TRANSLATION_KEYS.HOMEPAGE.MODAL.TITLE)}
      </Typography>
      <InputSearchModalHomeFilterContainer>
        <TextInput
          id="searcHome"
          placeholder="Telaio"
          value={search}
          icon={ICONS_TYPE.SEARCH}
          type={TextInput.TYPES.SEARCH.name}
          onChange={({ target: { value } }) => {
            setSearch(value)
          }}
        />
      </InputSearchModalHomeFilterContainer>
      <Typography
        tag="p"
        type={Typography.TYPES.TITLE}
        color={colorsNameKey.BLACK_90}
      >
        {t(TRANSLATION_KEYS.HOMEPAGE.MODAL.SUBTITLE)}
        { activeFilters > 0
          ? ` (${activeFilters})` : null }
      </Typography>
      <Row>

        {filter.map((element) => (
          <Col xs="12" sm="12" md="6" xl="3" lg="3" key={element.id}>
            <InputSelect
              margin="32px 0px 0px 0px"
              icon={ICONS_TYPE.CHEVRON_DOWN}
              disabled={element.disabled}
              selectOption={element.option}
              onChange={({ target: { value } }) => {
                setCurrentActiveFilters((state) => ({ ...state, [element.id]: value }))
              }}
            />
          </Col>
        ))}
        <Col xs="12" sm="12" md="6" xl="3" lg="3">
          <TextInput
            margin="32px 0px 0px 0px"
            inputType="number"
            max={9999}
            value={currentYearFilter}
            placeholder={t(TRANSLATION_KEYS.HOMEPAGE.anno_prod)}
            type={TextInput.TYPES.SEARCH.name}
            onChange={({ target: { value } }) => {
              setCurrentActiveFilters((state) => ({ ...state, yearProduction: Number(value) }))
              setCurrentYearFilter(value)
            }}
          />
        </Col>
      </Row>
      <ModalHomeButtonContainer>
        <Button type={Button.TYPES.MAIN_RED} onClick={onSubmit}>
          {t(TRANSLATION_KEYS.HOMEPAGE.MODAL.APPLY_FILTERS)}
          {Object.keys(activeFilters).length > 0 ? `
            (${Object.keys(activeFilters).length})` : null }
        </Button>
        <Button
          type={Button.TYPES.SECONDARY}
          onClick={() => {
            setCurrentActiveFilters(() => ({}))
            setCurrentYearFilter()
            setSearch()
          }}
          disabled={(((search === undefined) || (search === null)) && (Object.keys(currentActiveFilters).length === 0))}

        >
          {t(TRANSLATION_KEYS.HOMEPAGE.MODAL.RESET_FILTERS)}
        </Button>
      </ModalHomeButtonContainer>
    </>
  )
};


ModalBodyHomepage.defaultProps = {
  filter: null,
  activeFilters: {},
  onFiltersChange: () => null,
  yearProduction: null,
  onYearFilterChange: () => null,
  onSearchChange: () => null,
  onSubmit: () => null,
  searchText: null,
};

ModalBodyHomepage.propTypes = {
  filter: PropTypes.string,
  activeFilters: PropTypes.objectOf(PropTypes.string),
  onFiltersChange: PropTypes.func,
  yearProduction: PropTypes.number,
  onYearFilterChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSubmit: PropTypes.func,
  searchText: PropTypes.string,
};

export default ModalBodyHomepage;
