import styled, { css } from 'styled-components';

export const HomePageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: ${({ theme }) => theme.constants.header_margin}px;
  margin-bottom: ${({ theme }) => theme.constants.header_margin}px;
`;

export const MaxWidthDiv = styled.div`
  max-width: 100%;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    max-width: ${({ maxWidth }) => maxWidth};
  }
`;

export const HomePageTitleContainer = styled.div`
  > h1 {
    margin-top: 32px;

    @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
      margin-top: 50px;
    }

    @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
      margin-top: 64px;
    }
  }
`;

export const HomePageSubtitleContainer = styled.div`
  > div {
    margin-top: 24px;
    margin-bottom: 24px;

    @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
      margin-top: 32px;
      margin-bottom: 0px;
    }
  }
`;

export const ContainerFilters = styled.div`
  width: 100%;
`;

export const FilterSelectSection = styled.div`
  width: 100%;
  display: ${({ displayNone }) => (displayNone ? 'none' : null)};
`;

export const FilterButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
`;

export const ButtonSecondaryContainer = styled.div`
  > button {

    @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
      min-width: 230px;
    } 

    @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
      min-width: 248px;
    }
  }
`;

export const ButtonMainRedContainer = styled.div`
    flex: 1 0 auto;

  > button {
    width: 100%;
    max-width: 210px;
    margin-left: auto;

    @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
      min-width: 210px;
    }

    @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
      min-width: 226px;
    }
  }
`;

export const LineDivider = styled.div`
  background-color: #303030;
  margin-top: 24px;
  height: 1px;
  width: 100%;
`;

export const InputSearchModalHomeFilterContainer = styled.div`
  margin-top: 48px;
  margin-bottom: 24px;
`;

export const ModalHomeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  margin-bottom: 32px;

  button {
    &:first-child {
      margin-bottom: 24px;
    }
  }
`;

export const IconFilterMobile = styled.div`
  position: absolute;
  right: 0;
  top: -5px;
  width: 24px;
  height: 24px;

  > i {
    width: 100%;
    height: 100%;
  }

  ${({ alert }) => alert
    && css`
      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        font-size: 24px;
        top: -1px;
        left: -1px;
        background-color: #e02020;
        border-radius: 50%;
        border: 1px solid white;
      }
    `}
`;

export const VievMoreSection = styled.div`
  width: 100%;
  margin-bottom: 32px;
  margin-top: 32px;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    margin-bottom: 64px;
    margin-top: 48px;
  }

  > button {
    margin: auto;
  }
`;

export const NoResultContainer = styled.div`
  width: 100%;  
  margin: auto;
  margin-top: 127px;
  text-align: center;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    margin-top: 140px;
  }

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    margin-top: 177px;
  }
`;

export const FilterButtonSectionLeft = styled.div`
  display: flex;
  margin-right: 30px;


  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    margin-right: 0;
  }

  & > div {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
