import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { InputContainer, Input } from './index.style';
import Typography from '../Typography';
import ICONS_TYPE from '../Icon/index.enum';
import { TYPES } from './index.enum';
import { UUIDGenerator } from '../../utils/id';
import TypographyStyle from '../Typography/index.style';
import Icon from '../Icon';

const TextInput = ({
  id,
  placeholder,
  value,
  icon,
  type,
  fullWidth,
  onBlur,
  onChange,
  onInput,
  onFocus,
  onClick,
  onIconClick,
  inputType,
  margin,
  max,
  min,
}) => {
  const isUpdating = useRef(false)
  const [currentValue, setCurrentValue] = useState(value || '');
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setCurrentValue(value);
    isUpdating.current = true;
  }, [value]);

  const handleChange = (e) => {
    if (!isUpdating.current) {
      const val = e.target.value;
      const maxLenght = max;
      const maxLengthText = maxLenght.toString().length;
      // eslint-disable-next-line radix
      const newVal = val <= maxLenght ? val : parseInt(val.toString().substring(0, maxLengthText))
      onChange({ target: { value: newVal } })
      // setCurrentValue(newVal);
    } else {
      isUpdating.current = false;
    }
  }

  return (
    <InputContainer
      fullWidth={fullWidth}
      focused={focused}
      hasValue={currentValue}
      hasLabel={placeholder && TYPES[type].showPlaceholderAlways}
      margin={margin}
    >
      {(focused
        ? TYPES[type].showPlaceholderAlways
        : currentValue?.length > 0
          ? TYPES[type].showPlaceholderAlways
          : true) && (
          <label htmlFor={id}>
            <Typography
              tag="p"
              type={TYPES[type].type}
              style={TYPES[type].style}
              color={TYPES[type].color.placeholder}
            >
              {placeholder}
            </Typography>
          </label>
      )}
      <Input
        type={inputType}
        hasIcon={icon}
        styleType={TypographyStyle[TYPES[type].type][TYPES[type].style]}
        color={TYPES[type].color.value}
        value={currentValue}
        onChange={(ev) => {
          if (!isUpdating.current) {
            // setCurrentValue(ev?.target?.value);
            onChange(ev);
            if (inputType === 'number') {
              handleChange(ev);
            }
          } else {
            isUpdating.current = false;
          }
        }}
        onInput={(ev) => {
          if (!isUpdating.current) {
            // setCurrentValue(ev?.target?.value);
            onInput(ev);
            if (inputType === 'number') {
              handleChange(ev);
            }
          } else {
            isUpdating.current = false;
          }
        }}
        onBlur={(ev) => {
          setFocused(false);
          onBlur(ev);
        }}
        onFocus={(ev) => {
          setFocused(true);
          onFocus(ev);
        }}
        onClick={onClick}
        id={id}
        maxLength={max}
        minLength={min}
      />
      {icon && (
        <Icon onClick={() => onIconClick(currentValue)} icon={icon} size={16} />
      )}
    </InputContainer>
  );
};

TextInput.TYPES = TYPES;

TextInput.defaultProps = {
  id: UUIDGenerator('input-text'),
  placeholder: null,
  value: '',
  icon: null,
  type: TYPES.FORM,
  onBlur: () => null,
  onChange: () => null,
  onInput: () => null,
  onFocus: () => null,
  onClick: () => null,
  onIconClick: () => null,
  fullWidth: false,
  inputType: 'text',
  margin: null,
  max: null,
  min: null,
};

TextInput.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.oneOf(Object.values(ICONS_TYPE)),
  type: PropTypes.oneOf(Object.values(TYPES).map(({ name }) => name)),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onIconClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  inputType: PropTypes.string,
  margin: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
};

export default TextInput;
