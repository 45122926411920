const ICONS_TYPE = {
  REMOVE: 'ferrari-icon-black-1',
  CHEVRON_LEFT: 'ferrari-icon-black-2',
  CHEVRON_RIGHT: 'ferrari-icon-black-3',
  CHEVRON_DOWN: 'ferrari-icon-black-4',
  CHEVRON_UP: 'ferrari-icon-black-5',
  LOGO: 'ferrari-icon-black-6',
  SEARCH: 'ferrari-icon-black',
  CLOSE: 'ferrari-icon-close',
  ARROW_LEFT: 'ferrari-icon-combined-shape-1',
  ARROW_RIGHT: 'ferrari-icon-combined-shape-copy',
  SCREEN: 'ferrari-icon-combined-shape',
  SIDE_CHEVRON: 'ferrari-icon-group-3',
  USER: 'ferrari-icon-group-7',
  DOWNLOAD: 'ferrari-icon-group-24',
  SHARE: 'ferrari-icon-group-25',
  PLUS: 'ferrari-icon-path',
  SETTTINGS: 'ferrari-icon-black1',
  SCROLLUP: 'ferrari-icon-arrowTop',
};

export default ICONS_TYPE;
