export const THEME_TYPES = {
  DARK: {
    themeName: 'DARK',
    textColor: 'white',
  },
  WHITE: {
    themeName: 'WHITE',
    textColor: 'black_90',
  },
};
