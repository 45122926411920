import styled, { css } from 'styled-components';

export const ImageCardContainer = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;

  ${({ hasFullView }) => hasFullView
    && css`
      height: 100vh;
      position: relative;
      top: 50%;
      margin-top: auto;
      transform: translateY(-50%);
  `}
  > img {
    transition: 0.2s;
    width: 100%;
  }
  &:hover {
    > img {
      transform: scale(1.05);
      transform-origin: center center;
    }
  }
`;

export const ImageCardFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding-top: 16px;
  bottom: 0;
  left: 0%;
  width: calc(100% - 16px);
  box-sizing: border-box;
  z-index: 2;

  @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
    width: 100%;
  }

  > p,
  > i {
    color: ${({ theme }) => theme.colors.primary.black_90};
  }
  > i {
    cursor: pointer;
    margin-left: auto;
    margin-right: 0;
    position: absolute;
    top: -22px;
    right: 6px;
    color: white;

    @media ${({ theme }) => theme.device.mediaqueries.large_screen_min} {
      right: 21px;
    }

    @media ${({ theme }) => theme.device.mediaqueries.desktop_screen_min} {
      right: 5px;
    }

  }
`;

export const ImageContainer = styled.div`
  ${({ image }) => image
    && css`
      background-image: url(${image});
    `}
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.primary.black_05};
  background-origin: center;
  background-position: center;
  min-height: 240px;
`;
