/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TypographyStyle from './index.style';
import theme from '../../ui/theme/colors';
import { DEFAULT_CUSTOM, STYLES, TYPES } from './index.enum';

const Tag = styled.div`
  margin: ${({ margin }) => margin || 0}px;
  padding: 0;
  ${(props) => (props.type !== TYPES.CUSTOM
    ? TypographyStyle[props.type]
      ? TypographyStyle[props.type][props.styler](props.color, props)
      : TypographyStyle.body.default(props.color, props)
    : TypographyStyle[props.type]({
      ...props.custom,
      props,
      color: props.color,
    }))}
`;

const Typography = ({
  margin,
  tag,
  type,
  style,
  color,
  custom,
  onClick,
  children,
}) => (
  <Tag
    as={tag}
    margin={margin}
    type={type}
    styler={style}
    color={color}
    custom={custom}
    onClick={onClick}
    {...(typeof children === 'string' && !/<script(.*)?(\/)?>(<\/script>)?/gmi.test(children)
      ? { dangerouslySetInnerHTML: { __html: children } }
      : {})}
  >
    {typeof children !== 'string' ? children : null}
  </Tag>
);

Typography.TYPES = TYPES;
Typography.STYLES = STYLES;
Typography.DEFAULT_CUSTOM = DEFAULT_CUSTOM;

Typography.defaultProps = {
  color: null,
  onClick: () => null,
  custom: DEFAULT_CUSTOM,
  style: STYLES.DEFAULT,
  margin: '0',
};

Typography.propTypes = {
  tag: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
  style: PropTypes.oneOf(Object.values(STYLES)),
  color: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  onClick: PropTypes.func,
  custom: PropTypes.shape({
    fontFamily: PropTypes.string.isRequired,
    fontSize: PropTypes.string.isRequired,
    fontWeight: PropTypes.string.isRequired,
    fontStretch: PropTypes.string.isRequired,
    fontStyle: PropTypes.string.isRequired,
    lineHeight: PropTypes.string.isRequired,
    letterSpacing: PropTypes.string.isRequired,
  }),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  margin: PropTypes.string,
};

export default Typography;
