import styled from 'styled-components';

export const ModalBodyLoginRequestContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    padding: 0 14px;
  }

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    padding: 0;
    padding-left: 81px;
    padding-right: 87px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    margin-top: 85px;
  }
`;

export const SubTitleContainer = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
    margin-bottom: 80px;
  }

  @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
    margin-bottom: 64px;
    flex-direction: row;
    justify-content: space-between;
  }

  > button {
    margin-bottom: 24px;
    min-width: 100%;

    @media ${(props) => props.theme.device.mediaqueries.large_screen_min} {
      min-width: 226px;
    }

    @media ${(props) => props.theme.device.mediaqueries.desktop_screen_min} {
      min-width: 226px;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
