import React from 'react';
import PropTypes from 'prop-types';
import {
  InputSelectContainer,
  SelectContainer,
  IconContainer,
} from './index.style';
import Icon from '../Icon';
import ICONS_TYPE from '../Icon/index.enum';

const InputSelect = ({
  margin,
  id,
  icon,
  selectOption,
  onChange,
  disabled: selectDisabled,
}) => (
  <InputSelectContainer margin={margin} disabled={selectDisabled}>
    <SelectContainer
      id={id}
      disabled={selectDisabled}
      defaultValue={selectOption.find((option) => option.selected).value}
      value={selectOption.find((option) => option.selected).value}
      onChange={onChange}
    >
      {selectOption.map(({
        value, disabled, selected, label,
      }) => (
        <option
          key={value}
          disabled={disabled}
          selected={selected}
          value={value}
        >
          {label}
        </option>
      ))}
    </SelectContainer>
    {icon && (
      <IconContainer htmlFor={id}>
        <Icon icon={icon} size={16} />
      </IconContainer>
    )}
  </InputSelectContainer>
);
InputSelect.defaultProps = {
  id: null,
  icon: null,
  selectOption: null,
  margin: null,
  disabled: false,
  onChange: () => null,
};

InputSelect.propTypes = {
  margin: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(ICONS_TYPE)),
  selectOption: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default InputSelect;
