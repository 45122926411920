import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  width:100%;
  display: inline-block;
  ${({ hasLabel }) => hasLabel
    && css`
      margin-top: 30px;
    `}
  ${({ fullWidth }) => fullWidth
    && css`
      width: 100%;
    `}
  position: relative;
  margin: ${({ margin }) => margin};
  label {
    position: absolute;
    top: 0;
    left: 0;
    transition: top 0.15s;
  }
  i {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: top 0.15s;
  }
  ${({ focused, hasValue }) => (focused || hasValue)
    && css`
      label {
        top: -28px;
        > p {
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }
    `}
`;

export const Input = styled.input`
  ${(props) => props.styleType(props.color, props)}
  width: 100%;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.black_20};
  border-radius: 0;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
  ${({ hasIcon }) => hasIcon
    && css`
      padding-right: 24px;
      box-sizing: border-box;
    `}
`;
