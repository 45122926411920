import React from 'react';
import PropTypes from 'prop-types';
import ICONS_TYPE from './index.enum';
import theme from '../../ui/theme/colors';
import './style.scss';
import { IconContainer, IconCircleContainer } from './index.style';

const Icon = ({
  icon,
  color,
  secondColor,
  size,
  onClick,
  hoverBehaviour,
  hoverRingColor,
  hoverRingProgressColor,
}) => (
  <>
    {!hoverBehaviour ? (
      <IconContainer
        className={icon}
        color={color}
        size={size}
        secondColor={secondColor}
        onClick={onClick}
      >
        <span className="path1" />
        <span className="path2" />
      </IconContainer>
    ) : (
      <IconCircleContainer
        hoverBehaviour={hoverBehaviour}
        hoverRingColor={hoverRingColor}
        hoverRingProgressColor={hoverRingProgressColor}
      >
        <svg width="40" height="40">
          <circle r="19" cx="20" cy="20" fill="transparent" strokeDasharray="565.48" strokeDashoffset="0" />
        </svg>
        <IconContainer
          className={icon}
          color={color}
          size={size}
          secondColor={secondColor}
          onClick={onClick}
        >
          <span className="path1" />
          <span className="path2" />
        </IconContainer>
      </IconCircleContainer>
    )}
  </>
);

Icon.ICONS_TYPE = ICONS_TYPE;

Icon.defaultProps = {
  color: null,
  secondColor: null,
  size: 16,
  onClick: () => null,
  hoverBehaviour: false,
  hoverRingColor: 'black_05',
  hoverRingProgressColor: 'rosso_corsa',
};

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.values(ICONS_TYPE)).isRequired,
  color: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  secondColor: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  onClick: PropTypes.func,
  size: PropTypes.number,
  hoverBehaviour: PropTypes.bool,
  hoverRingColor: PropTypes.oneOf(Object.keys(theme.colors.primary)),
  hoverRingProgressColor: PropTypes.oneOf(Object.keys(theme.colors.primary)),
};

export default Icon;
