import React, {
  useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from '../../../node_modules/react-share';
import { TRANSLATION_KEYS } from '../../i18n/index.enum';
import '../../App.scss';
import Icon from '../../atoms/Icon';
import Typography from '../../atoms/Typography';
import { colorsNameKey } from '../../const/theme.const';
import {
  CarStatusInfoHeaderContainer,
  CarStatusInfoHeader,
  StatusContainerInfo,
  TextShadownContainer,
  CarStatusShareLink,
  LinkContainer,
  SvgContainer,
} from './index.style';
import TextIconContainer from '../../atoms/TextIconContainer';
import Badge from '../../atoms/Badge';

const ShareHeader = ({
  isUserLogged, isNegotiable, isOwner, dataShareURL,
}) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const [openShare, setOpenShare] = useState(false);
  const [clipBoard, setClipboard] = useState(false);

  const toogleClipBoard = (event) => {
    setClipboard(true);
    const el = document.createElement('textarea')
    el.value = dataShareURL.toString();
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    setTimeout(() => {
      setClipboard(false);
    }, 2000);
    event.stopPropagation();
  };

  useEffect(() => {
    setOpenShare(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeContext.device.windowScroolToTop().scroll]);

  // TODO Cambiare 40 con variabile altezza header
  return (
    <>
      <CarStatusInfoHeaderContainer
        top={themeContext.device.windowScroolToTop().scroll <= 40 ? '40' : '0'}
        position={
          themeContext.device.windowScroolToTop().scroll <= 40
            ? 'absolute'
            : 'fixed'
        }
        openShare={openShare}
        onClick={() => setOpenShare(false)}
      >
        <CarStatusInfoHeader>
          <StatusContainerInfo openShare={openShare}>
            {(isNegotiable && isUserLogged && isOwner) && (
              <Badge
                color={colorsNameKey.WHITE}
                background={colorsNameKey.ROSSO_CORSA}
                hasNotCursorPointer
              >
                {t(TRANSLATION_KEYS.CAR_DETAIL.STATUS.NEGOTIABLE)}
              </Badge>
            )}
            {(isOwner && isUserLogged) && (
              <Badge
                color={colorsNameKey.WHITE}
                background={colorsNameKey.ROSSO_CORSA}
                hasNotCursorPointer
              >
                {t(TRANSLATION_KEYS.CAR_DETAIL.STATUS.OWNED)}
              </Badge>
            )}
          </StatusContainerInfo>

          <TextShadownContainer>
            <TextIconContainer
              icon={openShare ? Icon.ICONS_TYPE.CLOSE : Icon.ICONS_TYPE.SHARE}
              hoverBehaviour
              size={42}
              color={colorsNameKey.WHITE}
              secondColor={colorsNameKey.TRASPARENT}
              hasIconRight
              onClick={(ev) => {
                setOpenShare(!openShare);
                ev.stopPropagation();
              }}
            >
              {openShare && (
                <Typography
                  tag="span"
                  type={Typography.TYPES.CAPTION}
                  color={colorsNameKey.WHITE}
                >
                  {t(TRANSLATION_KEYS.CAR_DETAIL.CLOSE)}
                </Typography>
              )}
            </TextIconContainer>
          </TextShadownContainer>
        </CarStatusInfoHeader>
        <CarStatusShareLink openShare={openShare}>
          {/* {linksShare.map((element, index) => (
            <LinkContainer key={index} index={index} openShare={openShare}>
              <Typography
                tag="span"
                type={Typography.TYPES.CAPTION}
                color={colorsNameKey.WHITE}
              >
                {element.value}
              </Typography>
            </LinkContainer>

          ))} */}
          <LinkContainer index={0} openShare={openShare}>
            <FacebookShareButton url={dataShareURL}>
              <Typography
                tag="span"
                type={Typography.TYPES.CAPTION}
                color={colorsNameKey.WHITE}
              >
                FACEBOOK
              </Typography>
            </FacebookShareButton>
          </LinkContainer>
          <LinkContainer index={1} openShare={openShare}>
            <TwitterShareButton url={dataShareURL}>
              <Typography
                tag="span"
                type={Typography.TYPES.CAPTION}
                color={colorsNameKey.WHITE}
              >
                TWITTER
              </Typography>
            </TwitterShareButton>
          </LinkContainer>
          <LinkContainer index={2} openShare={openShare}>
            <LinkedinShareButton url={dataShareURL}>
              <Typography
                tag="span"
                type={Typography.TYPES.CAPTION}
                color={colorsNameKey.WHITE}
              >
                LINKEDIN
              </Typography>
            </LinkedinShareButton>
          </LinkContainer>
          <LinkContainer
            index={3}
            openShare={openShare}
            onClick={(event) => {
              setOpenShare(true);
              toogleClipBoard(event);
            }}
          >
            {!clipBoard ? (
              <Typography
                tag="button"
                type={Typography.TYPES.CAPTION}
                color={colorsNameKey.WHITE}
              >
                URL
              </Typography>
            ) : (
              <SvgContainer openShare={openShare}>
                <svg
                  viewBox="0 0 16 16"
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="white"
                >
                  <path
                    fill="#00000"
                    fillRule="evenodd"
                    d="M7.878 13.673a1 1 0 0 1-1.587.057L1.404 8.146a.5.5 0 0 1 .707-.707l4.922 4.306L14.46 4.99c.274-.275.656-.34.85-.144.196.195.131.576-.143.851l-7.11 7.818c-.058.057-.118.11-.18.158z"
                  />
                </svg>
              </SvgContainer>
            )}
          </LinkContainer>
        </CarStatusShareLink>
      </CarStatusInfoHeaderContainer>
    </>
  );
};

ShareHeader.defaultProps = {
  isUserLogged: null,
  isOwner: true,
  isNegotiable: true,
  dataShareURL: null,
};

ShareHeader.propTypes = {
  isUserLogged: PropTypes.bool,
  isOwner: PropTypes.bool,
  isNegotiable: PropTypes.bool,
  dataShareURL: PropTypes.string,
};

export default ShareHeader;
