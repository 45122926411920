import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// import Icon from '../../atoms/Icon';
import ShareHeaderCarousel from '../ShareHeaderCarousel'
import {
  ModalContainer,
  ModalBody,
  ModalBodyContainer,
  ModalOverlay,
} from './index.style';
// import { colorsNameKey } from '../../const/theme.const';

const Modal = ({
  active,
  close,
  onClose, onOpen, children, fullScreen, padding, shareHeader,
}) => {
  useEffect(() => {
    if (active) {
      onOpen();
    } else {
      onClose();
    }
  }, [active, onOpen, onClose]);

  return (
    <ModalContainer active={active} fullScreen={fullScreen}>
      <ModalOverlay active={active} onClick={close} />
      <ModalBodyContainer active={active} padding={padding}>
        <ShareHeaderCarousel
          onClose={close}
          dataShareURL={window.location}
          shareHeader={shareHeader}
        />

        <ModalBody>
          {children}
        </ModalBody>
      </ModalBodyContainer>
    </ModalContainer>
  );
};

Modal.defaultProps = {
  active: false,
  onClose: () => null,
  onOpen: () => null,
  fullScreen: false,
  padding: null,
  shareHeader: false,
};

Modal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  fullScreen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  padding: PropTypes.string,
  shareHeader: PropTypes.bool,
};

export default Modal;
